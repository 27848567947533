import { Component, inject } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatButtonModule } from '@angular/material/button';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
declare var particlesJS: any;

@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [CommonModule, MatButtonModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, MatTooltipModule],
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.css'
})
export class SigninComponent {
  fb = inject(FormBuilder)
  router = inject(Router)

  loginForm: FormGroup;
  constructor(private auth: AuthService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
    // particlesJS.load('particles-js', 'assets/particles.json', null);
    particlesJS.load('particles-js', 'assets/particles.json', function () {
      // console.log('Particles.js config loaded');
    });
  }
  onSubmit(): void {
    if (this.loginForm.valid) {
      const { email, password } = this.loginForm.value;
      this.auth.signinWithEmailAndPass(email, password)
    }
  }


  hide = true;

  public loader$ = new BehaviorSubject<any>({ state: false, error: false });

  public formLogin = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(2),
      // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
      Validators.maxLength(20),
    ]),
  });

  loginSubmit() {
    if (this.formLogin.valid) {
    }
  }

  signinWithEmail() {
    console.log('login')
    this.auth.signinWithEmailAndPass('f.morales@lucro-app.com', 'passss')
  }


  signinWithGoogle() {
    // console.log('signinWithGoogle')
    this.auth.signInGoogle()
  }

  signinWithMicrosoft() {
    // console.log('signinWithMicrosoft')
    this.auth.signInMicrosoft()
  }

  signOut() {
    console.log('signOut')
    this.auth.signOut()
  }


}
