import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { EditorModule } from 'primeng/editor';
// import { QuillModule } from 'ngx-quill';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SearchAutocompleteComponent } from '../search-autocomplete/search-autocomplete.component';

import { BehaviorSubject, Observable, skip, tap } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RulesService } from '../../rules/rules.service';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-dialog-task-rule-params',
  standalone: true,
  imports: [CommonModule, MatDialogTitle, MatDialogContent, FormsModule, ReactiveFormsModule, AutoCompleteModule, SearchAutocompleteComponent, MatButtonModule, MatFormFieldModule, MatInputModule],
  templateUrl: './dialog-task-rule-params.component.html',
  styleUrl: './dialog-task-rule-params.component.css'
})
export class DialogTaskRuleParamsComponent {
  jsonForm!: FormGroup;
  editorOptions = { theme: 'vs-dark', language: 'json' };

  state: string = 'creando';
  disableInput: boolean = true;
  dataSend: any;
  taskruleparameters!: any[];
  taskRuleForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private rulesService: RulesService,
    private dialogRef: MatDialogRef<DialogTaskRuleParamsComponent>
  ) {
    this.taskRuleForm = this.fb.group({
      taskruleparameters: this.fb.array([])
    });

    this.rules$ = this.rulesService.getRules();
    // console.log('data_', data);
    if (this.data.data != 'nuevo') {
      this.rulesService.getRuleById(this.data.data.rule_id).subscribe(
        (rule) => {
          let taskruleparametersCom = this.combineParameters(this.data.data.taskruleparameters, rule.rulesparameters)
          this.data.data.taskruleparameters = taskruleparametersCom;
          this.initializeParameters('exist', this.data);
        }
      )

    } else {
      console.log('data', data);
      this.disableInput = false;
    }
  }

  private rules = new BehaviorSubject<any[]>([]);
  rules$ = this.rules.asObservable();

  ngOnInit(): void {

    this.taskRuleForm.valueChanges.pipe(skip(1)).subscribe(v => { })
  }
  combineParameters(taskParams: any, ruleParams: any) {
    return taskParams.map((taskParam: any) => {
      let ruleParam = ruleParams.find((rp: any) => rp.rule_parameter_id === taskParam.rule_parameter_id);
      return {
        ...taskParam,
        format: ruleParam ? ruleParam.format : null,
        key: ruleParam ? ruleParam.key : null,
        input_validator: ruleParam ? ruleParam.input_validator : null
      };
    });
  }

  get taskruleparameters2(): FormArray {
    return this.taskRuleForm.get('taskruleparameters') as FormArray;
  }

  saveRuleTaskParms() {
    let data;
    let resultType;
    console.log('this.dataSend', this.dataSend);
    if (this.state === 'creando') {
      data = this.transformDataForCreation(this.dataSend);
      resultType = 'nuevo';
    } else {
      data = this.transformDataForUpdate(this.dataSend);
      resultType = 'existente';
    }
    console.log(this.dataSend, data);
    this.dialogRef.close({ data: { ...data }, type: resultType });
  }

  initializeParameters(state: string, data: any): void {
    console.log('initializeParameters', state, data);
    const nestedData = data.data;
    if (state === 'exist') {
      this.taskruleparameters = nestedData.taskruleparameters;
      this.dataSend = nestedData;
      console.log('this.dataSend if', this.dataSend);
      this.state = 'editando';
    } else {
      this.taskruleparameters = data.rulesparameters;
      this.dataSend = data;
      console.log('this.dataSend else', this.dataSend);
      this.state = 'creando';
    }
    this.initializeForm();
  }

  initializeForm() {
    this.taskruleparameters2.clear()
    this.taskruleparameters.forEach(param => {
      this.taskruleparameters2.push(this.createParameterControl(param));
    });
  }

  createParameterControl(param: any): FormGroup {
    console.log('createParameterControl', param)
    return this.fb.group({
      key: [param.key],
      format: [param.format],
      rule_parameter_id: [param.rule_parameter_id],
      task_rule_id: [param.task_rule_id],
      task_rule_parameter_id: [param.task_rule_parameter_id],
      value: [param.value, Validators.required]
    });
  }
  transformDataForCreation(inputData: any) {
    console.log('inputData1', inputData);
    if (Array.isArray(inputData)) {
      inputData = inputData[0];
    }
    const transformed = {
      rule_id: inputData.rule_id,
      task_id: this.data.task_id,
      rule: {
        rule_id: inputData.rule_id,
        name: inputData.name,
        description: inputData.description,
        weight: inputData.weight,
      },
      taskrulelabel: {
        value: inputData.name
      },
      taskruleparameters: this.taskRuleForm.value.taskruleparameters
    };
    return transformed;
  }

  transformDataForUpdate(inputData: any) {
    console.log('inputData2', inputData);
    if (Array.isArray(inputData)) {
      inputData = inputData[0];
    }
    const transformed = {
      taskrulelabel: inputData.taskrulelabel,
      taskruleparameters: this.taskRuleForm.value.taskruleparameters.map((param: any) => ({
        task_rule_parameter_id: param.task_rule_parameter_id,
        task_rule_id: param.task_rule_id,
        rule_parameter_id: param.rule_parameter_id,
        value: param.value || null
      }))
    };
    return transformed;
  }

  save(): void {
    console.log('Updated Parameters:', this.taskruleparameters);
  }

  onItemSelected(item: any): void {
    console.log('Enviado desde el buscador', item);
    this.initializeParameters('nuevo', item);
  }

}
