<app-main-header [name_main]="'Gestion dashboards'">
    <button class="check-btn" mat-flat-button routerLink="/dashboards/create">
        CREAR
    </button>
    <button [disabled]="!selectedProducts.length" class="check-btn" mat-flat-button color="warn"
    (click)="deleteDashboard(selectedProducts)">
    BORRAR
</button>
</app-main-header>
<app-data-table 
    [data]="boardsData" 
    [columns]="columns" 
    [rows]="10" 
    [rowsPerPageOptions]="[10, 25, 50]" 
    (rowSelect)="onRowSelect($event)" 
    [selection]="selectedItems" 
    (selectionChange)="onSelectionChange($event)"
    [sortField]="'dashboards'"
    [sortOrder]="-1"
    [showFilters]="true"
    (deleteItems)="onDeleteItems($event)"
    (editItem)="onEditItem($event)">  
</app-data-table>
