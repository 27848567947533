import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IndexedDbService } from '../playground.service';
import { CommonModule, Location } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { FixedTwoDecimalsPipe } from '../../../pipes/twodecimal.pipe';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { ImageAnnotatorService } from '../../../services/image_anotate.service';
@Component({
  selector: 'app-render-images',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MainHeaderComponent, FixedTwoDecimalsPipe, AutoCompleteModule, FormsModule, RouterLink],
  templateUrl: './render-images.component.html',
  styleUrl: './render-images.component.css'
})
export class RenderImagesComponent {
  titleheader: string = 'Playground'
  requestId!: string;
  imageUrl!: string;
  detections: any[] = [];
  panelVisible = false;

  currentIndex: number = 0;
  // images: any[] = [];


  @ViewChild('imageElement') imageElement!: ElementRef<HTMLImageElement>;

  constructor(private activatedRoute: ActivatedRoute, private idb: IndexedDbService, private cdr: ChangeDetectorRef, private imageAnnotatorService: ImageAnnotatorService, private router: Router, private location: Location) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.requestId = params['id'];
      this.loadImages();
    });
  }

  backbutton() {

    this.location.back()

  }

  setupBoundingBoxes() {
    this.detections = this.detections.sort((a, b) => a.name.localeCompare(b.name)).map((det, index) => {
      const cleanedName = det.name.trim();
      const is_other = cleanedName.split('_').slice(-1)[0].toLowerCase() === "others" ? true : false
      return {
        ...det,
        id: index,
        visible: true,
        color: this.imageAnnotatorService.generateColorFromName(cleanedName, is_other),
        is_other: cleanedName.split('_').slice(-1)[0].toLowerCase() === "others" ? true : false
      }
    });
  }

  getBoxStyle(box: any): any {
    const imgWidth = this.imageElement.nativeElement.clientWidth;
    const imgHeight = this.imageElement.nativeElement.clientHeight;
    const naturalWidth = this.imageElement.nativeElement.naturalWidth;
    const naturalHeight = this.imageElement.nativeElement.naturalHeight;

    const scaleX = imgWidth / naturalWidth;
    const scaleY = imgHeight / naturalHeight;
    const { r, g, b } = box.color;
    const border_style = box.is_other ? 'dashed' : 'solid'

    const result =
    {
      position: 'absolute',
      //  'border': '2px solid ' + box.color,
      left: box.bounding_box.x_min * scaleX + 'px',
      top: box.bounding_box.y_min * scaleY + 'px',
      width: (box.bounding_box.x_max - box.bounding_box.x_min) * scaleX + 'px',
      height: (box.bounding_box.y_max - box.bounding_box.y_min) * scaleY + 'px',
      '--box-color': `${r}, ${g}, ${b}`,
      'border': `2px ${border_style} rgba(${r}, ${g}, ${b}, 1)`,
    };
    return result
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setupBoundingBoxes();
    }, 0);
  }

  toggleVisibility(id: number) {
    const detection = this.detections.find(det => det.id === id);
    if (detection) {
      detection.visible = !detection.visible;
    }
  }

  togglePanel() {
    this.panelVisible = !this.panelVisible;
    setTimeout(() => {
      this.imageElement.nativeElement.style.width = '100%';
      this.setupBoundingBoxes();
    }, 300);
  }



  showDetails(box: any): void {
    // console.log('Detalles:', box);
  }
  nameImage!: string


  images: any[] = [];
  thumbnails: string[] = [];  // Array para almacenar las URLs de las miniaturas
  ngOnDestroy() {
    this.clearThumbnails();
  }

  clearThumbnails() {
    this.thumbnails.forEach(url => URL.revokeObjectURL(url));
    this.thumbnails = [];
  }



  loadImages() {
    if (this.requestId) {
      // Limpiar miniaturas antes de cargar nuevas
      this.clearThumbnails();

      this.idb.getImagesByRequestId(this.requestId)
        .then(images => {
          if (images.length > 0) {
            this.images = images;
            this.thumbnails = images.map(img => URL.createObjectURL(img.blobData));  // Crear una URL para cada miniatura
            this.currentIndex = 0;
            this.loadCurrentImage();
          } else {
            console.error('No images found for the provided request ID.');
          }
        })
        .catch(error => {
          console.error('Error retrieving images:', error);
        });
    } else {
      console.log('No request ID provided');
    }
  }





  ngAfterViewChecked() {
    this.cdr.detectChanges();  // Forzar la verificación de cambios en cada ciclo de vista
  }


  loadCurrentImage() {
    const currentImage = this.images[this.currentIndex];
    setTimeout(() => {
      this.imageUrl = URL.createObjectURL(currentImage.blobData);
      this.detections = currentImage.analysisData.detections;
      this.nameImage = currentImage.fileName;
      this.setupBoundingBoxes();
    }, 0);  // Retrasa el cambio hasta el siguiente ciclo del event loop
  }


  nextImage() {
    if (this.currentIndex < this.images.length - 1) {
      this.currentIndex++;
      this.loadCurrentImage();
    }
  }

  previousImage() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.loadCurrentImage();
    }
  }
  getThumbnailURL(blobData: Blob): string {
    return URL.createObjectURL(blobData);
  }


  selectImage(index: number) {
    this.currentIndex = index;
    this.loadCurrentImage();
    // Forzar la detección de cambios para que Angular vea los nuevos valores correctamente
    this.cdr.detectChanges();
  }


  windowWidth: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
    this.setupBoundingBoxes();
  }

  filteredDetections: any[] = [];
  selectedItem: any = null;

  search(event: any) {
    this.filteredDetections = this.detections.filter(detection =>
      detection.name.toLowerCase().includes(event.query.toLowerCase())
    );
  }
  onSelectDetection(event: any) {
    console.log('Selección:', event);
  }
}

