<mat-drawer-container class="md-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
        @if(user$ | async; as d){
        <div class="content-menu">
            <div class="icon-logo">
                <img width="30" [src]="iconUrl" alt="">
            </div>
            <a routerLink="/playground" mat-icon-button routerLinkActive="activebutton" #tooltip="matTooltip"
                matTooltip="PlayGround" matTooltipPosition="left"
                *ngIf="d.roles?.admin || d.roles?.superadmin || d.roles?.viewer">
                <span class="material-symbols-outlined">ar_stickers</span>
            </a>
            <a routerLink="/missions" mat-icon-button routerLinkActive="activebutton" #tooltip="matTooltip"
                matTooltip="Tareas" matTooltipPosition="left" *ngIf="d.roles?.admin || d.roles?.superadmin">
                <span class="material-symbols-outlined">detection_and_zone</span>
            </a>
            <a routerLink="/rules" mat-icon-button routerLinkActive="activebutton" #tooltip="matTooltip"
                matTooltip="Reglas" matTooltipPosition="left" *ngIf="d.roles?.admin || d.roles?.superadmin">
                <span class="material-symbols-outlined">straighten</span>
            </a>
            <a routerLink="/dashboards" mat-icon-button routerLinkActive="activebutton" #tooltip="matTooltip"
                matTooltip="Dashboards" matTooltipPosition="left" *ngIf="d.roles?.admin || d.roles?.superadmin">
                <span class="material-symbols-outlined">dashboard_2</span>
            </a>
            <a routerLink="/models" mat-icon-button routerLinkActive="activebutton" #tooltip="matTooltip"
                matTooltip="Modelos" matTooltipPosition="left" *ngIf="d.roles?.admin || d.roles?.superadmin">
                <span class="material-symbols-outlined">smart_toy</span>
            </a>
            <a mat-icon-button routerLink="/users" routerLinkActive="activebutton" matTooltipPosition="left"
                matTooltip="Usuarios" *ngIf="d.roles?.admin || d.roles?.superadmin">
                <span class="material-symbols-outlined">
                    group
                </span>
            </a>
            <a mat-icon-button routerLink="/clients" routerLinkActive="activebutton" matTooltipPosition="left"
                matTooltip="Clientes" *ngIf="d.roles?.admin || d.roles?.superadmin">
                <span class="material-symbols-outlined"><span class="material-symbols-outlined">
                        patient_list
                    </span></span>
            </a>
            <a mat-icon-button routerLink="/library" routerLinkActive="activebutton" matTooltipPosition="left"
                matTooltip="Productos" *ngIf="d.roles?.admin || d.roles?.superadmin">
                <span class="material-symbols-outlined"><span class="material-symbols-outlined">
                        local_library
                    </span></span>
            </a>
            <a mat-icon-button routerLink="/boards" routerLinkActive="activebutton" matTooltipPosition="left"
                matTooltip="DashBoards">
                <span class="material-symbols-outlined">dashboard</span>
            </a>
            <button mat-icon-button (click)="signOut()" routerLinkActive="activebutton" matTooltipPosition="left"
                matTooltip="Salir">
                <span class="material-symbols-outlined">logout</span>
            </button>
        </div>
        }
    </mat-drawer>
    <mat-drawer-content class="example-sidenav-content">
        <router-outlet></router-outlet>

        @if(iconview){
        <div class="client-init">
            <img height="80" [src]="logoUrl" alt="">
            <mat-spinner diameter="20" strokeWidth="1"></mat-spinner>
        </div>
        }


    </mat-drawer-content>
</mat-drawer-container>