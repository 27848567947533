<div class="nav-top">
    <button class="back-btn" mat-icon-button routerLink="/boards">
        <span class="material-symbols-outlined">arrow_back</span>
    </button>
</div>
@if(selectedDashboardUrl$){
<div class="powerbi-report-container">
    <iframe *ngIf="selectedDashboardUrl$" [src]="selectedDashboardUrl$ | safe" frameborder="0"
        allowFullScreen="true"></iframe>
</div>
}