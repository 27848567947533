import { ChangeDetectionStrategy, ChangeDetectorRef, Component, output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { matFormFieldAnimations, MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MainHeaderComponent } from '../shared/main-header/main-header.component';
import { MatButtonModule } from '@angular/material/button';
import { TableModule } from 'primeng/table';
import { RulesService } from './rules.service';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'd3';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { Rule } from '../../models/rules';
import { UsersService } from '../../users/users.service';
import { MatSelectModule } from '@angular/material/select';
@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatIconModule, MatButtonModule,
    MainHeaderComponent, MatSelectModule
  ],
  styleUrls: ['./rules.component.css']
})
export class RulesComponent {
  ruleId: number;
  for_save: boolean = false;
  creating: boolean = true; 
  titleheader: string = 'Regla';
  ruleData: any;
  toDeleteParams: number[] = [];
  toDeleteOutputs: number[] = [];
  canDelete: boolean = false;
  

  constructor(private fb: FormBuilder, private ruleService: RulesService, private router: Router, private route: ActivatedRoute, private _snackBar: MatSnackBar, private userService: UsersService) {
    this.ruleId = +this.route.snapshot.paramMap.get('id')!;
    if (this.ruleId) {
      this.titleheader = 'Editando Regla'
      this.creating = false
      this.updateRule
      this.ruleService.getRuleById(this.ruleId).subscribe(
        d => {
          console.log(d)
          this.patchForm(d)
          //  this.checkUserRoles();
        }
      )
    } else {
      this.titleheader = 'Creando Regla'
    }
  }
  

  typeOptions = [
  { value: 'list', label: 'list' },
  { value: 'dict', label: 'dict' },
  { value: 'int', label: 'int' },
  { value: 'str', label: 'str' },
  { value: 'bool', label: 'bool' },
  ];
  
  ruleForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    weight: [0, Validators.required],
    version: ['1.0'],
    function_name: ['', Validators.required],
    rulesparameters: this.fb.array([]), //this.minLengthArray(1)), 
    ruleoutputs: this.fb.array([])
  });

  formConfig = [
    { controlName: 'name', label: 'Nombre', type: 'text', width: '100%' },
    { controlName: 'description', label: 'Descripción', type: 'textarea', width: '100%' },
    { controlName: 'weight', label: 'Peso', type: 'number', width: '20%' },
    { controlName: 'function_name', label: 'Nombre función', type: 'text', width: '40%' },
    { controlName: 'version', label: 'Versión', type: 'text', width: '30%' }
  ];


  ngOnInit(): void {
    this.userService.loadUsersStore();
    this.checkUserRoles()
    this.ruleForm.valueChanges.subscribe(
      d => {console.log('data form', this.ruleForm.value)
      this.for_save = true
      this.default_rules = {...this.default_rules, ...d}
      this.rule_subject.next(this.default_rules)
      
   })
  } 
  private default_rules : Partial <Rule> = {
    name: '',
    description: '',
    weight: 0,
    funtion_name: '',
    version: '',
    rulesparameters: [],
    ruleoutputs: []
  }

  private rule_subject = new BehaviorSubject<Rule | Partial <Rule> | undefined> (this.default_rules)
  rule$: Observable <Rule | Partial <Rule> | undefined> =this.rule_subject.asObservable();
  patchForm(data: any) {
    this.ruleForm.patchValue({
      name: data.name,
      description: data.description,
      weight: data.weight,
      version: data.version,
      function_name: data.function_name
    });

    this.patchRulesParameters(data.rulesparameters || []);
    this.patchRuleOutputs(data.ruleoutputs || []);
  }

  patchRulesParameters(rulesparameters: any[]) {
    const formArray = this.ruleForm.get('rulesparameters') as FormArray;
    formArray.clear(); // Clear existing items

    rulesparameters.forEach(item => {
      formArray.push(this.fb.group({
        key: [item.key, Validators.required],
        format: [item.format, Validators.required],
        rule_parameter_id: [item.rule_parameter_id],
        input_validator: [item.input_validator]
      }));
    });
  }

  patchRuleOutputs(ruleoutputs: any[]) {
    const formArray = this.ruleForm.get('ruleoutputs') as FormArray;
    formArray.clear(); // Clear existing items

    ruleoutputs.forEach(item => {
      formArray.push(this.fb.group({
        key: [item.key, Validators.required],
        label: [item.label, Validators.required],
        type: [item.type, Validators.required],
        rule_output_id: [item.rule_output_id]
      }));
    });
  }

  generateUniqueId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  initParameters() {
    return this.fb.group({
      key: ['', Validators.required],
      format: ['', Validators.required],
      input_validator: [''],
      trackingId: this.generateUniqueId()
    });
  }

  initOutputs() {
    return this.fb.group({
      key: ['', Validators.required],
      label: ['', Validators.required],
      type: ['', Validators.required],
      trackingId: this.generateUniqueId()
    });
  } 


  get rulesparameters(): FormArray {
    return this.ruleForm.get('rulesparameters') as FormArray;
  }

  get ruleoutputs(): FormArray {
    return this.ruleForm.get('ruleoutputs') as FormArray;
  }

  
  addParameter() {
    this.rulesparameters.push(this.initParameters());
  }
  removeParameter(index: number) {
    const parameter = this.rulesparameters.at(index).value;
    if (parameter.rule_parameter_id) {
      if (confirm('¿Estás seguro que deseas eliminar este parámetro?')){
        this.ruleService.deleteParameter(parameter.rule_parameter_id).subscribe({
           next: () => {
          this.rulesparameters.removeAt(index); // Eliminar solo si la API responde correctamente
          this._snackBar.open('Parámetro eliminado correctamente.', 'cerrar', { duration: 3000 });
        },
        error: (err) => {
          console.error('Error al eliminar parámetro:', err);
          this._snackBar.open('Error al eliminar parámetro. Por favor, inténtalo nuevamente.', 'cerrar', { duration: 3000 });
        },
        })
      }
    } else {
    console.warn('El parámetro no tiene un ID válido:', parameter);
    this.rulesparameters.removeAt(index); 
   }
  }

  addOuput() {
    this.ruleoutputs.push(this.initOutputs());
  }
  removeOuput(index: number) {
    const output = this.ruleoutputs.at(index).value;
    if (output.rule_output_id) {
      if (confirm('¿Estás seguro que deseas eliminar esta salida?')){
        this.ruleService.deleteOutput(output.rule_output_id).subscribe({
           next: () => {
          this.ruleoutputs.removeAt(index); // Eliminar solo si la API responde correctamente
          this._snackBar.open('Salida eliminada correctamente.', 'cerrar', { duration: 3000 });
        },
        error: (err) => {
          console.error('Error al eliminar salida:', err);
          this._snackBar.open('Error al eliminar salida. Por favor, inténtalo nuevamente.', 'cerrar', { duration: 3000 });
        },
        })
      }
    } else {
    console.warn('La salida no tiene un ID válido:', output);
    this.ruleoutputs.removeAt(index); 
   }
  }


  saveRule() {
    console.log('save ')

    if (this.ruleId) {
      // this.getTask()
      this.updateRule()
    } else {
      this.createRule()
    }
    this.for_save = false
  }
  

  createRule() {
    if (this.ruleForm.valid) {
      const formValue = this.ruleForm.value;
      const ruleData = {
        name: formValue.name,
        version: formValue.version,
        description: formValue.description,
        function_name: formValue.function_name,
        weight: Number(formValue.weight),
        rulesparameters: formValue.rulesparameters?.map((param: any) => ({
          key: param.key,
          format: param.format,
          input_validator: param.input_validator
        })) ,
        ruleoutputs: formValue.ruleoutputs.map((param: any) => ({
          key: param.key,
          label: param.label,
          type: param.type
        })),
      };
      this.ruleService.createRule(ruleData).subscribe ((response) => {
        this._snackBar.open('Regla creada.', 'ok');
        this.router.navigate(['rules'], response)
    });
 }
}

  updateRule () {
    if (this.ruleForm.valid) {
      console.log('rulesparameters antes de enviar:', this.ruleForm.value.rulesparameters);
      console.log('rulesoutputs antes de enviar:', this.ruleForm.value.ruleoutputs);
      const formValue = this.ruleForm.value;
      const ruleData = {
        name: formValue.name,
        version: formValue.version,
        description: formValue.description,
        function_name: formValue.function_name,
        weight: Number(formValue.weight),
        rulesparameters: formValue.rulesparameters.map((param: any) => ({
          key: param.key,
          format: param.format,
          input_validator: param.input_validator,
          rule_parameter_id: param.rule_parameter_id

        })),
        ruleoutputs: formValue.ruleoutputs.map((param: any) => ({
          key: param.key,
          label: param.label,
          type: param.type,
          rule_output_id: param.rule_output_id
        })),

        
      //  toDeleteParams: this.toDeleteParams, // Incluir parámetros a eliminar
      // toDeleteOutputs: this.toDeleteOutputs
      } 
          this.ruleService.updateRule(this.ruleId, ruleData).subscribe({
            next:(updatedRule) => {
              console.log('Regla actualizada en la base de datos:', updatedRule);
          this._snackBar.open('Regla actualizada.', 'ok');
          this.for_save= false
          this.refreshRuleData()
        },
        error: () => this._snackBar.open('Error al actualizar la regla.',  'cerrar')
      })
    }
  } 

  refreshRuleData() {
  this.ruleService.getRuleById(this.ruleId).subscribe(d => {
    console.log('Datos de la regla después de actualizar:', d);
    this.patchForm(d);
  });
}  
   minLengthArray(min: number) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof FormArray && control.length < min) {
      return { minLengthArray: { requiredLength: min, actualLength: control.length } };
    }
    return null;
  };
  } 


  onClone() {
    console.log("ID", this.ruleId)
    this.ruleService.cloneRule(this.ruleId).subscribe({
      next: (response) => {
        console.log("Regla clonada exitosamente", response);
        
        const confirmEdit = confirm(
          '¡Duplicación con éxito!! ¿Quiere ser direccionado al nuevo elemento?'
        )

        if (confirmEdit) {
          this.router.navigate(['rules', response.rule_id]);
          this.ruleId = response.rule_id
          this.refreshRuleData()
        } 
        else  {
          this._snackBar.open(`Tarea ${this.ruleId} clonada exitosamente`, 'ok', {duration: 3000})
        }
      },
      error: (err) => {
       console.error('Error al clonar la regla', err)
      }, 
    })
  }

  checkUserRoles(): void {
  this.userService.getCurrentUserUid$().subscribe((uid) => {
    if (uid) {
      this.userService.users$.subscribe((users) => {
        const currentUser = users.find((user) => user.uid === uid);
        if (currentUser) {
          this.canDelete = currentUser.roles?.admin || currentUser.roles?.superadmin || false;
          console.log('Roles cargados:', currentUser.roles, 'Can Delete:', this.canDelete);
        } else {
          console.warn('Usuario no encontrado en la lista de usuarios.');
          this.canDelete = false;
        }
      });
    } else {
      console.error('No se pudo obtener el UID del usuario autenticado.');
      this.canDelete = false;
    }
  });
 }

  deleteRule (ruleId: number) {
    if (!this.canDelete) {
      this._snackBar.open('No tienes permisos para eliminar esta regla' , 'Cerrar ' , {duration: 3000});
      return;
    }
     if (window.confirm("Esto eliminará esta regla y todas sus configuraciones")) 
      this.ruleService.deleteRule(ruleId).subscribe({
     next: () => {
      this._snackBar.open(`Regla ${ruleId} eliminada.`, 'ok');
      this.router.navigate(['/rules',])
      },
      error: (err) => {
        console.error('Error al eliminar la regla:', err);
        this._snackBar.open(`Error: No se pudo eliminar la regla ${this.ruleId} debido a que ya está relacionada a una tarea.`, 'Cerrar', { duration: 4000 });
      }
    });
  }
}
