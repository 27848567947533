import { computed, Injectable, signal } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Users } from '../models/user';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { Clients } from '../models/clients';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private baseUrl: string = `${environment['api_backend']}/api`
  private apiUrlRecon = `${environment['api_recognition']}/v1/recognition`
  private apiUrlDetails = `${environment['api_recognition']}/v2/recognition/details`
  // TOKEN PARA API RECOGNITION
  private headers = new HttpHeaders({
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfdWlkIjoiY2IxNDk2Y2QtOTJhZi00YjE0LWFiZWItMjMwZWUzZGU5ZjA4IiwiY2xpZW50X2lkIjo0LCJpYXQiOjE3MjI0ODIzOTIsImV4cCI6MTcyMjQ5MzE5Mn0.6VEgFrRSx9uHEGWpAMhJViRzxwnOc7EyamkgrhnEojw`,
      'Accept': 'application/json'
  });

  private UsersSubject = new BehaviorSubject<Users[]>([]);
  users$ =  this.UsersSubject.asObservable(); 
  private currentUserUid = new BehaviorSubject<string | null>(null);
  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(error);
  }
  constructor(private http: HttpClient, private authService: AuthService) { }
  
  setCurrentUserUid (uid: string): void {
  this.currentUserUid.next(uid)
  }

  getCurrentUserUid$(): Observable <string | null> {
   return this.authService.userData$.pipe(
    map(user => user?.uid || null)
   );
  }

  updateUsers( users: Partial<Users>, uid:string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.patch<any>(`${this.baseUrl}/users/${uid}`, users, { headers })
        .pipe(
            catchError(this.handleError)
        );
  }

  getUsersByUid(userUid: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/users/${userUid}`); 
    }

    createUsers(users: any) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post<any>(`${this.baseUrl}/users`, users, { headers })
          .pipe(
              catchError(this.handleError)
          )
    };

    loadUsersStore(): void {
      let params = new HttpParams().set('limit', '200');
      this.http.get<Users[]>(`${this.baseUrl}/users`, { params })
        .pipe(catchError(this.handleError)) 
        .subscribe((users: Users[]) => {
          if (users && users.length > 0) {
            this.UsersSubject.next(users); 
          } else {
            console.warn('No users found or empty array returned');
          }
        });
    }

  loadClients(): Observable<Clients[]> {
    let params = new HttpParams().set('limit', '200');
    return this.http.get<Clients[]>(`${this.baseUrl}/clients`, { params })
      .pipe(catchError(this.handleError));
  }

  deleteUsers(userUid:string): Observable<Users> {
    return this.http.delete<Users>(`${this.baseUrl}/users/${userUid}`)
  } 
}
