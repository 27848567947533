import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { MissionsService } from '../../../services/missions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, tap, catchError, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-editor-json',
  standalone: true,
  imports: [CommonModule, MonacoEditorModule, FormsModule, MatDialogModule, MatButtonModule],
  templateUrl: './editor-json.component.html',
  styleUrls: ['./editor-json.component.css'] // Cambié 'styleUrl' por 'styleUrls' (error tipográfico)
})
export class EditorJsonComponent implements OnInit, OnDestroy {
  data = inject(MAT_DIALOG_DATA);
  missionsService = inject(MissionsService);
  private _snackBar = inject(MatSnackBar);
  router = inject(Router);
  dialogRef = inject(MatDialogRef<EditorJsonComponent>);

  datajson: any;
  code!: string;
  title!: string;
  import = false;

  // Un Subject para manejar la cancelación de suscripciones automáticamente
  private destroy$ = new Subject<void>();

  editorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
    formatOnType: true,
    formatOnPaste: true,
    minimap: {
      enabled: false
    },
    folding: true,
    scrollBeyondLastLine: false
  };

  constructor() { }

  ngOnInit() {
    if (this.data.task_id != null) {
      this.title = 'Exportar Tarea';
      this.missionsService.exportTaskById(this.data.task_id)
        .pipe(
          tap(d => {
            this.datajson = d;
            this.code = JSON.stringify(this.datajson, null, 2);
          }),
          catchError(error => {
            console.error('Error al exportar tarea:', error);
            this._snackBar.open('Error al exportar tarea.', 'Cerrar', { duration: 3000 });
            return of(null); // Retorna un observable vacío para continuar
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.title = 'Importar Tarea';
      this.import = true;
    }
  }

  ngOnDestroy(): void {
    // Emite la señal para cancelar todas las suscripciones activas
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatJson() {
    try {
      const jsonData = JSON.parse(this.code);
      this.code = JSON.stringify(jsonData, null, 2);
    } catch (error) {
      console.error('Error al formatear JSON:', error);
      this._snackBar.open('Error al formatear JSON. Asegúrate de que el contenido es un JSON válido.', 'Cerrar', {
        duration: 2000
      });
    }
  }
  copyToClipboard() {
    navigator.clipboard.writeText(this.code).then(
      () => {
        this._snackBar.open('El JSON ha sido copiado al portapapeles.', 'Cerrar', {
          duration: 3000,
        });
      },
      (error) => {
        console.error('Error al copiar JSON al portapapeles:', error);
        this._snackBar.open('Error al copiar JSON al portapapeles.', 'Cerrar', {
          duration: 3000,
        });
      }
    );
  }
  importbtn = false
  importTask() {
    this.importbtn = true
    if (!this.code) {
      this._snackBar.open('No hay JSON válido para importar.', 'Cerrar', {
        duration: 3000
      });
      this.importbtn = false
      return;
    }

    try {
      const parsedData = JSON.parse(this.code); // Verifica que el JSON sea válido antes de hacer la importación
      this.missionsService.importTaskById(parsedData).pipe(
        tap(response => {
          this._snackBar.open('Tarea creada correctamente.', 'Cerrar', {
            duration: 2000,
          });
          this.closeDialog();
          this.router.navigate(['/missions', response.task_id]);
        }),
        catchError(error => {
          console.error('Error al importar tarea:', error);
          this._snackBar.open('Error al importar la tarea.', 'Cerrar', {
            duration: 3000,
          });
          return of(null);
        }),
        takeUntil(this.destroy$)
      ).subscribe();
    } catch (error) {
      console.error('Error al parsear JSON para importar:', error);
      this._snackBar.open('Error en el formato del JSON.', 'Cerrar', {
        duration: 3000
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close(); // Cierra el diálogo sin pasar datos
  }
}