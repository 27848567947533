<div class="ctn-global">
    @if(rules$ | async; as rules){
    @if(!disableInput){
    <app-search-autocomplete [disabledBool]="disableInput" [title]="'Busca una regla para continuar'" [items]="rules"
        (itemSelected)="onItemSelected($event)">
    </app-search-autocomplete>
    <br>
    }

    <form [formGroup]="taskRuleForm" (ngSubmit)="saveRuleTaskParms()">
        <div class="params">
            <h4>@if(disableInput){<span class="editp">Editando</span>}Parámetros {{data.data.name}} </h4>
            <button mat-raised-button color="primary" type="submit" [disabled]="!taskRuleForm.valid">Guardar</button>
        </div>


        <div formArrayName="taskruleparameters">
            <div *ngFor="let param of taskruleparameters2.controls; let i = index" [formGroupName]="i">
                <div>
                    <span class="title-param">{{i + 1}}</span> <span>{{param.get('key')?.value}}</span> -
                    <span class="format">Formato: <b>{{param.get('format')?.value}}</b></span>
                </div>
                <mat-form-field class="full-width" appearance="outline">
                    <input matInput formControlName="value" placeholder="Ingrese el valor del parámetro" required>
                    <mat-error *ngIf="param.get('value')?.hasError('required')">
                        Este campo es requerido
                    </mat-error>
                </mat-form-field>
                <hr style="opacity: .2;">
            </div>
        </div>
    </form>
    }
</div>