import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [MatSidenavModule, MatButtonModule],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class MenuComponent {
  showFiller = false;
  constructor() {
    console.log('menu')
  }

  ngOnInit(): void {
    
    console.log('menu init')
  }
}
