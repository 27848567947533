import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root',
})
export class IndexedDbService {
    private dbName = 'MyDbCr';
    private dbVersion = 2;
    private db: IDBDatabase | null = null;

    http = inject(HttpClient)

    private apiUrl = `${environment['api_recognition']}/v2/inference`
    private token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfdWlkIjoiNGM2NWY2YmEtM2Q2My00MzU3LTllN2EtODI3NDdjMTgxYWJiIiwiY2xpZW50X2lkIjo1LCJpYXQiOjE3MjYxOTY5NDUsImV4cCI6MTcyNjIwNzc0NX0.DzVfEo7gNM9C2tcKY50tbqP1Yo8mxLOzhs0lcTUqh68';

    constructor() {
        this.openDatabase().then(() => { });
    }

    public openDatabase(): Promise<void> {
        return new Promise((resolve, reject) => {
            const openRequest = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                this.db = (event.target as IDBOpenDBRequest).result;

                if (event.oldVersion < 1) {
                    const objectStore = this.db.createObjectStore('images', { keyPath: 'id', autoIncrement: true });
                    objectStore.createIndex('fileName', 'fileName', { unique: false });
                    objectStore.createIndex('request_id', 'request_id', { unique: false });
                }

                // if (event.oldVersion < 2) {
                //     // Ejemplo: si la versión previa es menor que 2, agrega un nuevo índice
                //     const objectStore = (event.target as IDBOpenDBRequest).transaction?.objectStore('images');
                //     if (objectStore && !objectStore.indexNames.contains('newIndex')) {
                //         objectStore.createIndex('newIndex', 'newField', { unique: false });
                //     }
                // }
            };

            openRequest.onsuccess = (event: Event) => {
                this.db = (event.target as IDBOpenDBRequest).result;
                resolve();
            };

            openRequest.onerror = (event: Event) => {
                console.error('Error al abrir la base de datos:', (event.target as IDBOpenDBRequest).error);
                reject((event.target as IDBOpenDBRequest).error);
            };
        });
    }


    public loadImages(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                return reject('Database not initialized');
            }
            const transaction = this.db.transaction('images', 'readonly');
            const objectStore = transaction.objectStore('images');

            const getAllRequest = objectStore.getAll();

            getAllRequest.onsuccess = (event) => {
                const images = (event.target as IDBRequest).result;
                // console.log('Imágenes recuperadas:', images);
                resolve(images);
            };

            getAllRequest.onerror = (event) => {
                // console.error('Error al recuperar las imágenes:', (event.target as IDBRequest).error);
                reject((event.target as IDBRequest).error);
            };
        });
    }
    public saveImage(fileName: string, blobData: Blob, analysisData: any = null, request_id: any = null): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                console.error('Database not initialized');
                return reject('Database not initialized');
            }
            const transaction = this.db.transaction('images', 'readwrite');
            const objectStore = transaction.objectStore('images');

            const image = { fileName, blobData, analysisData, request_id };

            const addRequest = objectStore.put(image);

            addRequest.onsuccess = () => {
                // console.log('Imagen guardada con éxito', image);
                resolve();
            };

            addRequest.onerror = (event) => {
                // console.error('Error al guardar la imagen:', (event.target as IDBRequest).error);
                reject((event.target as IDBRequest).error);
            };
        });
    }

    public updateImageData(fileName: string, analysisData: any, requestId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                console.error('Database not initialized');
                return reject('Database not initialized');
            }

            const transaction = this.db.transaction(['images'], 'readwrite');
            const store = transaction.objectStore('images');
            const index = store.index('fileName');

            const request = index.get(fileName);

            request.onsuccess = () => {
                const data = request.result;
                if (data) {
                    data.analysisData = analysisData;
                    data.request_id = requestId;

                    const updateRequest = store.put(data);

                    updateRequest.onsuccess = () => {
                        resolve();
                    };

                    updateRequest.onerror = () => {
                        reject(updateRequest.error);
                    };
                } else {
                    reject('Image not found');
                }
            };

            request.onerror = () => {
                console.error('Error al obtener la imagen:', request.error);
                reject(request.error);
            };
        });
    }


    public getImagesByRequestId(requestId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                return reject('Database not initialized');
            }
            const transaction = this.db.transaction(['images'], 'readonly');
            const store = transaction.objectStore('images');
            const index = store.index('request_id');
            const request = index.getAll(requestId);

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event) => {
                console.error('Error al recuperar imágenes por request_id:', request.error);
                reject(request.error);
            };
        });
    }


    public deleteImage(id: number): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                return reject('Database not initialized');
            }
            const transaction = this.db.transaction('images', 'readwrite');
            const objectStore = transaction.objectStore('images');
            const deleteRequest = objectStore.delete(id);

            deleteRequest.onsuccess = () => {
                console.log('Imagen eliminada con éxito');
                resolve();
            };

            deleteRequest.onerror = (event) => {
                console.error('Error al eliminar la imagen:', (event.target as IDBRequest).error);
                reject((event.target as IDBRequest).error);
            };
        });
    }

    resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                // Ajusta el tamaño basado en la mayor dimensión
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx: any = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('Error al crear el Blob'));
                    }
                }, file.type, 0.9); // Calidad al 90%
            };

            img.onerror = (error) => {
                reject(error);
            };
        });
    }

    // Método para enviar múltiples imágenes en batch
    sendBatchImages(files: Blob[], fileNames: string[], modelId: number): Observable<any> {
        // console.log(files, fileNames, modelId)

        const url = `${this.apiUrl}/batch`;
        const formData: FormData = new FormData();

        formData.append('models', modelId.toString());

        files.forEach((file, index) => {
            formData.append('files', file, fileNames[index]);
        });
        return this.http.post(url, formData);
    }





}