<app-main-header [name_main]="'Tableros'">
    <app-user-card></app-user-card>
</app-main-header>


@if(dashBoards$ | async; as dash){
@if(dash.length === 0){
<div style="display: grid; place-items: center;">
    <p>No hay tableros asignados al usuario</p>
</div>
}

<div class="ctn-card-bi">
    @for (item of dash; track $index) {
    <div class="card-bi">
        <div class="image" [ngStyle]="{'background-image': 'url(' + 'assets/back.webp' + ')'}">
            <div class="image" [ngStyle]="{'background-color': getBackgroundColor($index)}">


            </div>

        </div>


        <div [ngSwitch]="item.client_id" class="icon-client">
            <!-- {{item.client_id}} -->
            <img *ngSwitchCase="7" src="assets/logo-pepsi.svg" width="100">
            <img *ngSwitchCase="2" src="assets/logo-postb.svg" width="100">
            <img *ngSwitchCase="4" src="assets/logo-lucro.svg" width="100">
            <img *ngSwitchCase="5" src="assets/logo-alpina.svg" width="70">
            <img *ngSwitchCase="37" src="assets/logo-bepe.svg" width="100">
            <img *ngSwitchDefault src="assets/not.svg" width="100">
        </div>

        <!-- <img [src]="d.client === 'pepsi' ? 'uno' : 'dos'" alt="postobon" width="100"> -->
        <!-- <img src="assets/post.png" alt="postobon" width="100"> -->


        <div class="body-card-bi">
            <h4 class="title-bi">{{item.name}}</h4>
            <button class="btn-bi" (click)="godash(item)" mat-flat-button>
                Ir a tablero
            </button>
        </div>

    </div>
    }
</div>
}@else{
<div style="display: grid; place-items: center;">
    <p>No hay tableros asignados al usuario</p>
</div>

}
<!-- <p class="fot">Designed by Lucro Ⓒ2024</p> -->