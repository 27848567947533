// src/app/services/auth.service.ts
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { Auth, getAuth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { GoogleAuthProvider, signInWithPopup, OAuthProvider } from '@firebase/auth';
import { Firestore, collection, doc, getDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private baseUrl = environment.apiURL;
    router = inject(Router);
    private userDataSubject = new BehaviorSubject<any>(null); 
    userData$ = this.userDataSubject.asObservable();
    constructor(private http: HttpClient, private auth: Auth, private firestore: Firestore, private snackBar: MatSnackBar) {
        this.auth.onAuthStateChanged(async (user) => {
            if (user) {
                const userData = await this.getUserData(user.uid);
                const claims: any = await this.getUserClaims(user);
                // console.log('claims',claims)
                if (userData) {
                    // console.log('userData',userData)
                    this.userDataSubject.next({ ...userData});
                }
            } else {
                this.userDataSubject.next(null); // Restablecer `BehaviorSubject` si no hay usuario
            }
        });
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/login`, { username, password });
    }
    // Método para obtener datos del usuario desde Firestore
    private async getUserData(uid: string): Promise<any> {
        try {

            const userDocRef = doc(this.firestore, `users/${uid}`);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                return userDocSnap.data();
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error al obtener datos del usuario', error);
            return null;
        }
    }

    private async getUserClaims(user: any): Promise<any> {
        try {
            const tokenResult = await user.getIdTokenResult();
            return tokenResult.claims || {};
        } catch (error) {
            console.error('Error al obtener claims del usuario:', error);
            return null;
        }
    }

    // Método para exponer los datos del usuario actual como un Observable
    getUserDataObservable(): Observable<any> {
        return this.userData$;
    }

    signinWithEmailAndPass(email: any, password: any) {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                this.snackBar.open(errorCode, 'Cerrar', {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                });
            });

    }

    async signInGoogle() {
        try {
            const creds = await signInWithPopup(this.auth, new GoogleAuthProvider());
            const uid = creds.user.uid;
        } catch (error) {
            console.log('Error durante la autenticación con Google', error);
        }
    }

    async signInMicrosoft() {
        try {
            const provider = new OAuthProvider('microsoft.com');
            const creds = await signInWithPopup(this.auth, provider);
            const uid = creds.user.uid;
        } catch (error) {
            console.log('Error durante la autenticación con Microsoft', error);
        }
    }


    async signOut() {
        try {
            await this.auth.signOut()
            this.router.navigate(['/auth']);
        } catch (error) {
            console.log('error logout', error)
        }
    }

    async checkUserAuthorization(uid: string): Promise<boolean> {
        try {
            const userDocRef = doc(this.firestore, `users/${uid}`);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                console.log('Usuario autorizado');
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error al verificar autorización del usuario', error);
            return false;
        }
    }

    showUnauthorizedMessage(): void {
        this.snackBar.open('Usuario no autorizado.', 'Cerrar', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
        });
    }



}


