// Archivo: fixed-two-decimals.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixedTwoDecimals',
  standalone: true,
})
export class FixedTwoDecimalsPipe implements PipeTransform {
  transform(value: number): string {
    return value.toFixed(2);
  }
}
