import { Component, inject } from '@angular/core';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-rules-play-ground',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
    MainHeaderComponent,
    AutoCompleteModule,
    FormsModule
  ],
  templateUrl: './rules-play-ground.component.html',
  styleUrl: './rules-play-ground.component.css'
})
export class RulesPlayGroundComponent {
  titleheader: string = 'Playground'
  selectedItems: any[] | undefined;
  items: any[] = [];
  router = inject(Router)
  activatedRoute = inject(RouterLink)

  onSelect($event: any) {
    console.log('this.selectedItems', this.selectedItems)
    // this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { m: this.selectedItems?.map(v => v.model_id) } })
  }

  onUnselect($event: any){
    // this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { m: this.selectedItems?.map(v => v.model_id) } })
  }

  search(event: any) {
    const query = event.query.toLowerCase();
    // console.log('query', query)
    // this.items = this.allItems.filter(item => {
    //   const matches = item.name.toLowerCase().includes(query);
    //   return matches;
    // });
  }


}
