@if(envP){
<div class="dev">
    Entorno de desarrollo
</div>
}
<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
    </mat-drawer>
    <mat-drawer-content class="example-sidenav-content">
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>