<app-main-header [name_main]="'Reglas'">
    <button class="check-btn" mat-flat-button routerLink="/rules/create">
        CREAR
    </button>
   <button [disabled]="!selectedProducts.length || !canDelete" class="check-btn" mat-flat-button color="warn"
        (click)="deleteRule(selectedProducts)">
        BORRAR
    </button>
</app-main-header>

<app-data-table 
    [data]="data" 
    [columns]="columns" 
    [rows]="50" 
    [rowsPerPageOptions]="[10, 25, 50]" 
    (rowSelect)="onRowSelect($event)" 
    [selection]="selectedItems" 
    (selectionChange)="onSelectionChange($event)"
    [sortField]="'rule_id'" 
    [sortOrder]="-1"
    [showFilters]="true"
    (deleteItems)="onDeleteItems($event)"
    (editItem)="onEditItem($event)">
</app-data-table>

