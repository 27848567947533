<p>{{config | json}}</p>
<p>list component</p>
<!-- <div class="grid-container">
    @if(config.field && config.field.type == 'array'){
    <div [formArrayName]="config.name" class="col-12">
        <mat-divider></mat-divider>
        <div class="section-header">
            <h3>{{ config.label ? config.label : config.name | titlecase }}</h3>
            <button mat-button color="primary" class="mr-25"
                (click)="addArrayFormWithFormGroup(getFormArrayFromGroup(formGroup, config.name), config.field);$event.stopPropagation()">Agregar
                nuevo</button>
        </div>
        <mat-divider></mat-divider>
        <mat-accordion>

            @for(arrayGroup of getFormArrayFromGroup(formGroup, config.name).controls; track $index; let a1 =
            $index){

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title> {{a1}}: cant:
                        ({{getFormArrayInArray(arrayGroup).controls.length}})</mat-panel-title>
                    <mat-panel-description>
                        <button mat-button color="primary" class="mr-25"
                            (click)="addFormGroupInArray(getFormArrayInArray(arrayGroup), config.field);$event.stopPropagation()">Agregar
                            nuevo</button>
                        <button mat-button color="warn"
                            (click)="removeItemByIndex(getFormArrayFromGroup(formGroup, config.name), a1)">Eliminar</button>
                    </mat-panel-description>

                </mat-expansion-panel-header>
                <mat-accordion>
                    @for(innerarrayGroup of getFormArrayInArray(arrayGroup).controls; track $index; let a2 =
                    $index){

                    <mat-expansion-panel class="mat-elevation-z0 panel-main-level">
                        <mat-expansion-panel-header>
                            <div class="mat-panel-header-array-container">
                                <mat-panel-title> {{a2}}: </mat-panel-title>
                                <button mat-button color="warn" class="mr-25"
                                    (click)="removeItemByIndex(getFormArrayInArray(arrayGroup), a2); $event.stopPropagation()">Eliminar</button>
                            </div>
                        </mat-expansion-panel-header>
                        <app-object-field [config]="config.field"
                            [formGroup]="getFormGroup(innerarrayGroup)"></app-object-field>

                    </mat-expansion-panel>
                    }
                </mat-accordion>


            </mat-expansion-panel>

            }
        </mat-accordion>


    </div>
    }
</div> -->