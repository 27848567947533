<mat-accordion *ngIf="recognitionResults$ | async as recognitionResults">

    <div *ngIf="recognitionResults.status?.response?.length > 0">

        <mat-expansion-panel *ngFor="let result of recognitionResults.status.response; let resultIndex = index"
            expanded="true" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>Resultado reconocimiento</mat-panel-title>
                <mat-panel-description>{{ result.image_id }}</mat-panel-description>
            </mat-expansion-panel-header>

            <div>

                @for(d of result.details; track idx; let idx = $index){
                <p>{{d.rule}}</p>
                <span>Peso: {{d.score}}</span>
                <span> - {{d.approved ? 'Aprobada' : 'Desaprovada'}}</span>
                }

                <div class="product-viewer">
                    <div class="ima">
                        <div #container class="image-container">
                            <img #image [src]="result.results?.image_url" class="image" (load)="onImageLoad()" />
                            <svg #svgContainer class="svg-overlay"></svg>
                            <svg width="0" height="0">
                                <defs>
                                    <pattern id="line-pattern" width="4" height="4" patternUnits="userSpaceOnUse"
                                        patternTransform="rotate(45)">
                                        <rect width="2" height="4" transform="translate(0,0)" fill="white"></rect>
                                        <animateTransform attributeName="patternTransform" type="translate" from="0,0"
                                            to="4,4" begin="0s" dur="1s" repeatCount="indefinite" />
                                    </pattern>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="list-objects">
                        <span><b>Listado de objetos detectados: {{ result.results?.inferences?.length }}</b></span>
                        <span *ngFor="let inference of result.results?.inferences; let idx = index"
                            [class.selected]="idx === selectedProductIndex"
                            (mouseover)="highlightBox(inference, idx)">
                            <span class="number">{{ idx + 1 }}.</span>
                            <span>({{inference.model_id}})</span>
                            <span>{{ inference.name }}</span>
                            <span [ngClass]="inference.score > 0.5 ? 'valid':'verify'">{{ inference.score }}</span>
                        </span>
                    </div>
                </div>


                <!-- @for(d of result.details; let idx = $index; track idx ){
                {{d | json}}
                } -->
            </div>
            <!-- <div class="product-viewer">
                <div class="ima">
                    <div #container class="image-container">
                        <img #image [src]="result.results.image_url" class="image" (load)="onImageLoad()" />
                        <svg #svgContainer class="svg-overlay"></svg>
                        <svg width="0" height="0">
                            <defs>
                                <pattern id="line-pattern" width="4" height="4" patternUnits="userSpaceOnUse"
                                    patternTransform="rotate(45)">
                                    <rect width="2" height="4" transform="translate(0,0)" fill="white"></rect>
                                    <animateTransform attributeName="patternTransform" type="translate" from="0,0"
                                        to="4,4" begin="0s" dur="1s" repeatCount="indefinite" />
                                </pattern>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div class="list-objects">
                    <span><b>Listado de objetos detectados: {{ result.results.inferences.length }}</b></span>
                    <span *ngFor="let inference of result.results.inferences; let idx = index"
                        [class.selected]="idx === selectedProductIndex"
                        (mouseover)="highlightBox(inference, idx, resultIndex)">
                        <span class="number">{{ idx + 1 }}.</span>
                        <span>({{inference.model_id}})</span>
                        <span>{{ inference.name }}</span>
                        <span [ngClass]="inference.score > 0.5 ? 'valid':'verify'">{{ inference.score }}</span>
                    </span>
                </div>
            </div> -->
        </mat-expansion-panel>
        <!-- {{recognitionResults | json}} -->
    </div>



    <!-- <mat-expansion-panel *ngFor="let result of recognitionResults; let resultIndex = index" expanded="true" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
            <mat-panel-title>Resultado reconocimiento</mat-panel-title>
            <mat-panel-description>{{ result.image_id }}</mat-panel-description>
        </mat-expansion-panel-header>
        <div class="product-viewer">
            <div class="ima">
                <div #container class="image-container">
                    <img #image [src]="result.image_url" class="image" (load)="onImageLoad()" />
                    <svg #svgContainer class="svg-overlay"></svg>
                    <svg width="0" height="0">
                        <defs>
                            <pattern id="line-pattern" width="4" height="4" patternUnits="userSpaceOnUse"
                                patternTransform="rotate(45)">
                                <rect width="2" height="4" transform="translate(0,0)" fill="white"></rect>
                                <animateTransform attributeName="patternTransform" type="translate" from="0,0" to="4,4"
                                    begin="0s" dur="1s" repeatCount="indefinite" />
                            </pattern>
                        </defs>
                    </svg>
                </div>
            </div>
            <div class="list-objects">
                <span><b>Listado de objetos detectados: {{ result.inferences.length }}</b></span>
                <span *ngFor="let inference of result.inferences; let idx = index"
                    [class.selected]="idx === selectedProductIndex"
                    (mouseover)="highlightBox(inference, idx, resultIndex)">
                    <span class="number">{{ idx + 1 }}.</span>
                    <span>({{inference.model_id}})</span>
                    <span>{{ inference.name }}</span>
                    <span [ngClass]="inference.score > 0.5 ? 'valid':'verify'">{{ inference.score }}</span>
                </span>
            </div>
        </div>
    </mat-expansion-panel> -->
</mat-accordion>