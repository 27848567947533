<div [formGroup]="formGroup">
    @for(field of config.fields; track $index){

    @if(field.type == 'string'){
    <app-input-field [type]="'string'" [config]="field"
        [formControl]="getFormControl(formGroup, field.name)"></app-input-field>
    }
    @else if(field.type == 'number'){
    <app-input-field [type]="'number'" [config]="field"
        [formControl]="getFormControl(formGroup, field.name)"></app-input-field>
    }
    @else if(field.type == 'boolean'){
    <app-checkbox-field [config]="field" [formControl]="getFormControl(formGroup, field.name)"></app-checkbox-field>
    }
    @else if(field.type == 'chips'){
    <app-chip-field class="full-width" [formControl]="getFormControl(formGroup, field.name)"
        [config]="field"></app-chip-field>
    }
    @else if(field.type == 'model'){
    <app-model-field [config]="field" [formControl]="getFormControl(formGroup, field.name)"></app-model-field>
    }
    @else if(field.type == 'option'){
    <app-select-field [config]="field" [formControl]="getFormControl (formGroup, field.name)"></app-select-field>
    }
    @else if(field.type == 'library'){
    <app-library-objects-field [config]="field" [formControl]="getFormControl (formGroup, field.name)"></app-library-objects-field>
    }
    @else{
    <app-input-field [type]="'string'" [config]="field"
        [formControl]="getFormControl(formGroup, field.name)"></app-input-field>
    }

    }
</div>