import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Firestore, collection, collectionData, doc, docData } from '@angular/fire/firestore';
import { RouterLink } from '@angular/router';
import { catchError, Observable, tap } from 'rxjs';
import { DashboardService } from './board.service';
import { MatButtonModule } from '@angular/material/button';
import { SafePipe } from '../../pipes/safe.pipe';

@Component({
  selector: 'app-boards',
  standalone: true,
  imports: [CommonModule, RouterLink, MatButtonModule, SafePipe],
  templateUrl: './boards.component.html',
  styleUrl: './boards.component.css'
})
export class BoardsComponent {
  dashboardService = inject(DashboardService)
  constructor() { }
  selectedDashboardUrl$ = this.dashboardService.selectedDashboardUrlSignal;

  // dashboards$ = this.dashboardService.getDashboards();
  ngOnInit(): void {
    // this.powerBiUrl$ = this.dashboardService.getPowerBiUrl();
  }


}