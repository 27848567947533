import { computed, Injectable, signal } from '@angular/core';
import { Clients } from '../models/clients';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Config } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private baseUrl: string = `${environment['api_backend']}/api`
  private apiUrlRecon = `${environment['api_recognition']}/v1/recognition`
  private apiUrlDetails = `${environment['api_recognition']}/v2/recognition/details`
  // TOKEN PARA API RECOGNITION
  private headers = new HttpHeaders({
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfdWlkIjoiY2IxNDk2Y2QtOTJhZi00YjE0LWFiZWItMjMwZWUzZGU5ZjA4IiwiY2xpZW50X2lkIjo0LCJpYXQiOjE3MjI0ODIzOTIsImV4cCI6MTcyMjQ5MzE5Mn0.6VEgFrRSx9uHEGWpAMhJViRzxwnOc7EyamkgrhnEojw`,
      'Accept': 'application/json'
  });
  private ClientsSignal = signal<Clients[]>([]);
  clients = computed(() => this.ClientsSignal());
  clients$ = new BehaviorSubject<Clients[]>(this.ClientsSignal()); 

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(error);
  }
  constructor(private http: HttpClient) {};

    updateClients( clients: Partial<Clients>, clientsId:number) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.patch<Clients>(`${this.baseUrl}/clients/${clientsId}`, clients, { headers })
          .pipe(
              catchError(this.handleError)
          );
    }
    getClientsById(clientsId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/clients/${clientsId}`); 
    }
    createClients(clients: any) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post<any>(`${this.baseUrl}/clients`, clients, { headers })
          .pipe(
              catchError(this.handleError)
          )
    };

    loadClientsStore(): void {
        let params = new HttpParams().set('limit', '200');
        this.http.get<Clients[]>(`${this.baseUrl}/clients`, { params }).subscribe((clients: Clients[],) => {
           console.log('clientes desde el api:', clients)
            this.ClientsSignal.set(clients);
        })
    }

    getClients(): Clients[] {
      return this.ClientsSignal();
    }
  
      deleteClients(clientsId:number): Observable<Clients> {
      return this.http.delete<Clients>(`${this.baseUrl}/clients/${clientsId}`)
    } 
}