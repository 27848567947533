 <app-main-header [name_main]="'Clientes'">
    <button class="check-btn" mat-flat-button routerLink="/clients/create">
        CREAR
    </button>
    <button [disabled]="!selectedProducts.length" class="check-btn"  mat-flat-button color="warn"
    (click)="deleteClient(selectedProducts)">
    BORRAR
</button>
</app-main-header>
<app-data-table 
    [data]="clientsService.clients()" 
    [columns]="columns" 
    [rows]="50" 
    [rowsPerPageOptions]="[10, 25, 50]" 
    (rowSelect)="onRowSelect($event)" 
    [selection]="selectedItems" 
    (selectionChange)="onSelectionChange($event)"
    [sortField]="'clients'"
    [sortOrder]="-1"
    [showFilters]="true"
    (deleteItems)="onDeleteItems($event)"
    (editItem)="onEditItem($event)">
</app-data-table> 
