import { ChangeDetectionStrategy, ChangeDetectorRef, Component, output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MainHeaderComponent } from '../shared/main-header/main-header.component';
import { MatButtonModule } from '@angular/material/button';
import { TableModule } from 'primeng/table';
import { RulesService } from './rules.service';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'd3';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatIconModule, MatButtonModule,
    MainHeaderComponent
  ],
  styleUrls: ['./rules.component.css']
})
export class RulesComponent {
  ruleId: number
  for_save: boolean = false
  creating: boolean = true
  titleheader: string = 'Regla'
  toDeleteParams: number[] = [];
  toDeleteOutputs: number[] = [];
  constructor(private fb: FormBuilder, private ruleService: RulesService, private router: Router, private route: ActivatedRoute, private _snackBar: MatSnackBar) {
    this.ruleId = +this.route.snapshot.paramMap.get('id')!;
    if (this.ruleId) {
      this.titleheader = 'Editando Regla'
      this.creating = false
      this.ruleService.getRuleById(this.ruleId).subscribe(
        d => {
          console.log(d)
          this.patchForm(d)
        }
      )
    } else {
      this.titleheader = 'Creando Regla'
    }
  }


  ruleForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    weight: [0, Validators.required],
    version: [],
    function_name: ['', Validators.required],
    rulesparameters: this.fb.array([this.initParameters()], this.minLengthArray(1)), 
    ruleoutputs: this.fb.array([this.initOutputs()])
  });

  formConfig = [
    { controlName: 'name', label: 'Nombre', type: 'text', width: '100%' },
    { controlName: 'description', label: 'Descripción', type: 'textarea', width: '100%' },
    { controlName: 'weight', label: 'Peso', type: 'number', width: '20%' },
    { controlName: 'function_name', label: 'Nombre función', type: 'text', width: '40%' },
    { controlName: 'version', label: 'Versión', type: 'text', width: '30%' }
  ];


  ngOnInit(): void {
    this.ruleForm.valueChanges.subscribe(
      d => console.log('data form', this.ruleForm.value)
    )
  }
  patchForm(data: any) {
    this.ruleForm.patchValue({
      name: data.name,
      description: data.description,
      weight: data.weight,
      version: data.version,
      function_name: data.function_name
    });

    this.patchRulesParameters(data.rulesparameters);
    this.patchRuleOutputs(data.ruleoutputs);
  }

  patchRulesParameters(rulesparameters: any[]) {
    const formArray = this.ruleForm.get('rulesparameters') as FormArray;
    formArray.clear(); // Clear existing items

    rulesparameters.forEach(item => {
      formArray.push(this.fb.group({
        key: [item.key, Validators.required],
        format: [item.format, Validators.required],
        rule_parameter_id: [item.rule_parameter_id]
      }));
    });
  }

  patchRuleOutputs(ruleoutputs: any[]) {
    const formArray = this.ruleForm.get('ruleoutputs') as FormArray;
    formArray.clear(); // Clear existing items

    ruleoutputs.forEach(item => {
      formArray.push(this.fb.group({
        key: [item.key, Validators.required],
        label: [item.label, Validators.required],
        type: [item.type, Validators.required],
        rule_output_id: [item.rule_output_id]
      }));
    });
  }

  generateUniqueId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  initParameters() {
    return this.fb.group({
      key: ['', Validators.required],
      format: ['', Validators.required],
      input_validator: [''],
      trackingId: this.generateUniqueId()
    });
  }

  initOutputs() {
    return this.fb.group({
      key: ['', Validators.required],
      label: ['', Validators.required],
      type: ['', Validators.required],
      trackingId: this.generateUniqueId()
    });
  } 


  get rulesparameters(): FormArray {
    return this.ruleForm.get('rulesparameters') as FormArray;
  }

  get ruleoutputs(): FormArray {
    return this.ruleForm.get('ruleoutputs') as FormArray;
  }

  
  addParameter() {
    this.rulesparameters.push(this.initParameters());
  }
  removeParameter(index: number) {
    const parameter = this.rulesparameters.at(index).value;
    if (parameter.rule_parameter_id) {
      if (confirm('¿Estás seguro que deseas eliminar este parámetro?')){
        this.ruleService.deleteParameter(parameter.rule_parameter_id).subscribe({
           next: () => {
          this.rulesparameters.removeAt(index); // Eliminar solo si la API responde correctamente
          this._snackBar.open('Parámetro eliminado correctamente.', 'cerrar', { duration: 3000 });
        },
        error: (err) => {
          console.error('Error al eliminar parámetro:', err);
          this._snackBar.open('Error al eliminar parámetro. Por favor, inténtalo nuevamente.', 'cerrar', { duration: 3000 });
        },
        })
      }
    } else {
    console.warn('El parámetro no tiene un ID válido:', parameter);
    this.rulesparameters.removeAt(index); 
   }
  }

  addOuput() {
    this.ruleoutputs.push(this.initOutputs());
  }
  removeOuput(index: number) {
    const output = this.ruleoutputs.at(index).value;
    if (output.rule_output_id) {
      if (confirm('¿Estás seguro que deseas eliminar esta salida?')){
        this.ruleService.deleteOutput(output.rule_output_id).subscribe({
           next: () => {
          this.ruleoutputs.removeAt(index); // Eliminar solo si la API responde correctamente
          this._snackBar.open('Salida eliminada correctamente.', 'cerrar', { duration: 3000 });
        },
        error: (err) => {
          console.error('Error al eliminar salida:', err);
          this._snackBar.open('Error al eliminar salida. Por favor, inténtalo nuevamente.', 'cerrar', { duration: 3000 });
        },
        })
      }
    } else {
    console.warn('La salida no tiene un ID válido:', output);
    this.ruleoutputs.removeAt(index); 
   }
  }

  onSubmit() {
    console.log('value: ', this.ruleForm.value);
    console.log('valid: ', this.rulesparameters.value);
    console.log('valid: ', this.rulesparameters.valid);
   this.createRule()
  }

  onEdit() {
    console.log('OnEdit _ value: ', this.ruleForm.value);
    this.updateRule()
  }

  createRule() {
    if (this.ruleForm.valid) {
      const formValue = this.ruleForm.value;
      const ruleData = {
        name: formValue.name,
        version: formValue.version,
        description: formValue.description,
        function_name: formValue.function_name,
        weight: Number(formValue.weight),
        rulesparameters: formValue.rulesparameters.map((param: any) => ({
          key: param.key,
          format: param.format,
          input_validator: param.input_validator
        })),
        ruleoutputs: formValue.ruleoutputs.map((param: any) => ({
          key: param.key,
          label: param.label,
          type: param.type
        })),
      };
      this.ruleService.createRule(ruleData).subscribe({
        next: () => {
          this.router.navigate(['/rules']);
          this._snackBar.open('Regla creada.', 'ok'); 
        },
        error: () => this._snackBar.open('Error al crear la regla.',  'cerrar')
      });
    }
  }
    updateRule() {
    if (this.ruleForm.valid) {
      const formValue = this.ruleForm.value;
      const ruleData = {
        name: formValue.name,
        version: formValue.version,
        description: formValue.description,
        function_name: formValue.function_name,
        weight: Number(formValue.weight),
        rulesparameters: formValue.rulesparameters.map((param: any) => ({
          key: param.key,
          format: param.format,
          input_validator: param.input_validator,
          rule_parameter_id: param.rule_parameter_id
        })),
        ruleoutputs: formValue.ruleoutputs.map((param: any) => ({
          key: param.key,
          label: param.label,
          type: param.type,
          rule_output_id: param.rule_output_id
        })),
      // toDeleteParams: this.toDeleteParams, // Incluir parámetros a eliminar
      // toDeleteOutputs: this.toDeleteOutputs 
      };
      this.ruleService.updateRule(this.ruleId, ruleData).subscribe({
        next: () => {
          this.router.navigate(['/rules']);
          this._snackBar.open('Regla actualizada.', 'ok'); 
        },
        error: () => this._snackBar.open('Error al actualizar la regla.',  'cerrar')
      });
    }
  }

   minLengthArray(min: number) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof FormArray && control.length < min) {
      return { minLengthArray: { requiredLength: min, actualLength: control.length } };
    }
    return null;
  };
 }
}