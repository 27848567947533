<mat-form-field class="full-width" appearance="outline">
    <mat-label>{{config.label ? config.label : config.name}}</mat-label>
    @if(type == 'textarea'){
    <textarea matInput [formControl]="formControl"
        [placeholder]="config.placeholder ? config.placeholder : 'Ingrese el valor del parámetro'"
        [required]="config.required ? config.required : false"></textarea>
    }
    @if(type == 'number'){
    <input matInput type="number" [formControl]="formControl"
        [placeholder]="config.placeholder ? config.placeholder : 'Ingrese el valor del parámetro'"
        [required]="config.required ? config.required : false">
    }
    @else{
    <input matInput [type]="type" [formControl]="formControl"
        [placeholder]="config.placeholder ? config.placeholder : 'Ingrese el valor del parámetro'"
        [required]="config.required ? config.required : false">
    }



</mat-form-field>