import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SecureService {
    private tokenUrl = `${environment.api_recognition}/auth/token?client_id=${environment.client}`;
    private localStorageKey = 'At_';
    private tokenExpiryKey = 'At_expiry';

    constructor(private http: HttpClient) { }

    private tokenRequiredEndpoints = [
        '/v2/model',
        '/v2/batch',
        '/v2/inference/batch'
    ];

    shouldAttachToken(url: string): boolean {
        const urlPath = new URL(url).pathname;
        // Verifica si el path coincide con cualquiera de los endpoints
        const requiresToken = this.tokenRequiredEndpoints.some(endpoint => {
            return urlPath === endpoint;
        });

        // console.log(`Checking if token is required for: ${urlPath} - Result: ${requiresToken}`);
        
        return requiresToken;
    }
    
    


    checkToken(): Observable<string | null> {
        const token = localStorage.getItem(this.localStorageKey);
        const tokenExpiry = localStorage.getItem(this.tokenExpiryKey);
        const now = new Date().getTime();

        if (token && tokenExpiry && now < +tokenExpiry) {
            return of(token);
        } else {
            // console.log('No valid token found, fetching new token');
            return this.fetchToken().pipe(
                tap(newToken => {
                    if (newToken) {
                        // console.log('New token fetched and stored in localStorage');
                        localStorage.setItem(this.localStorageKey, newToken);
                        localStorage.setItem(this.tokenExpiryKey, (now + 2 * 60 * 60 * 1000).toString());
                    }
                })
            );
        }
    }

    private fetchToken(): Observable<string | null> {
        const headers = new HttpHeaders({
            'accept': 'application/json',
            'Authorization': environment.key
        });
        // console.log('Fetching new token from API'); 
        return this.http.get<{ token: string }>(this.tokenUrl, { headers }).pipe(
            // tap(response => console.log('Token fetched successfully:', response.token)),
            map(response => response.token),
            catchError(error => {
                console.error('Error fetching token:', error);
                return of(null);
            })
        );
    }
}
