import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Inject, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FileUploadModule } from 'primeng/fileupload';
import { BehaviorSubject, catchError, interval, map, Observable, of, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';
import { MissionsService } from '../../../services/missions.service';
import { MatButtonModule } from '@angular/material/button';
import { ButtonModule } from 'primeng/button';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-send-recognition-bottomsheet',
  standalone: true,
  imports: [CommonModule, MatButtonModule, FileUploadModule, ButtonModule],
  templateUrl: './send-recognition-bottomsheet.component.html',
  styleUrl: './send-recognition-bottomsheet.component.css'
})
export class SendRecognitionBottomsheetComponent {
  @Output() recognitionFinished = new EventEmitter<any>();

  http = inject(HttpClient)

  uploadedFiles: any[] = [];
  send: boolean = false
  statusSubject$: Subject<string> = new Subject<string>();
  recognitionStatus$ = new BehaviorSubject<string>('');
  private stopPolling$ = new Subject<void>();  // Nuevo Subject para detener el polling
  constructor(private _bottomSheetRef: MatBottomSheetRef<SendRecognitionBottomsheetComponent>, private missionsService: MissionsService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }

  ngOnInit() {
    this._bottomSheetRef.containerInstance
    console.log(this.data)
  }

  onSelect(event: any) {
    console.log('event', event)
    this.uploadedFiles = event.currentFiles;
  }

  onUpload() {
    const taskId = this.data.task_key;  // Asegúrate de establecer el taskId correcto
    const metadata = {
      "additionalProp2": "string", "skus": ["8602", "4450", "8605", "8633",
        //  "16591", "15221", "10073", "4202", "4766", "11727",
        // "4758",
        // "4641",
        // "4757",
        // "4756",
        // "1606",
        // "847284",
        // "847285",
        // "847283",
        // "10460",
        // "15744",
        // "4767",
        // "4768", "10668",
        // "5058",
        // "4743",
        // "9029",
        // "4640",
        // "4642",
        // "13441",
        // "2971",
        // "4141",
        // "1205",
        // "1208",
        // "1130",
        // "12178",
        // "11656",
        // "5042",
        // "14483",
        // "16280",
        // "4242",
        // "16320",
        // "16321",
        // "5410",
        // "4454",
        // "15864",
        // "15876",
        // "16271",
        // "16256",
        // "16255",
        // "16254",
        // "4197",
        // "10147",
        // "15701",
        // "16109",
        // "7202",
        // "16370",
        // "16151",
        // "10632",
        // "2402",
        // "2401",
        // "2400",
        // "15930",
        // "11815",
        // "15822",
        // "2972",
        // "11655",
        // "1131",
        // "12177",
        // "12175",
        // "12176",
        // "1213",
        // "15888",
        // "15892",
        // "16139",
        // "13361",
        // "12173",
        // "12174",
        // "4739",
        // "4650",
        // "4740",
        // "4412",
        // "8649",
        // "10074",
        // "16250",
        // "16257",
        // "12162",
        // "16419",
        // "12476",
        // "16389",
        // "16396",
        // "16388",
        // "4810",
        // "4796",
        // "4811",
        // "4865",
        // "4807"
      ]
    };
    console.log('____ ____ ____ ___ taskId', taskId);
    console.log('onUpload', this.uploadedFiles);


    // Crear el FormData y añadir todos los archivos
    const formData: FormData = new FormData();
    for (let file of this.uploadedFiles) {
      formData.append('files', file, file.name);
    }
    formData.append('task_id', taskId);
    formData.append('metadata', JSON.stringify(metadata));

    this.missionsService.sendRecognition(formData).subscribe(
      response => {
        console.log('Recognition result:', response);
        if (response && response.recognition_id) {
          this.checkRecognitionStatus(response.recognition_id);

        }
      },
      error => {
        console.error('Error uploading files:', error);
      }
    );
  }


  checkRecognitionStatus(id: string) {
    const maxDuration = 1 * 60 * 1000; // 1 minuto en milisegundos
    const stopPolling = timer(maxDuration);

    interval(1000).pipe(
      switchMap(() => this.missionsService.getRecognitionStatus(id)),
      tap(status => {
        // console.log('Status check response:', status);
        this.recognitionStatus$.next(status.state);

        if (status.state === 'finished') {
          console.log('Recognition finished:', status);
          this.recognitionFinished.emit({ status, id }); // Emitir el evento al finalizar
          this._bottomSheetRef.dismiss({ status, id }); // Cerrar el bottom sheet con los datos
          this.stopPolling$.next(); // Emitir para detener el polling
        }
      }),
      takeUntil(this.stopPolling$), // Detener cuando se emite desde stopPolling$
      takeUntil(stopPolling.pipe(tap(() => console.log('Stopped polling after maximum duration'))))
    ).subscribe(
      status => {
        if (status.state === 'finished') {
          console.log('Final result:', status.response);
          this.stopPolling$.next(); // Asegurarse de detener el polling
        }
      }
    );
  }

  clearFiles() {
    this.uploadedFiles = [];
  }
}
