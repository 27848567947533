<div class="ctn-search">
    <!-- @if(icon === 'models'){
    <img width="80" src="assets/icons/no_model.svg" alt="">
    }@else {
    <img width="80" src="assets/icons/no_task.svg" alt="">
    } -->
    <p class="title">{{ title }}</p>
    <br>
    <p-autoComplete [disabled]="disabledBool" id="search-input" [(ngModel)]="selectedItem" [suggestions]="filteredItems"
        (completeMethod)="filterItems($event)" (onSelect)="onSelect($event)" field="name" [dropdown]="true"
        [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
        <ng-template let-item pTemplate="item">
            <div class="section-container flex align-items-center gap-2">
                <div>{{ item.name }}</div>
                <div class="small text-muted">{{ item.description }}</div>
                <span class="add-button">Añadir</span>
            </div>
        </ng-template>
    </p-autoComplete>
</div>