import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, inject, model, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IndexedDbService } from '../playground.service';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { ModelService } from '../../models/model.service';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { Models } from '../../../models/models';

declare const URL: any;
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-model-play-ground',
  standalone: true,
  imports: [CommonModule,
    MatButtonModule,
    MainHeaderComponent,
    AutoCompleteModule,
    FormsModule,
    RouterLink,
    RouterLinkActive,
    MatRadioModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MonacoEditorModule],
  templateUrl: './model-play-ground.component.html',
  styleUrl: './model-play-ground.component.css'
})
export class ModelPlayGroundComponent {
  modelsService = inject(ModelService)
  titleheader: string = 'Playground'
  ejec: boolean = true

  images: any[] = [];
  imageUrls: Map<number, string> = new Map();

  listModels$!: Observable<any>;

  imagesForm: FormGroup;
  selectedImages: number[] = [];

  selectedItems: any[] | undefined;
  items: any[] = [];
  allItems: any[] = [];


  editorOptions = {
    theme: 'vs-dark',
    language: 'javascript',
    automaticLayout: true
  };

  code: string = 'function x() { console.log("Hello world!"); }';

  readonly checked = model(false);
  readonly indeterminate = model(false);
  readonly labelPosition = model<'before' | 'after'>('after');
  readonly disabled = model(false);

  constructor(
    private imageService: IndexedDbService, 
    private cd: ChangeDetectorRef, 
    private fb: FormBuilder, 
    private activatedRoute: ActivatedRoute) {
    this.imagesForm = this.fb.group({});
  }

  ngOnInit() {
    const modes$ = this.modelsService.getModels().pipe(map(models => {
      return models.map((model: Models) => ({ view: `(${model.model_id}) ${model.name}`, ...model }))
        .filter((item: any) => item.is_active)
    }))

    modes$.subscribe(data => {
      this.allItems = data
    });



    combineLatest([modes$, this.activatedRoute.queryParams]).subscribe(([models_data, params]) => {
      const _params = params['m']
      let models = []

      if (typeof _params === 'string')
        models = [+_params]
      else if (typeof _params === 'object')
        models = params['m'].map(Number)
      else
        models = []

      const new_models = models_data.filter((model: any) => models.includes(model.model_id))
      this.selectedItems = new_models
    });

    this.checkCameraAvailability();

    this.imageService.openDatabase().then(() => {
      this.loadImages();
    }).catch(error => {
      console.error('Error al abrir la base de datos:', error);
    });
  }

  onSelect($event: any) {
    console.log('this.selectedItems', this.selectedItems)
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { m: this.selectedItems?.map(v => v.model_id) } })
  }

  onUnselect($event: any){
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { m: this.selectedItems?.map(v => v.model_id) } })
  }

  hasCamera: boolean = false;
  // Método para verificar si el dispositivo tiene una cámara disponible
  checkCameraAvailability() {
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        this.hasCamera = devices.some(device => device.kind === 'videoinput');
      })
      .catch(error => {
        console.error('Error al verificar la disponibilidad de la cámara:', error);
      });
  }
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;


  selectFile() {
    this.fileInput.nativeElement.accept = 'image/*';  // Permitir cualquier tipo de archivo
    this.fileInput.nativeElement.click();
  }

  // Método para abrir el selector de archivos en modo cámara
  captureFromCamera() {
    if (this.hasCamera) {
      this.fileInput.nativeElement.accept = 'image/*';  // Solo imágenes
      this.fileInput.nativeElement.capture = 'environment';  // Usar la cámara trasera, si está disponible
      this.fileInput.nativeElement.click();
    } else {
      console.warn('Este dispositivo no tiene cámara disponible.');
    }
  }

  // Método que se ejecuta cuando se selecciona un archivo o se captura una imagen
  captureImage(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.imageService.resizeImage(file, 1400, 1200).then(resizedBlob => {
        return this.imageService.saveImage(file.name, resizedBlob);
      }).then(() => {
        this.loadImages();
      }).catch(error => {
        console.error('Error al procesar la imagen:', error);
      });
    }
  }


  // -------------------------------------
  // Método para manejar cambios en los checkboxes
  onCheckboxChange(imageId: number) {
    console.log('checkox change')
    const isSelected = this.imagesForm.get(imageId.toString())?.value;

    if (isSelected) {
      // Si el checkbox está marcado, añadimos la imagen a la lista de seleccionadas
      this.selectedImages.push(imageId);
    } else {
      // Si el checkbox se desmarca, eliminamos la imagen de la lista
      this.selectedImages = this.selectedImages.filter(id => id !== imageId);
    }

    console.log('Imágenes seleccionadas:', this.selectedImages);
  }

  // Método para eliminar una imagen (mantiene tu lógica actual)
  deleteImage(id: number) {
    this.imageService.deleteImage(id).then(() => {
      const url = this.imageUrls.get(id);
      if (url) {
        URL.revokeObjectURL(url);
        this.imageUrls.delete(id);
      }
      this.loadImages();
    }).catch(error => {
      console.error('Error al eliminar la imagen:', error);
    });
  }

  // loadImages() {
  //   this.imageService.loadImages().then(images => {
  //     this.images = images;
  //     if (this.images.length > 0) {
  //       this.selectedImageId = this.images[0].id;
  //     }
  //     this.images.forEach(image => {
  //       const blobKey = image.id;
  //       if (!this.imageUrls.has(blobKey)) {
  //         const url = this.createImageURL(image.blobData);
  //         this.imageUrls.set(blobKey, url);
  //       }
  //     });
  //     this.cd.detectChanges();
  //   }).catch(error => {
  //     console.error('Error al cargar imágenes:', error);
  //   });
  // }
  // Cargar imágenes desde IndexedDB
  loadImages() {
    this.imageService.loadImages().then(images => {
      this.images = images;

      // Asegúrate de que se creen los controles del formulario para cada imagen
      this.images.forEach(image => {
        const controlName = image.id.toString();  // Convertir ID a string
        if (!this.imagesForm.contains(controlName)) {  // Solo agrega el control si no existe
          this.imagesForm.addControl(controlName, new FormControl(false));  // Crear un FormControl con valor inicial false
        }
      });

      // Crear URLs de las imágenes y agregarlas al mapa de imageUrls
      this.images.forEach(image => {
        const blobKey = image.id;
        if (!this.imageUrls.has(blobKey)) {
          const url = this.createImageURL(image.blobData);
          this.imageUrls.set(blobKey, url);
        }
      });

      this.cd.detectChanges();  // Detectar los cambios en el componente
    }).catch(error => {
      console.error('Error al cargar imágenes:', error);
    });
  }



  createImageURL(blob: Blob): string {
    const url = URL.createObjectURL(blob);
    return url;
  }

  ngOnDestroy() {
    this.imageUrls.forEach(url => URL.revokeObjectURL(url));
    this.imageUrls.clear();
  }

  
  search(event: AutoCompleteCompleteEvent) {
    const query = event.query.toLowerCase();
    // console.log('query', query)
    this.items = this.allItems.filter(item => {
      const matches = item.name.toLowerCase().includes(query);
      return matches;
    });
  }


  boundingBoxes: any[] = [];
  selectedImageId!: any
  router = inject(Router)
  // sendImagesToAPI() {
  //   const files: Blob[] = this.images.map(image => image.blobData);
  //   const fileNames: string[] = this.images.map(image => image.fileName);

  //   const modelId = this.selectedModelId;
  //   if (modelId === null) {
  //     console.error('No se ha seleccionado un modelo.');
  //     return;
  //   }

  //   this.imageService.sendBatchImages(files, fileNames, modelId).subscribe({
  //     next: (response: any) => {
  //       console.log('Respuesta recibida:', response);
  //       if (response && response.results) {
  //         response.results.forEach((result: any) => {
  //           this.imageService.updateImageData(result.filename, result, response.request_id).catch(console.error);
  //         });
  //         this.router.navigate(['/playground/models', response.request_id]);
  //       } else {
  //         console.error('No results or detections found in response:', response);
  //       }
  //       this.cd.markForCheck();
  //     },
  //     error: (error: any) => console.error('Error al enviar las imágenes:', error),
  //     complete: () => console.log('Envío de imágenes completado.')
  //   });
  // }
  uncheckOthers(selectedIndex: number) {
    this.images.forEach((img, index) => {
      if (index !== selectedIndex) img.selected = false;
    });
  }
  onImageSelectionChange() {
    console.log("La imagen seleccionada tiene el ID:", this.selectedImageId);
  }
  // sendSelectedImageToAPI() {
  //   const selectedImage = this.images.find(image => image.id === this.selectedImageId);

  //   if (!selectedImage) {
  //     console.error('No se ha seleccionado ninguna imagen.');
  //     return;
  //   }

  //   const file: Blob = selectedImage.blobData;
  //   const fileName: string = selectedImage.fileName;
  //   const modelId = this.selectedModelId;

  //   // console.log('modelId', modelId)
  //   // console.log('modelId2', this.selectedItems?.[0]?.model_id)

  //   if (modelId === null) {
  //     console.error('No se ha seleccionado un modelo.');
  //     return;
  //   }

  //   this.imageService.sendSingleImage(file, fileName, this.selectedItems?.[0]?.model_id).subscribe({
  //     next: (response: any) => {
  //       if (response && response.result) {
  //         this.imageService.updateImageData(fileName, response.result, response.request_id).catch(console.error);
  //         this.router.navigate(['/playground/models', response.request_id]);
  //       } else {
  //         console.error('No results or detections found in response:', response);
  //       }
  //       this.cd.markForCheck();
  //     },
  //     error: (error: any) => console.error('Error al enviar la imagen:', error),
  //     complete: () => console.log('Envío de imagen completado.')
  //   });
  // }
  block: boolean = false

  private _snackBar = inject(MatSnackBar);

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom'
    });
  }


  sendSelectedImagesToAPI() {
    this.openSnackBar('Analizando...', 'Ok')
    this.block = true
    // Obtener los valores seleccionados del FormGroup
    const selectedValues = this.imagesForm.value;

    // Filtrar los IDs seleccionados
    const selectedImageIds = Object.keys(selectedValues).filter(key => selectedValues[key]);

    // Verificar si hay imágenes seleccionadas
    if (selectedImageIds.length === 0) {
      console.error('No images');
      this.openSnackBar('Selecciona una imagen!!!', 'Ok')
      this.block = false
      return;
    }

    // Preparar las imágenes seleccionadas para el envío en batch
    const files: Blob[] = [];
    const fileNames: string[] = [];

    selectedImageIds.forEach(selectedId => {
      const selectedImage = this.images.find(image => image.id.toString() === selectedId);

      if (selectedImage) {
        files.push(selectedImage.blobData);
        fileNames.push(selectedImage.fileName);
      }
    });

    // Recoger todos los model_id seleccionados en un array
    const selectedModelIds: any = (this.selectedItems ?? []).map(item => item.model_id);


    // Verificar si hay modelos seleccionados
    if (selectedModelIds.length === 0) {
      console.error('No se ha seleccionado ningún modelo.');
      return;
    }

    // Enviar las imágenes seleccionadas en un batch
    this.imageService.sendBatchImages(files, fileNames, selectedModelIds).subscribe({
      next: (response: any) => {
        if (response && response.results) {
          response.results.forEach((result: any) => {
            this.imageService.updateImageData(result.filename, result, response.request_id).catch(console.error);
          });
          this.router.navigate(['/playground/models', response.request_id]);
        } else {
          console.error('No results or detections found in response:', response);
        }
        this.cd.markForCheck();
      },
      error: (error: any) => {
        this.openSnackBar('Error, intentalo nuevamente.', 'Ok')
        this.block = false
      },
      complete: () => console.log('Envío de imágenes completado.')
    });
  }
}
