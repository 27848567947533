import { Component, CUSTOM_ELEMENTS_SCHEMA, forwardRef, inject, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FormHandlerService } from '../form-handler.service';
import { ObjectFieldComponent } from '../object-field/object-field.component';
import { DynamicFormField } from '../model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-list-field',
  standalone: true,
  templateUrl: './list-field.component.html',
  styleUrl: './list-field.component.css',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ListFieldComponent),
    multi: true
  }],
  imports: [CommonModule, ReactiveFormsModule, ObjectFieldComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ListFieldComponent implements ControlValueAccessor {
  @Input() config!: DynamicFormField;
  @Input() formGroup!: FormGroup;

  constructor(private fb: FormBuilder) {
    console.log('this.formControl', this.formGroup)
    console.log('this.config', this.config)
  }
  private form_handle = inject(FormHandlerService);
  writeValue(value: string): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  addArrayFormWithFormGroup(parentGroup: AbstractControl, field: any) {
    const parentArray = parentGroup as FormArray
    const formArray_2 = this.fb.array([]);
    formArray_2.push(this.form_handle.createFormGroupFromFields(field.fields as any) as any)
    parentArray.push(formArray_2 as any)
  }

  removeItemByIndex(parentGroup: AbstractControl, index: number) {
    this.form_handle.removeItemByIndex(parentGroup, index)
  }

  getFormGroup(group: AbstractControl) {
    return this.form_handle.getFormGroup(group)
  }

  getFormArrayFromGroup(group: AbstractControl, fieldName: string): FormArray {
    return this.form_handle.getFormArrayFromGroup(group, fieldName)
  }

  getFormArrayInArray(parentGroup: AbstractControl) {
    return this.form_handle.getFormArrayInArray(parentGroup)
  }

  addFormGroupInArray(parentGroup: AbstractControl, field: any) {
    this.form_handle.addFormGroupInArray(parentGroup, field)
  }
}
