import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-main-header',
  standalone: true,
  imports:[MatButtonModule],
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent {
  @Input() name_main: string = '...'
  @Input() id_code?: number = undefined
  constructor(){
    // this.id_code = this.id_code
  }
  // name_main: string = 'nombre de la pagina'
  // id_code: string = '38e232c9-06cf-417c-98b7-3c2d10de4120'
  ngOnInit(): void {
  }
}
