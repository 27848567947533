import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SecureService } from '../services/secure.service';

@Injectable()
export class SecureInterceptor implements HttpInterceptor {

    constructor(
        private secureService: SecureService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.secureService.shouldAttachToken(req.url)) {
            return next.handle(req);
        }

        return this.secureService.checkToken().pipe(
            switchMap(token => {
                if (token) {
                    const cloned = req.clone({
                        headers: req.headers.set('Authorization', `Bearer ${token}`)
                    });
                    return next.handle(cloned);
                } else {
                    return next.handle(req);
                }
            })
        );
    }
}
