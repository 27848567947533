<div id="particles-js"></div>
<section class="login">
    <p class="wel"> Bienvenidos a la plataforma de análisis y control de reconocimientos</p>
    <div class="content create-account-form">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="Tu email">
                <mat-hint>Ingresa tu email</mat-hint>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Contraseña</mat-label>
                <input matInput formControlName="password" placeholder="Tu contraseña" type="password">
                <mat-hint>Ingresa tu contraseña</mat-hint>
            </mat-form-field>

            <button  class="sgin" mat-flat-button color="primary" type="submit" [disabled]="!loginForm.valid">
                Iniciar sesión
            </button>
        </form>

        <button class="btn-signin" (click)="signinWithGoogle()" mat-button #tooltip="matTooltip" matTooltip="Google"
            matTooltipPosition="right">
            <div class="ctn-iconSig">
                <img src="assets/google.svg" alt="" width="24">
                <span>Continuar con Google</span>
            </div>
        </button>
        <button class="btn-signin" (click)="signinWithMicrosoft()" mat-button #tooltip="matTooltip"
            matTooltip="Microsoft" matTooltipPosition="right">
            <div class="ctn-iconSig"><img src="assets/microsoft.svg" alt="" width="24">
                <span>Continuar con Microsoft</span>
            </div>
        </button>
    </div>

    <div class="fott">
        <img src="assets/byl.svg" alt="" width="100">
    </div>
</section>

<!-- <img src="assets/images/logo.svg" alt="Lucro" style="margin-bottom:30px" width="160"> -->
<!-- <form [formGroup]="formLogin" (ngSubmit)="loginSubmit()" autocomplete="off">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Correo electrónico</mat-label>
                <input matInput type="email" formControlName="email">
                <mat-error *ngIf="formLogin.controls.email.hasError('email')">Correo electrónico no valido.
                </mat-error>
                <mat-error *ngIf="formLogin.controls.email.hasError('required')">El correo electrónico es requerido.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" style="margin-top: 12px" appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">

                <span matSuffix class="cursor-pointer" (click)="hide = !hide" class="material-symbols-outlined">
                    {{hide ? 'visibility' : 'visibility_off'}}
                </span>
                <mat-error *ngIf="formLogin.controls.password.hasError('required')">La contraseña es
                    requerida.</mat-error>
            </mat-form-field>
            <button (click)="signinWithEmail()" type="submit" class="btn-form" mat-flat-button color="primary"
                [disabled]="!formLogin.valid" style="margin-top:14px">
                INGRESAR
            </button>
        </form> -->