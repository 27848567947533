import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterLink } from '@angular/router';
import { Table } from 'primeng/table';
import { Button } from 'primeng/button';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ClientsService } from '../../../services/clients.service';
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { Clients } from '../../../models/clients';


@Component({
  selector: 'app-list-clients',
  standalone: true,
  imports: [MainHeaderComponent, DataTableComponent, RouterLink, MatButtonModule, MatButton, CommonModule, MatFormFieldModule, DataTableComponent, MainHeaderComponent],
  templateUrl: './list-clients.component.html',
  styleUrl: './list-clients.component.css',
  providers: [ClientsService],
})
export class ListClientsComponent {
  clients$!: Observable<Clients[]>
  selected!: Clients[];
  products!: Clients[];
  selectedProducts: Clients[] = [];

  selectedItems: any[] = [];

  columns: any[] = [
    { field: 'client_id', header: 'Id', filter: true, orderby: true },
    { field: 'client_uid', header: 'Uid', filter: true, orderby: false },
    { field: 'name', header: 'Nombre', filter: true, orderby: false },
   { field: 'config.default_bucket', header: 'Almacenamiento', filter: true, orderby: false },
    { field: 'config.filter_products', header: 'Filtrar producto', filter: true, orderby: false }, 
    { field: 'config.validate_unique', header: 'Validación única', filter: true, orderby: false },
    { field: 'config.validate_unique_key', header: 'Id de validación', filter: true, orderby: false },
    { field: 'config.webhook_endpoint', header: 'Url de webhook', filter: true, orderby: false },
    { field: 'config.webhook_active', header: 'link activo', filter: true, orderby: false }, 

  ];

  @ViewChild('dt')table!: Table;
  constructor(public clientsService: ClientsService, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.clientsService.loadClientsStore()
    console.log("tabla____", this.table) 
  }
  onSelectionChange(event: any) {
    console.log('event', event)
    this.selectedProducts = event
  }
  onEditItem(item: any) {
    console.log('onEditItem items:', item);
    this.router.navigate(['/clients', item.client_id]);
  }

  onDeleteItems(items: any) {
    console.log('Delete items:', items);
    if (window.confirm("Esto eliminará este cliente y todas sus configuraciones")) { 
    this.clientsService.deleteClients(items.client_id).subscribe(() => {
     this.selectedProducts = []
     console.log('client delete')
     this._snackBar.open(`Cliente ${items.client_id} eliminado.`, 'ok');
     this.clientsService.loadClientsStore()
   })
  } 
  }

  deleteClient( clients: Clients[]) {
     this._snackBar.open('Eliminando...', 'ok');
    if (window.confirm("Esto eliminará este cliente y todas sus configuraciones")) {
        clients.map(clients => {
        this.clientsService.deleteClients(clients.client_id).subscribe(() => {
          this.selectedProducts = []
          console.log('cliente delete')
          this._snackBar.open(`Cliente ${clients.client_id} eliminado.`, 'ok');
          this.clientsService.loadClientsStore()
        })
      })
      } 
    }
      onRowSelect(event: any) {
        console.log('select', this.selectedProducts)
       
    
    }
}
 
