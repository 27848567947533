import { Component, CUSTOM_ELEMENTS_SCHEMA, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormField } from '../model';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-library-objects-field',
  standalone: true,
  templateUrl: './library-objects-field.component.html',
  styleUrl: './library-objects-field.component.css',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LibraryObjectsFieldComponent),
    multi: true
  }],
  imports: [ReactiveFormsModule, MatTableModule, MatButtonModule, MatInputModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LibraryObjectsFieldComponent implements ControlValueAccessor {
  @Input() config!: DynamicFormField;
  @Input() formControl!: FormControl;
  productInput = new FormControl('');
  products: string[] = [];
  displayedColumns: string[] = ['name', 'actions'];

  ngOnInit() {
    if (this.formControl.value) {
      if (typeof this.formControl.value == 'string') {
        this.products = this.formControl.value.split(',')
      } else {
        this.products = this.formControl.value
      }

    } else {
      this.products = []
    }
  }

  addProducts(): void {
    const input = this.productInput.value;
    if (input) {
      const newProducts = input.split(',').map(p => p.trim()).filter(p => p);
      this.products = [...this.products, ...newProducts];
      this.productInput.setValue('');
    }
    this.formControl.setValue(this.products)
  }

  removeProduct(product: string): void {
    this.products = this.products.filter(p => p !== product);
    this.formControl.setValue(this.products)
  }

  writeValue(value: string): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}
