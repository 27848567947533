import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { Firestore, collection, collectionData, doc, docData } from '@angular/fire/firestore';
import { RouterLink } from '@angular/router';
import { catchError, map, Observable, tap } from 'rxjs';
import { DashboardService } from './board.service';
import { MatButtonModule } from '@angular/material/button';
import { SafePipe } from '../../pipes/safe.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-boards',
  standalone: true,
  imports: [CommonModule, RouterLink, MatButtonModule, SafePipe, MatMenuModule, MatTooltipModule],
  templateUrl: './boards.component.html',
  styleUrl: './boards.component.css'
})
export class BoardsComponent {
  dashboardService = inject(DashboardService)
  isLoading: boolean = false
  snackBar = inject(MatSnackBar)
  constructor() { }
  // console.log('selectedDashboardUrlSignal', selectedDashboardUrl$)
  selectedDashboardUrl$ = this.dashboardService.selectedDashboardUrlSignal;
  granular$: any = this.dashboardService.granular$
  gestion$: any = this.dashboardService.gestion$


  // dashboards$ = this.dashboardService.getDashboards();
  ngOnInit(): void {
    // this.powerBiUrl$ = this.dashboardService.getPowerBiUrl();
  }

  downloadQuery(queryUrl: string): void {
    this.isLoading = true
    this.snackBar.open('Descargando...', '', { duration: 30000 });
    this.dashboardService.downloadFile(queryUrl).subscribe({
      next: (blob: Blob) => {
        // Crear un enlace de descarga
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'datos.csv';
        link.click();
        window.URL.revokeObjectURL(url);
        this.isLoading = false;
        this.snackBar.open('Descarga completada', 'Cerrar', { duration: 3000 });
      },
      error: (err) => {
        console.error('Error al descargar el archivo:', err);
        this.isLoading = false;
      },
    });
  }

}