<app-main-header [name_main]="titleheader">
    <section class="btns">
        <button mat-button routerLink="/playground/models">Modelos</button>
        <button mat-button routerLink="/playground/rules" routerLinkActive="activebuttonb">Tareas</button>
        <button mat-button routerLink="/playground/task" [disabled]="true">Reglas</button>
    </section>
</app-main-header>
<div class="ctn-aut">
    <p-autoComplete id="search-input" [(ngModel)]="selectedItems" (onSelect)="onSelect($event)" (onUnselect)="onUnselect($event)" [suggestions]="items" (completeMethod)="search($event)"
        [multiple]="true" field="view" [dropdown]="true" placeholder="Buscar módelo de reconocimiento"></p-autoComplete>
</div>