import { ChangeDetectorRef, Component, computed, effect, ElementRef, inject, signal, ViewChild, WritableSignal } from '@angular/core';
import { RecognitionSystemLibraryService } from '../../../recongition-system-api/recongnition-library';
import { catchError, combineLatest, filter, from, fromEvent, map, merge, Observable, startWith, tap, throwError } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { IndexedDbService } from '../playground.service';
import { RequestPayload } from '../../../recongition-system-api/model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { db } from '../../../recongition-system-api/db';
import { liveQuery } from 'dexie';
import { HttpEventType } from '@angular/common/http';
import { MissionsService } from '../../../services/missions.service';
import { ScreenSizeService } from '../../../services/screen.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { WebcamModule } from 'ngx-webcam';
import { MatDialog } from '@angular/material/dialog';
import { DialogCameraComponent } from '../../dialog-camera/dialog-camera.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
// import { ClientRecognitionDetails } from '../../../recongition-system-api/state';
// import { ClientRecognitionStore } from '../../../recongition-system-api/store';

@Component({
  selector: 'app-task-play-ground',
  standalone: true,
  imports: [CommonModule,
    MatButtonModule,
    MainHeaderComponent,
    AutoCompleteModule,
    FormsModule,
    RouterLink,
    RouterLinkActive,
    MatMenuModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MonacoEditorModule,
    MatTabsModule,
    MatProgressBarModule, MatIconModule, WebcamModule],

  templateUrl: './task-play-ground.component.html',
  styleUrl: './task-play-ground.component.css',
  providers: [RecognitionSystemLibraryService]
})
export class TaskPlayGroundComponent {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;
  readonly dialog = inject(MatDialog);
  images$ = this.system_recognition.getLocalImages()
  recognitions$ = this.system_recognition.getRecognitionWithDetails()
  titleheader: string = 'Playground'
  listModels$!: Observable<any>;
  imagesForm: FormGroup;
  selectedImages: number[] = [];
  block: boolean = false
  hasCamera: boolean = false;
  snackBar = inject(MatSnackBar)
  missionsService = inject(MissionsService)
  isLargeScreen$?: Observable<boolean>;
  screenSizeService = inject(ScreenSizeService)
  _snackBar = inject(MatSnackBar);
  router = inject(Router)
  upload_events = signal({
    state: 'initial',
    progress: 0,
    message: 'EJECUTAR'
  });
  readonly upload_eventss = computed(() => this.upload_events())
  buttonText: string = '';
  modeprogress: any = 'determinate'
  tasks$: Observable<any[]> = this.missionsService.tasks$;
  allTasks: any[] = [];
  filteredTasks: any[] = [];
  selectedItems: any | null = null;
  task_uid: string | null = null;
  groupedRecognitions$!: Observable<any[]>
  combinedData$!: Observable<any[]>;
  networkStatus$!: Observable<{ isOnline: boolean; connectionInfo: any }>;
  hasSelected$!: Observable<boolean>;
  constructor(
    public system_recognition: RecognitionSystemLibraryService,
    private fb: FormBuilder,
    private imageService: IndexedDbService,
    private activatedRoute: ActivatedRoute
  ) {
    this.imagesForm = this.fb.group({});
    this.images$ = this.images$.pipe(
      map(images => {
        const sortedImages = images.sort((a: any, b: any) => b.id - a.id);
        sortedImages.forEach((image: any) => {
          const controlName = image.id.toString();
          if (!this.imagesForm.contains(controlName)) {
            this.imagesForm.addControl(controlName, new FormControl(false));
          }
        });
        return sortedImages;
      })
    );

    from(this.system_recognition.getAllRecognitionImages()).subscribe((result: any) => {
      console.log('image recognition', result)
    })
    console.log('into', navigator.onLine, (navigator as any).connection)
    this.isLargeScreen$ = this.screenSizeService.screenWidth$.pipe(map((width) => width > 400));


  }

  openCamera(): void {
    const dialogRef = this.dialog.open(DialogCameraComponent, {
      panelClass: 'full-width-dialog'
      // data: {name: this.name(), animal: this.animal()},
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed', result);
      if (result) {
        const promises = result.map(async (url: string) => {
          const response = await fetch(url);
          const image = await response.blob()
          await this.system_recognition.setImage({
            filename: `${Math.random()}`,
            blob_data: await this.imageService.resizeImage(image as any, 1400, 1200)
          })
        })

        await Promise.all(promises)

      }


    });
  }


  ngOnInit(): void {
    this.missionsService.loadTaskStore();

    combineLatest([this.tasks$, this.activatedRoute.queryParams])
      .pipe(
        map(([tasks, params]) => {
          const tabIndex = params['tab'];
          this.activeTabIndex = tabIndex ? +tabIndex : 0;

          if (!Array.isArray(tasks)) {
            console.error('Error: "tasks" no es un array.', tasks);
            return;
          }
          this.allTasks = tasks.map(task => ({
            view: `(${task.task_key}) ${task.name}`,
            ...task
          }));
          this.filteredTasks = [...this.allTasks];
          const taskId = params['t'];
          if (taskId) {
            const task = this.allTasks.find(item => item.task_id === +taskId);
            this.selectedItems = task || null;
            this.task_uid = task?.task_key || null;
          } else {
            this.selectedItems = null;
            this.task_uid = null;
          }
        })
      )
      .subscribe();

    this.groupedRecognitions$ = combineLatest([this.recognitions$, this.tasks$]).pipe(
      map(([recognitions, tasks]) => {
        if (!Array.isArray(recognitions)) return [];

        const grouped = recognitions.reduce((acc: any, item: any) => {
          const key = item.recognition_id;
          if (!acc[key]) {
            const task = tasks.find(task => task.task_id === item.task_id);
            acc[key] = {
              recognition_id: key,
              task_id: item.task_id,
              task_key: item.task_key,
              updated_at: item.updated_at,
              task_name: task?.name || '',
              images: []
            };
          }
          acc[key].images.push({
            image_id: item.image_id,
            updated_at: item.updated_at,
            recognition_id: item.recognition_id,
            url: item.url
          });

          return acc;
        }, {});

        return Object.values(grouped);
      })
    );

    const getConnectionInfo = () => { return (navigator as any).connection || {} };
    const online$ = fromEvent(window, 'online').pipe(
      map(() => ({ isOnline: true, connectionInfo: getConnectionInfo() }))
    );
    const offline$ = fromEvent(window, 'offline').pipe(
      map(() => ({ isOnline: false, connectionInfo: {} }))
    );
    this.networkStatus$ = merge(online$, offline$).pipe(
      startWith({
        isOnline: navigator.onLine, connectionInfo: navigator.onLine ? getConnectionInfo() : {},
      })
    );

    this.hasSelected$ = this.imagesForm.valueChanges.pipe(
      startWith(this.imagesForm.value),
      map((values) => Object.values(values).some((val) => val === true))
    );

  }

  search(event: any): void {
    const query = event.query.toLowerCase();
    this.filteredTasks = this.allTasks.filter(task =>
      task.view.toLowerCase().includes(query)
    );
  }

  onSelect(event: any): void {
    // console.log('Evento recibido en onSelect:', event);
    if (!event || !event.task_id) {
      return;
    }
    this.selectedItems = event;
    this.task_uid = event.task_key;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { t: event.task_id },
      queryParamsHandling: 'merge'
    });
  }
  activeTabIndex = 0;
  onTabChange(index: number): void {
    this.router.navigate([], {
      queryParams: { tab: index },
      queryParamsHandling: 'merge'
    });
  }

  captureFromCamera() {
    if (this.hasCamera) {
      this.fileInput.nativeElement.accept = 'image/*';  // Solo imágenes
      this.fileInput.nativeElement.capture = 'environment';  // Usar la cámara trasera, si está disponible
      this.fileInput.nativeElement.click();
    } else {
      console.warn('Este dispositivo no tiene cámara disponible.');
    }
  }

  selectFile() {
    console.log('selected')
    this.fileInput.nativeElement.accept = 'image/*';  // Permitir cualquier tipo de archivo
    this.fileInput.nativeElement.click();
  }


  deleteImage(id: number) {
  }


  async captureImage(event: any) {
    console.log('capture')
    const file: File = event.target.files[0];
    if (file) {
      try {
        const image = await this.imageService.resizeImage(file, 1400, 1200)
        await this.system_recognition.setImage({
          filename: file.name,
          blob_data: image
        })
      } catch (error) {
        console.log(error)
      }

    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom'
    });
  }
  private _bottomSheet = inject(MatBottomSheet);

  openBottomConfirmDelete(image: any): void {
    this._bottomSheet.open(DialogConfirmComponent, { data: { image } });
  }
  async run() {

    this.block = true
    // Obtener los valores seleccionados del FormGroup
    const selectedValues = this.imagesForm.value;

    // Filtrar los IDs seleccionados
    const selectedImageIds = Object.keys(selectedValues).filter(key => selectedValues[key]).map(v => +v);

    // Verificar si hay imágenes seleccionadas
    if (selectedImageIds.length === 0) {
      console.error('No images');
      this.openSnackBar('Selecciona una imagen!!!', 'Ok')
      this.block = false
      return;
    }


    const selected = await db.getImagesByIds(selectedImageIds)

    if (!this.task_uid) {
      console.error('task_uid es nulo o indefinido');
      return;
    }
    const payload: RequestPayload = {
      task_id: this.task_uid,
      metadata: {},
      files: selected,
    }

    this.upload_events.update(value => ({
      ...value, state: 'uploading', message: 'SUBIENDO'
    }))

    this.system_recognition.sendRecognition(payload).pipe(
      catchError((error) => {
        this.upload_events.update(value => ({
          ...value, state: 'initial', message: 'EJECUTAR'
        }))
        this.snackBar.open('Error inesperado, intenta nuevamente.', 'Aceptar')
        return throwError(() => new Error(error.message));
      }),
      tap((event: any) => {
        let progress = 0
        if (event.type === 0) {
          console.log(progress)
          // this.client_recognition.set('upload_progress', progress)
          this.upload_events.update(value => ({
            progress: progress, state: 'uploading', message: 'SUBIENDO'
          }))
        }
        if (event.type === 1) {
          progress = Math.round((event.loaded / (event.total || 1)) * 100);
          this.upload_events.update(value => ({
            progress: progress, state: 'uploading', message: 'SUBIENDO'
          }))
        }

        //  console.log(event)

      }),
      filter(event => event.type === HttpEventType.Response),
      map(event => event.body)
    ).subscribe(async (value: any) => {
      console.log('llego', value.images)
      const recognition_id = value.recognition_id
      const result_images = value.images

      const seleted_images = selected.map(image => {
        return {
          local_image_id: image.id || 0,
          recognition_id,
          file_name: image.filename
        }
      })

      const map_images_for_save = new Map<string, any>();

      // Agregar elementos del primer array al mapa
      seleted_images.forEach((item) => map_images_for_save.set(item.file_name, { ...item }));

      // Mezclar elementos del segundo array
      result_images.forEach((item: any) => {
        if (map_images_for_save.has(item.original_name)) {
          map_images_for_save.set(item.original_name, { ...map_images_for_save.get(item.original_name), ...item });
        } else {
          map_images_for_save.set(item.original_name, { ...item });
        }
      });

      await db.addImageRecognitions(Array.from(map_images_for_save.values()))

      this.router.navigate(['../details', recognition_id], { relativeTo: this.activatedRoute }).then()
    })
  }



  splitText(text: string): string[] {
    return text.split(/(\(.*?\))/g).filter(e => e);
  }

  isParenthesis(part: string): boolean {
    return part.startsWith('(') && part.endsWith(')');
  }
}
