import { Component, ViewChild } from '@angular/core';
import { Users } from '../../models/user';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../users.service';
import { Table } from 'primeng/table';
import { Router, RouterLink } from '@angular/router';
import { MainHeaderComponent } from '../../components/shared/main-header/main-header.component';
import { DataTableComponent } from '../../components/shared/data-table/data-table.component';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-list',
  standalone: true,
  imports: [MainHeaderComponent, DataTableComponent, RouterLink, MatButtonModule, MatButton, CommonModule, MatFormFieldModule, DataTableComponent, ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.css',
  providers: [UsersService]
})
export class ListUsersComponent {
  users$!: Observable<Users[]>
  selected!: Users[];
  products!: Users[];
  selectedProducts: Users[] = [];
  selectedItems: any[] = [];
  formattedUsersData: any[] = [];

  columns: any[] = [
    { field: 'client_id', header: 'Cliente', filter: true, orderby: true },
    { field: 'uid', header: 'Uid', filter: true, orderby: false },
    { field: 'name', header: 'Nombre', filter: true, orderby: false },
    { field: 'email', header: 'Correo', filter: true, orderby: false },
    { field: 'roles', header: 'Roles', filter: true, orderby: false },
    { field: 'is_active', header: 'Activo', filter: true, orderby: false },
  ];

  @ViewChild('dt')table!: Table;
  constructor(public usersService: UsersService, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.usersService.users$.subscribe(users => {
      this.formattedUsersData = users.map(user => ({
        ...user,
        roles: this.formatRoles(user.roles) // Formatea los roles antes de asignarlos a la tabla
      }));
    });
    this.usersService.loadUsersStore() 
    console.log("tabla____", this.table) 
  }
  onSelectionChange(event: any) {
    console.log('event', event)
    this.selectedProducts = event
  }
  onEditItem(item: any) {
    console.log('onEditItem items:', item);
    this.router.navigate(['/users', item.uid]);
  }

  onDeleteItems(items: any) {
    console.log('Delete items:', items);
    if (window.confirm("Esto eliminará este usuario y todas sus configuraciones")) { 
    this.usersService.deleteUsers(items.uid).subscribe(() => {
     this.selectedProducts = []
     console.log('usuario delete')
     this._snackBar.open(`usuario ${items.uid} eliminado.`, 'ok');
     this.usersService.loadUsersStore()
   })
  } 
  }
  
  deleteUser( user: Users[]) {
     this._snackBar.open('Eliminando...', 'ok');
    if (window.confirm("Esto eliminará este usuario y todas sus configuraciones")) {
        user.map(user => {
          if (user.uid) { // Verifica si el usuario tiene uid
            this.usersService.deleteUsers(user.uid).subscribe(() => {
              this.selectedProducts = []; 
              this._snackBar.open(`Usuario ${user.name} eliminado.`, 'ok');
              this.usersService.loadUsersStore(); // Recargar la lista de usuarios
            });
          } else {
            console.error(`El usuario ${user.name} no tiene un uid válido`);
          }
        });
      } 
    }

  formatRoles(roles: any): string {
    if (!roles) return 'Sin roles';
    const enabledRoles = Object.keys(roles).filter(role => roles[role]);
    return enabledRoles.length ? enabledRoles.join(', ') : 'Sin roles';
  }

  onRowSelect(event: any) {
        console.log('select', this.selectedProducts)
       
  }
}
