import { inject, Injectable, signal } from '@angular/core';
import { Firestore, collection, collectionData, query, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import { defer, from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    firestore: Firestore = inject(Firestore);
    auth = inject(Auth)

    private selectedDashboardUrl = signal<string | null>(null);
    setSelectedDashboardUrl(url: string) {
        this.selectedDashboardUrl.set(url);
    }
    get selectedDashboardUrlSignal() {
        return this.selectedDashboardUrl();
    }

    getDashboards(): Observable<any> {
        return defer(() => {
            const currentUser = this.auth.currentUser;
            if (currentUser) { return of(currentUser) } else {
                return of(null);
            }
        }).pipe(
            switchMap(user => {
                const userId = user?.uid;
                const dashboardsRef = collection(this.firestore, 'dashboards');
                const q = query(dashboardsRef, where(`roles.${userId}`, '==', 'reader'));
                return collectionData(q, { idField: 'id' });
            })
        );
    }



}
