<div style="height: 360px;">
    <p-fileUpload [customUpload]="true" (onSelect)="onSelect($event)" (uploadHandler)="onUpload()"
        accept="image/*" maxFileSize="1000000" chooseLabel="Elegir imagenes" uploadLabel="Enviar" cancelLabel="Cancelar"
        invalidFileSizeMessageSummary="Tamaño de archivo no válido"
        invalidFileSizeMessageDetail="El tamaño del archivo excede el límite permitido"
        invalidFileTypeMessageSummary="Tipo de archivo no válido"
        invalidFileTypeMessageDetail="Este tipo de archivo no es soportado">
        <ng-template pTemplate="content">

        </ng-template>
    </p-fileUpload>
    @if(recognitionStatus$ | async) {
    <div class="down">
        <div class="loader">
            <div class="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
        En proceso...
    </div>
    }
</div>