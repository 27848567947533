<app-main-header [name_main]="'Modelos de reconocimiento'">
    <button class="check-btn" mat-flat-button routerLink="/models/create">
        CREAR
    </button>
    <button [disabled]="!selectedProducts.length" class="check-btn" mat-flat-button mat-flat-button color="warn"
    (click)="deleteModel(selectedProducts)">
    BORRAR
</button>
</app-main-header>
<!--  -->
<app-data-table 
    [data]="modelsService.models()" 
    [columns]="columns" 
    [rows]="50" 
    [rowsPerPageOptions]="[10, 25, 50]" 
    (rowSelect)="onRowSelect($event)" 
    [selection]="selectedItems" 
    (selectionChange)="onSelectionChange($event)"
    [sortField]="'model_id'" 
    [sortOrder]="-1"
    [showFilters]="true"
    (deleteItems)="onDeleteItems($event)"
    (editItem)="onEditItem($event)">
</app-data-table>

