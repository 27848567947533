import { Component, forwardRef, Input } from '@angular/core';
import { DynamicFormField } from '../model';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-select-field',
  standalone: true,
  templateUrl: './select-field.component.html',
  styleUrl: './select-field.component.css',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectFieldComponent),
    multi: true
  }],
  imports: [MatOptionModule, MatSelectModule, ReactiveFormsModule],
})
export class SelectFieldComponent implements ControlValueAccessor {
  @Input() config!: DynamicFormField;
  @Input() formControl!: FormControl;

  writeValue(value: string): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}
