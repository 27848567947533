import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TableModule } from 'primeng/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { RulesService } from '../rules.service';
import { Router, RouterLink } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../../../users/users.service';
export interface RuleParameter {
  rule_parameter_id: number;
  rule_id: number;
  key: string;
  format: string;
  input_validator: string;
  created_at: string;
  updated_at: string;
} 

export interface RuleOutput {
  rule_output_id: number,
  rule_id: number, 
  type: string,
  key: string,
  label: string
}

export interface Rule {
  rule_id: number;
  name: string;
  version: string;
  description: string;
  function_name: string;
  weight: number;
  created_at: string;
  updated_at: string;
  rulesparameters: RuleParameter[];
  ruleoutputs: any[];
}
@Component({
  // DataTableComponent
  standalone: true,
  imports: [CommonModule, MainHeaderComponent, DataTableComponent, MatButtonModule, MatMenuModule, RouterLink, MatFormFieldModule],
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponentRules implements OnInit {
  selectedItems: Rule[] = [];
  data: any[] = [];
  selectedProducts: Rule[] = [];
  canDelete: boolean = false;
  columns: any[] = [
    { field: 'rule_id', header: 'Id', filter: true, orderby: true },
    { field: 'name', header: 'Nombre', filter: true, orderby: true },
    { field: 'version', header: 'Versión', filter: true, orderby: true },
    { field: 'description', header: 'Descripción', filter: true, orderby: true },
    { field: 'function_name', header: 'Función', filter: true, orderby: true},
    { field: 'weight', header: 'Peso', filter: true, orderby: true },
    { field: 'num_parameters', header: 'Nº parametros', filter: true, orderby: true }
  ];

  showFilters = true;

  constructor(private rulesService: RulesService, private userService: UsersService,private router: Router, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.refreshTable();
    this.userService.loadUsersStore();
    this.checkUserRoles();
  } 

  checkUserRoles(): void {
    this.userService.getCurrentUserUid$().subscribe((uid) => {
    if (uid) {
      this.userService.users$.subscribe((users) => {
        const currentUser = users.find((user) => user.uid === uid);
        if (currentUser) {
          this.canDelete = currentUser.roles?.admin || currentUser.roles?.superadmin || false;
          console.log('Roles cargados:', currentUser.roles, 'Can Delete:', this.canDelete);
        } else {
          console.warn('Usuario no encontrado en la lista de usuarios.');
          this.canDelete = false;
        }
      });
    } else {
      console.error('No se pudo obtener el UID del usuario autenticado.');
      this.canDelete = false;
    }
  });
}


  onRowSelect(event: any) {
    console.log('Row selected:', event);
  }

  onSelectionChange(event: any) {
    this.selectedProducts = event;
  }

  onDeleteItems(items: any) {
  if (!this.canDelete) {
    this._snackBar.open('No tienes permisos para eliminar esta regla', 'Cerrar', { duration: 3000 });
    return;
  }
  
  if (window.confirm("Esto eliminará esta regla y todas sus configuraciones")) {
    this.rulesService.deleteRule(items.rule_id).subscribe({
      next: () => {
        this.selectedProducts = [];
        console.log('Regla eliminada:', items.rule_id);
        this._snackBar.open(`Regla ${items.rule_id} eliminada.`, 'ok', { duration: 3000 });
        this.refreshTable();
      },
      error: (err) => {
        console.error('Error al eliminar la regla:', err);
        this._snackBar.open(`Error: No se pudo eliminar la regla ${items.rule_id} debido a que ya está relacionada a una tarea.`, 'Cerrar', { duration: 4000 });
      }
    });
  }
}

  deleteRule(rule: Rule[]) {
    if (!this.canDelete) {
      this._snackBar.open('No tienes permisos para eliminar esta regla' , 'Cerrar', {duration: 3000});
      return;
    }
    if (window.confirm("Esto eliminará esta regla y todas sus configuraciones")) {
      rule.map((rule: any) => {
        this.rulesService.deleteRule(rule.rule_id).subscribe({
          next: () => {
          this.selectedProducts = [];
          console.log('Regla eliminada:', rule.rule_id);
          this._snackBar.open(`Regla ${rule.name} eliminada.`, 'ok', { duration: 3000 });
          this.refreshTable();
        },
      error: (err) => {
        this._snackBar.open(`Error: No se pudo eliminar la regla ${rule.rule_id} debido a que ya está relacionada a una tarea.`, 'Cerrar', { duration: 4000 });
        }
      });
    });
  }
}

  onEditItem(item: Rule) {
    console.log('onEditItem items:', item);
    this.router.navigate(['/rules', item.rule_id]);
  }

  refreshTable() {
  this.rulesService.getRules().subscribe({
    next: (rules: Rule[]) => {
      this.data = rules.map(rule => ({
        ...rule,
        num_parameters: rule.rulesparameters.length
      }));
    },
    error: (err) => {
      console.error('Error al cargar las reglas:', err);
      this._snackBar.open('Error cargando las reglas. Intenta nuevamente.', 'ok');
    }
  });
 } 

  onClone(item : any) {
    console.log("item", item)
    console.log("ID", item.rule_id)
    this.rulesService.cloneRule(item.rule_id).subscribe((response) => {
        this.refreshTable();
      
      const confirmEdit = confirm(
        '¡Duplicación con éxito!! ¿Quiere ser direccionado al nuevo elemento?'
      );

      if (confirmEdit) {
        this.router.navigate(['/rules', response.rule_id])
      } 
      else {
        this._snackBar.open(`Regla ${item.rule_id} clonada exitosamente.`, 'ok', {duration: 3000})
      }  
    }) 
  }
  }
