import { Component, CUSTOM_ELEMENTS_SCHEMA, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormField } from '../model';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@Component({
  selector: 'app-model-field',
  standalone: true,
  templateUrl: './model-field.component.html',
  styleUrl: './model-field.component.css',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ModelFieldComponent),
    multi: true
  }],
  imports: [MatOptionModule, MatSelectModule, ReactiveFormsModule, MatAutocompleteModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModelFieldComponent implements ControlValueAccessor {
  @Input() config!: DynamicFormField;
  @Input() formControl!: FormControl;

  writeValue(value: string): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}
