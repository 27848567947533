<div class="nav-top">
    <button class="back-btn" mat-icon-button routerLink="/boards">
        <span class="material-symbols-outlined">arrow_back</span>
    </button>

    <div>
        @if(granular$().length > 0){
        <button #tooltip="matTooltip" matTooltip="Granular" class="back-btn" [matMenuTriggerFor]="menua" mat-icon-button
            [disabled]="isLoading">
            <span class="material-symbols-outlined">download</span>
        </button>
        }

        @if(gestion$().length > 0){
        <button  #tooltip="matTooltip" matTooltip="Gestión"  class="back-btn" [matMenuTriggerFor]="menu" mat-icon-button [disabled]="isLoading">
            <span class="material-symbols-outlined">dataset</span>
        </button>
        }
    </div>
    <mat-menu #menua="matMenu">
        @for(item of granular$(); track $index){
        <button mat-menu-item (click)="downloadQuery(item.host)">
            <span>{{item.name}}</span>
        </button>
        }
    </mat-menu>
    <mat-menu #menu="matMenu">
        @for(item of gestion$(); track $index){
        <button mat-menu-item (click)="downloadQuery(item.host)">
            <span>{{item.name}}</span>
        </button>
        }
    </mat-menu>

</div>
<div class="powerbi-report-container">
    <iframe *ngIf="selectedDashboardUrl$" [src]="selectedDashboardUrl$.urlBi | safe" frameborder="0"
        allowFullScreen="true"></iframe>
</div>