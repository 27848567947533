<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <mat-accordion class="example-headers-align">
    <div *ngFor="let section of formConfig" class="grid-container">
      <!-- Sección de tipo array (nivel raíz) -->
      @if(section.type === 'array'){
      <mat-expansion-panel class="mat-elevation-z0 panel-main-level col-12">
        <mat-expansion-panel-header>
          <mat-panel-title> {{section.name | titlecase}} </mat-panel-title>
          <button mat-button color="primary" class="mr-25" (click)="addItem(section);$event.stopPropagation()">Agregar
            nuevo</button>
        </mat-expansion-panel-header>

        <div [formArrayName]="section.name">
          <mat-accordion>
            @for(group of getFormArray(section.name).controls; track $index; let i = $index){

            <mat-expansion-panel class="mat-elevation-z0 panel-main-level col-12">
              <mat-expansion-panel-header>
                <div class="mat-panel-header-array-container">
                  <mat-panel-title> {{i}} </mat-panel-title>
                  <button mat-button color="warn" (click)="removeItem(section.name, i)">Eliminar</button>
                </div>

              </mat-expansion-panel-header>

              <div [formGroupName]="i">
                @for(field of section.fields; track $index){

                <ng-container [ngSwitch]="field.type">
                  <!-- <app-list-field *ngSwitchCase="'list'" [config]="field" [formGroup]="getFormGroup(group)"></app-list-field> -->
                  <div class="grid-container" *ngSwitchCase="'list'">
                    @if(field.field && field.field.type == 'array'){
                    <div [formArrayName]="field.name" class="col-12">
                      <mat-divider></mat-divider>
                      <div class="section-header">
                        <h3>{{ field.label ? field.label : field.name | titlecase }}</h3>
                        <button mat-button color="primary" class="mr-25"
                          (click)="addArrayFormWithFormGroup(getFormArrayFromGroup(group, field.name), field.field);$event.stopPropagation()">Agregar
                          nuevo</button>
                      </div>
                      <mat-divider></mat-divider>
                      <mat-accordion>

                        @for(arrayGroup of getFormArrayFromGroup(group, field.name).controls; track $index; let a1 =
                        $index){

                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title> {{a1}}: cant:
                              ({{getFormArrayInArray(arrayGroup).controls.length}})</mat-panel-title>
                            <mat-panel-description>
                              <button mat-button color="primary" class="mr-25"
                                (click)="addFormGroupInArray(getFormArrayInArray(arrayGroup), field.field);$event.stopPropagation()">Agregar
                                nuevo</button>
                              <button mat-button color="warn"
                                (click)="removeItemByIndex(getFormArrayFromGroup(group, field.name), a1)">Eliminar</button>
                            </mat-panel-description>

                          </mat-expansion-panel-header>
                          <mat-accordion>
                            @for(innerarrayGroup of getFormArrayInArray(arrayGroup).controls; track $index; let a2 =
                            $index){

                            <mat-expansion-panel class="mat-elevation-z0 panel-main-level">
                              <mat-expansion-panel-header>
                                <div class="mat-panel-header-array-container">
                                  <mat-panel-title> {{a2}}: </mat-panel-title>
                                  <button mat-button color="warn" class="mr-25"
                                    (click)="removeItemByIndex(getFormArrayInArray(arrayGroup), a2); $event.stopPropagation()">Eliminar</button>
                                </div>
                              </mat-expansion-panel-header>
                              <app-object-field [config]="field.field"
                                [formGroup]="getFormGroup(innerarrayGroup)"></app-object-field>

                            </mat-expansion-panel>
                            }
                          </mat-accordion>


                        </mat-expansion-panel>

                        }
                      </mat-accordion>


                    </div>
                    }
                  </div>
                  
                  <app-select-field *ngSwitchCase="'option'" [config]="field"
                    [formControl]="getFormControl(group, field.name)"></app-select-field>

                  <app-input-field *ngSwitchCase="'string'" [type]="'string'" [config]="field"
                    [formControl]="getFormControl(group, field.name)"></app-input-field>

                  <app-input-field *ngSwitchCase="'number'" [type]="'number'" [config]="field"
                    [formControl]="getFormControl(group, field.name)"></app-input-field>

                  <div *ngSwitchCase="'boolean'" class="checkbox-field mb-20">
                    <app-checkbox-field [config]="field"
                      [formControl]="getFormControl(group, field.name)"></app-checkbox-field>
                  </div>

                  <ng-container *ngSwitchCase="'array'" class="col-12">
                    <!-- Se asume que field.fields existe y tiene al menos un elemento -->
                    @if(field.fields && field.fields.length > 0){
                    <div class="sublist-container">
                      <div [formArrayName]="field.name">
                        <h4 class="sublist-title">{{ field.name | titlecase }} (Sublista)</h4>

                        @for(subGroup of getFormArrayFromGroup(group, field.name).controls; track $index; let j =
                        $index){

                        <div class="level-1" [formGroupName]="j">
                          <!-- Itera sobre cada campo de la sublista -->
                          @for(subField of field.fields; track $index){
                          <div class="level-2">
                            <ng-container [ngSwitch]="subField.type">
                              <!-- Tipo option en sublista -->
                              <app-select-field *ngSwitchCase="'option'" [config]="subField"
                                [formControl]="getFormControl(subGroup, subField.name)"></app-select-field>
                              <!-- Tipo string -->
                              <app-input-field *ngSwitchCase="'string'" [type]="'string'" [config]="subField"
                                [formControl]="getFormControl(subGroup, subField.name)"></app-input-field>
                              <!-- Tipo number -->
                              <app-input-field *ngSwitchCase="'number'" [type]="'number'" [config]="subField"
                                [formControl]="getFormControl(subGroup, subField.name)"></app-input-field>
                              <!-- Tipo boolean -->
                              <div class="col-12 checkbox-field mb-20">
                                <app-checkbox-field *ngSwitchCase="'boolean'" [config]="subField"
                                  [formControl]="getFormControl(subGroup, subField.name)"></app-checkbox-field>
                              </div>
                              <!-- Tipo custom en sublista: usa el grupo local subGroup -->
                              <app-chip-field *ngSwitchCase="'chips'" class="full-width"
                                [formControl]="getFormControl(subGroup, subField.name)"
                                [config]="subField"></app-chip-field>
                              <app-library-objects-field *ngSwitchCase="'library'" class="full-width"
                                [formControl]="getFormControl(subGroup, subField.name)"
                                [config]="subField"></app-library-objects-field>
                              <app-model-field *ngSwitchCase="'model'" [config]="subField"
                                [formControl]="getFormControl(subGroup, subField.name)"></app-model-field>
                              <!-- Tipo array (lista anidada dentro de una sublista) -->
                              <ng-container *ngSwitchCase="'array'">
                                <!-- Aquí se maneja un array anidado dentro de una sublista -->
                                @if(subField.fields && subField.fields.length > 0){
                                <div class="level-1" [formArrayName]="subField.name" class="sub-sublist-container">
                                  <h5 class="sub-sublist-title">{{ subField.name | titlecase }} (Sub-sublistaa)</h5>
                                  @for(innerGroup of getFormArrayFromGroup(subGroup, subField.name).controls; track
                                  $index;
                                  ){

                                  <div class="level-1">
                                    <app-object-field [config]="subField"
                                      [formGroup]="getFormGroup(innerGroup)"></app-object-field>
                                    <button mat-button color="warn"
                                      (click)="removeSubItem(subGroup, subField.name, $index)">Eliminar
                                      sub-subitem</button>
                                  </div>


                                  }

                                  <button mat-buttom color="primary"
                                    (click)="addSubItem(subGroup, subField);$event.stopPropagation()">Agregar
                                    sub-subitem</button>
                                </div>
                                }

                              </ng-container>
                              <!-- Fallback para otros tipos -->
                              <app-input-field *ngSwitchDefault [type]="getFieldInputType(subField.type)"
                                [config]="subField"
                                [formControl]="getFormControl(subGroup, subField.name)"></app-input-field>

                            </ng-container>
                          </div>
                          }

                          <button mat-button color="warn" (click)="removeSubItem(group, field.name, j)">Eliminar
                            subitem</button>
                        </div>
                        }
                      </div>
                      <button mat-buttom color="primary"
                        (click)="addSubItem(group, field);$event.stopPropagation()">Agregar
                        subitem</button>
                    </div>
                    }

                  </ng-container>
                  <app-library-objects-field *ngSwitchCase="'library'" class="full-width"
                    [formControl]="getFormControl(group, field.name)" [config]="field"></app-library-objects-field>

                  <!-- Tipo custom a nivel raíz del array -->
                  <app-chip-field *ngSwitchCase="'chips'" class="full-width"
                    [formControl]="getFormControl(group, field.name)" [config]="field"></app-chip-field>

                  <app-model-field *ngSwitchCase="'model'" [config]="field"
                    [formControl]="getFormControl(group, field.name)"></app-model-field>

                  <!-- Fallback para otros casos -->
                  <app-input-field *ngSwitchDefault [type]="getFieldInputType(field.type)" [config]="field"
                    [formControl]="getFormControl(group, field.name)"></app-input-field>

                </ng-container>

                }
              </div>

            </mat-expansion-panel>

            }
          </mat-accordion>
        </div>

      </mat-expansion-panel>
      }
      <!-- Sección de tipo object -->
      @if(section.type === 'object'){
      <mat-expansion-panel class="mat-elevation-z0 panel-main-level col-12">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ section.label ? section.label : section.name | titlecase }}</mat-panel-title>

        </mat-expansion-panel-header>
        <!-- <mat-accordion class="col-12"> -->
        <div [formGroup]="getSectionFormGroup(section.name)">
          @for(field of section.fields; track $index){
          <ng-container [ngSwitch]="field.type">
            <!-- Tipo option -->
            <app-select-field *ngSwitchCase="'option'" [config]="field"
              [formControl]="getFormControl(getSectionFormGroup(section.name), field.name)"></app-select-field>
            <!-- Tipo string -->
            <app-input-field *ngSwitchCase="'string'" [type]="'string'" [config]="field"
              [formControl]="getFormControl(getSectionFormGroup(section.name), field.name)"></app-input-field>
            <!-- Tipo number -->
            <app-input-field *ngSwitchCase="'number'" [type]="'number'" [config]="field"
              [formControl]="getFormControl(getSectionFormGroup(section.name), field.name)"></app-input-field>
            <!-- Tipo boolean -->
            <app-checkbox-field *ngSwitchCase="'boolean'" [config]="field"
              [formControl]="getFormControl(getSectionFormGroup(section.name), field.name)"></app-checkbox-field>
            <!-- Tipo array (sublista) dentro de object -->
            <ng-container *ngSwitchCase="'array'">
              @if(field.fields && field.fields.length > 0){
              <div [formArrayName]="field.name">
                <mat-divider></mat-divider>
                <div class="section-header">
                  <h3>{{ field.label ? field.label : field.name | titlecase }}</h3>
                  <button mat-button color="primary"
                    (click)="addSubItem(getSectionFormGroup(section.name), field);$event.stopPropagation()">Agregar
                    elemento</button>
                </div>
                <mat-divider></mat-divider>
                <mat-accordion>

                  @for(subGroup of getFormArrayFromGroup(getSectionFormGroup(section.name), field.name).controls;
                  track
                  $index; let j = $index){

                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <div class="mat-panel-header-array-container">
                        <mat-panel-title> {{j}} </mat-panel-title>
                        <button mat-button color="warn" class="mr-25"
                          (click)="removeSubItem(getSectionFormGroup(section.name), field.name, j); $event.stopPropagation()">Eliminar</button>
                      </div>
                    </mat-expansion-panel-header>
                    <app-object-field [config]="field" [formGroup]="getFormGroup(subGroup)"></app-object-field>
                  </mat-expansion-panel>

                  }
                </mat-accordion>

              </div>
              }

            </ng-container>

            <app-library-objects-field *ngSwitchCase="'library'" class="full-width"
              [formControl]="getFormControl(getSectionFormGroup(section.name), field.name)"
              [config]="field"></app-library-objects-field>

            <!-- Tipo custom -->
            <app-chip-field *ngSwitchCase="'chips'" class="full-width"
              [formControl]="getFormControl(getSectionFormGroup(section.name), field.name)"
              [config]="field"></app-chip-field>

            <app-model-field *ngSwitchCase="'model'" [config]="field"
              [formControl]="getFormControl(getSectionFormGroup(section.name), field.name)"></app-model-field>

            <!-- Fallback -->
            <app-input-field *ngSwitchDefault [type]="getFieldInputType(field.type)" [config]="field"
              [formControl]="getFormControl(getSectionFormGroup(section.name), field.name)"></app-input-field>
          </ng-container>
          }
        </div>
        <!-- </mat-accordion> -->

        <div class="space-z2"></div>
      </mat-expansion-panel>
      }

      @if(section.type === 'boolean'){
      <div class="col-12 checkbox-field">
        <app-checkbox-field [config]="section" [formControl]="getFormControl(form, section.name)"></app-checkbox-field>
      </div>
      }
      @if(section.type === 'string'){
      <app-input-field class="col-12" [type]="'string'" [config]="section"
        [formControl]="getFormControl(form, section.name)"></app-input-field>
      }
      @if(section.type === 'textarea'){
      <app-input-field class="col-12" [type]="'textarea'" [config]="section"
        [formControl]="getFormControl(form, section.name)"></app-input-field>
      }

      @if(section.type === 'library'){
      <app-library-objects-field [config]="section"
        [formControl]="getFormControl(form, section.name)"></app-library-objects-field>
      }
      <!-- {{form.value | json}} -->

    </div>
  </mat-accordion>
</form>