import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Inject, Output, signal, ViewChild } from '@angular/core';
import { MainHeaderComponent } from '../shared/main-header/main-header.component';
import { TableModule } from 'primeng/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MissionsService } from '../../services/missions.service';
import { BehaviorSubject, delay, firstValueFrom, interval, Observable, retryWhen, skip, Subject, switchMap, takeUntil, takeWhile, tap, throwError, timer } from 'rxjs';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FileUploadModule } from 'primeng/fileupload';
import { MatListModule } from '@angular/material/list';
import { ButtonModule } from 'primeng/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';

// import * as THREE from 'three';
// import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { DialogTaskRuleParamsComponent } from '../shared/dialog-task-rule-params/dialog-task-rule-params.component';
import { TaskRule } from '../../models/task_rule';
import { TaskModel } from '../../models/task_model';
import { SearchAutocompleteComponent } from '../shared/search-autocomplete/search-autocomplete.component';
import { ModelService } from '../models/model.service';
import { Task } from '../../models/task';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { ImageRecognitionComponent } from '../shared/image-recognition/image-recognition.component';
import { SendRecognitionBottomsheetComponent } from '../shared/send-recognition-bottomsheet/send-recognition-bottomsheet.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { Rule } from '../../models/rules';
import { UsersService } from '../../users/users.service';
@Component({
  selector: 'app-missions',
  standalone: true,
  imports: [CommonModule, MainHeaderComponent, MatInputModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, TableModule, MatIconModule, MatButtonModule, MatListModule, MatExpansionModule, MatDialogModule, MatMenuModule, MatMenuTrigger, SearchAutocompleteComponent, MatSelectModule, ImageRecognitionComponent, MonacoEditorModule],
  templateUrl: './missions.component.html',
  styleUrls: ['./missions.component.css'],
  providers: [MissionsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsComponent {
  @ViewChild('image', { static: false }) image!: ElementRef<HTMLImageElement>;
  @ViewChild('svgContainer', { static: false }) svgContainer!: ElementRef<SVGSVGElement>;
  @ViewChild('container', { static: false }) container!: ElementRef<HTMLDivElement>;

  canDelete: boolean = false;
  groupedProducts: any[] = [];
  selectedProductIndex = 0;
  originalWidth!: number;
  originalHeight!: number;
  products: any[] = [];
  currentImagePath: string = '';
  assignedRules: any[] = [];

  readonly panelOpenState = signal(false);
  oculto = false;
  missionId!: number;
  missionData: any;
  tasksrules: any;
  tasksmodels!: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private missionsService: MissionsService, private modelsService: ModelService, private _bottomSheet: MatBottomSheet, private cdr: ChangeDetectorRef, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar, private userService: UsersService) {
    this.missionId = +this.route.snapshot.paramMap.get('id')!;
    if (this.missionId) {
      this.titleheader = 'Editando Misión';
      this.updateTask
      this.missionsService.getTaskById(this.missionId).subscribe(
        t => {
          this.refreshTask()
        }
      )

      // } else {
      //   this.titleheader = 'Creando Misión'
      //   console.log('Creando nueva')
    }
    const dataa = '{"category":"ORO","channel":"","city":"05001","distributor_id":"114629","distributor_name":"TEST SURTI AWS","id":"8100003153_08450_114629_102_2024-09-18_08:50:24","latitud":"4.7126005","leader":"ZAPATA CEBALLOS JAIME ANDRES","longitud":"-74.215789","neighborhood":"ROBLEDO","region":"MEDELLIN","skus":["8602","4450","4807"],"store_address":"CR 84 63 84","store_key":"8100003153-08450-114629","store_name":"MINIMERCADO LA ANTIOQUEÑITA","uid":"22222222","zone_id":"08450"}';
  }


  onInit(editor: any) {
    let line = editor.getPosition();
    console.log(line);
  }

  private default_task: Partial<Task> = {
    "task_key": undefined,
    "name": '',
    "client_config_id": 1,
    "description": '',
    "required_score": 0,
    "run_type": "",
    "task_label": '',
    tasksrules: [],
    taskmodels: []
  }

  get requiredWeight(): number {
    return this.default_task.required_score || 0
  }



  private mission_subject = new BehaviorSubject<Task | Partial<Task> | undefined>(this.default_task);
  mission$: Observable<Task | Partial<Task> | undefined> = this.mission_subject.asObservable();

  listModels$!: Observable<any>;
  titleheader: string = 'Misión'

  // local_task:Task =  {}

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;



  formConfig = [
    { controlName: 'name', label: 'Nombre misión', type: 'text', width: '100%', margin: '' },
    { controlName: 'description', label: 'Descripción', type: 'textarea', width: '100%', margin: '' },
    { controlName: 'task_label', label: 'Etiqueta', type: 'text', width: '100%', margin: '' },
    { controlName: 'task_key', label: 'Identificador de la misión', type: 'text', width: '100%', margin: '' },
    {
      controlName: 'run_type',
      label: 'Tipo de ejecución',
      type: 'select',
      width: '80%',
      margin: '',
      options: [
        { value: 'realtime', viewValue: 'Tiempo Real' }
      ]
    }, {
      controlName: 'required_score',
      label: 'Peso requerido',
      type: 'number',
      width: '18%',
      margin: '0 0 0 1%'
    }
  ];

  missionForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    task_key: ['', Validators.required],
    task_label: [''],
    run_type: ['realtime', Validators.required],
    required_score: ['', Validators.required]
  });


  // createTask(task: Partial<Task>) {
  //   const taskData = { ...task, required_score: +task.required_score!, tasksrules: [] };
  //   this.missionsService.createMission(taskData).subscribe(response => {
  //     const taskId = response.task_id;
  //     this.default_task.task_id = taskId;
  //     if (task.tasksrules?.length) {
  //       const taskRulePromises = task.tasksrules.map((taskRule: any) => {
  //         const taskRuleData: any = {
  //           taskruleparameters: taskRule.taskruleparameters,
  //           taskrulelabel: taskRule.taskrulelabel,
  //           rule_id: taskRule.rule_id,
  //           task_id: taskId
  //         };
  //         return firstValueFrom(
  //           this.missionsService.createTaskRule(taskRuleData)
  //         );
  //       });
  //       Promise.allSettled(taskRulePromises).then(results => {
  //         const failedTasks = results.filter(result => result.status === 'rejected');
  //         if (failedTasks.length > 0) {
  //           console.error(`${failedTasks.length} reglas fallaron al ser creadas.`);
  //         } else {
  //           this.refreshTask();
  //           this._snackBar.open(`Tarea creada.`, 'ok');
  //           this.router.navigate(['/missions', response]);

  //         }
  //       });
  //     } else {
  //       this._snackBar.open(`Tarea creada.`, 'ok');
  //       this.router.navigate(['/missions', response]);
  //     }
  //   }, error => {
  //     console.error('Error al crear la tarea:', error);
  //   });
  // }



  saveTask() {
    console.log('save ')

    if (this.missionId) {
      // this.getTask()
      this.updateTask(this.default_task)
    } else {
      // this.createTask(this.default_task)
    }
    this.for_save = false
  }

  updateTask(task: Partial<Task>) {
    const { task_key, name, client_config_id, description, run_type, task_label, task_id } = task;
    const required_score = +task.required_score!;
    this.missionsService.updateTask(<number>task_id, { task_key, name, client_config_id, description, required_score, run_type, task_label }).subscribe(() => {
      this._snackBar.open(`Tarea actualizada.`, 'ok');
    })
  }

  // ruleForm!: FormGroup;
  @Output() ruleAdded = new EventEmitter<any>();
  creating?: boolean = true
  for_save: boolean = false

  ngOnInit(): void {
    this.userService.loadUsersStore();
    this.checkUserRoles()

    this.missionForm.valueChanges.subscribe(v => {
      this.for_save = true
      this.default_task = { ...this.default_task, ...v }
      this.mission_subject.next(this.default_task)
      console.log('default_task', this.default_task)
    })
    this.listModels$ = this.modelsService.getModels().pipe()
  }


  get totalWeight(): number {
    if (!this.default_task || !this.default_task.tasksrules || !Array.isArray(this.default_task.tasksrules)) {
      console.warn("tasksrules está vacío o no se ha cargado aún.");
      return 0;
    }

    return this.default_task.tasksrules.reduce((sum, rule) => {
      // Validar que `taskruleweights` existe antes de acceder a `weight`
      const weight = rule?.taskruleweights?.weight ?? 0;
      return sum + weight;
    }, 0);
  }


  onItemSelected(item: any): void {
    this.addTaskModel(item)
  }

  addTaskModel(item: any) {
    if (this.missionId) {
      this.createTaskModel({ ...item, task_id: this.missionId })
    } else {
      console.log('es creacion de tarea')
      this.default_task.taskmodels?.push({ model_id: item.model_id, model: item })
      console.log('es creacion de tareas', this.default_task)
      this.mission_subject.next(this.default_task)
    }

    this.refreshTask()
  }

  refreshTask() {
    this.missionsService.getTaskById(this.missionId).subscribe(mission => {
      this.default_task = mission
      this.mission_subject.next(this.default_task)

      if (mission) {
        this.creating = false
        this.missionForm.patchValue({
          name: mission.name,
          description: mission.description,
          task_label: mission.task_label,
          task_key: mission.task_key,
          required_score: mission.required_score
        });
        this.assignedRules = mission.taskrule || [];
        this.missionData = mission
      }
    })
  }
  // Método que se llamará cuando los datos de la tabla cambien
  onTableChange(event: any): void {
    // this.updateTotalWeight();º
  }

  onClone() {
    console.log("ID", this.missionId)
    this.missionsService.cloneTask(this.missionId).subscribe({
      next: (response) => {
        const confirmEdit = confirm(
          '¡Duplicación con éxito!! ¿Quiere ser direccionado al nuevo elemento?'
        )

        if (confirmEdit) {
          this.missionId = response.task_id
          this.router.navigate(['missions', response.task_id]);
          this.refreshTask()
        }
        else {
          this._snackBar.open(`Tarea ${this.missionId} clonada exitosamente`, 'ok', { duration: 3000 })
        }
      },
      error: (err) => {
        console.error('Error al clonar la tarea', err)
      },
    })
  }

  checkUserRoles(): void {
    this.userService.getCurrentUserUid$().subscribe((uid) => {
      if (uid) {
        this.userService.users$.subscribe((users) => {
          const currentUser = users.find((user) => user.uid === uid);
          if (currentUser) {
            this.canDelete = currentUser.roles?.admin || currentUser.roles?.superadmin || false;
            console.log('Roles cargados:', currentUser.roles, 'Can Delete:', this.canDelete);
          } else {
            console.warn('Usuario no encontrado en la lista de usuarios.');
            this.canDelete = false;
          }
        });
      } else {
        console.error('No se pudo obtener el UID del usuario autenticado.');
        this.canDelete = false;
      }
    });
  }

  deleteTask(missionId: number) {
    if (!this.canDelete) {
      this._snackBar.open('No tienes permisos para eliminar esta tarea', 'Cerrar ', { duration: 3000 });
      return;
    }
    if (window.confirm("Esto eliminará esta tarea y todas sus configuraciones"))
      this.missionsService.deleteTask(missionId).subscribe((response) => {
        this._snackBar.open(`Tarea ${missionId} eliminada.`, 'ok');
        this.router.navigate(['/missions', response])
      })
  }

  async openDialogTaskRuleprams(data: any) {
    const dialogRef = this.dialog.open(DialogTaskRuleParamsComponent, {
      data: { data, task_id: this.missionId, asignedRules: this.assignedRules, task_models: this.default_task.taskmodels },
      restoreFocus: false,
      width: '90vw',
      height: '90vh',
      // maxHeight: '80vh',
      panelClass: 'dialog-container-taskrulesp'
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log(`Dialog result:`, result);
      if (result) {
        if (result.type === 'nuevo') {
          if (this.missionId) {
            let send: any = {
              rule_id: result.data.rule_id,
              task_id: result.data.task_id,
              taskruleweights: result.data.taskruleweights,
              taskrulelabel: result.data.taskrulelabel,
              taskruleparameters: result.data.taskruleparameters
            }
            this.createTaskRule(send);
            console.log('data dialog p', send)
          } else {
            this.default_task.tasksrules?.push(result.data);
            this.mission_subject.next(this.default_task);
          }
        } else {
          const task_rule_id = data.task_rule_id;
          this.updateTaskRule(task_rule_id, result.data);
        }
      }

    });
  }


  async createTaskRule(task_rule: any) {
    console.log('Datos para la creación', task_rule)
    this.missionsService.createTaskRule(task_rule).subscribe((result) => {
      console.log('Datos para la creación', result)
      this._snackBar.open(`Regla ${task_rule.rule_id} asignada.`, 'ok');
      this.refreshTask()
    });
  }

  async updateTaskRule(task_rule_id: number, task_rule: TaskRule) {

    console.log('Objeto recibido en updateTaskRule:', task_rule);
    console.log('rule id', task_rule.rule_id)
    this.missionsService.updateTaskRule(task_rule_id, task_rule).subscribe((result) => {
      this._snackBar.open(`Regla ${task_rule.rule_id} Editada.`, 'ok');
      this.refreshTask()
    });
  }


  async deleteTaskRule(task_rule: TaskRule): Promise<void> {
    if (window.confirm("Esto eliminara la regla y todas sus configuraciones para esta tarea?")) {
      this.missionsService.deleteTaskRule(task_rule.task_rule_id).subscribe((result) => {
        this._snackBar.open(`Regla ${task_rule.rule_id} eliminada.`, 'ok');
        this.refreshTask()
      });
    }
  }

  async createTaskModel(task_model: TaskModel) {
    this.missionsService.createTaskModel(task_model).subscribe((result) => {
      this._snackBar.open(`Modelo ${task_model.model_id} asignado.`, 'ok');
      this.refreshTask()
    });
  }

  async deleteTaskModel(task_model: TaskModel): Promise<void> {
    if (window.confirm("Esto eliminara la regla y todas sus configuraciones para esta tarea?")) {
      this.missionsService.deleteTaskModel(task_model.task_id, task_model.model_id).subscribe((result) => {
        this._snackBar.open(`Modelo ${task_model.model_id} eliminado.`, 'ok');
        this.refreshTask()
      });
    }
  }




  resulRecon: any;
  statusRecon$!: Observable<any>

  private recognitionDetails = new BehaviorSubject<any[]>([]);
  recognitionDetails$ = this.recognitionDetails.asObservable();



  openBottomSheet(): void {
    const bottomSheetRef = this._bottomSheet.open(SendRecognitionBottomsheetComponent, {
      data: this.missionData
    });

    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result) {
        console.log('result _ _ _ ____', result)
        this.recognitionDetails.next(result);
        this.pollRecognitionDetails(result.id);

      }
    });
  }

  updateFinalResult(newData: any[]) {
    console.log('newData', newData)
    const currentData = this.recognitionDetails.value as any

    // Verificar si currentData y currentData.status.response existen
    if (currentData && currentData.status && currentData.status.response) {
      const updatedResponse = currentData.status.response.map((item: any) => {
        // Buscar los nuevos datos correspondientes por image_id
        const newDataItem = newData.find(newItem => newItem.image_id === item.image_id);
        if (newDataItem) {
          // Agregar los nuevos datos al item existente
          return { ...item, results: newDataItem };
        }
        return item; // Devolver el item sin cambios si no se encontró nada nuevo
      });

      // Crear un nuevo objeto de datos actualizado para mantener la estructura intacta
      const updatedData = {
        ...currentData,
        status: {
          ...currentData.status,
          response: updatedResponse
        }
      };

      // Actualizar el BehaviorSubject con los nuevos datos
      this.recognitionDetails.next(updatedData);
    } else {
      console.error('Current data structure is not as expected.');
    }
  }


  // recognitionDetails$ = new Subject<any[]>();
  private stopPolling$ = new Subject<void>();
  pollRecognitionDetails(id: string) {
    const maxDuration = 1 * 60 * 1000; // 1 minuto en milisegundos
    const stopPolling = timer(maxDuration);
    interval(1000).pipe(
      switchMap(() => this.missionsService.getRecognitionDetails(id)),
      tap(details => {
        if (this.validateRecognitionData(details)) {
          this.updateFinalResult(details.results)
          console.log('Recognition Results:', details.results);
          this.stopPolling$.next();

        } else {
          console.error('Invalid recognition details received:', details);
        }
      }),
      takeUntil(this.stopPolling$), // Detener cuando se emite desde stopPolling$
      takeUntil(stopPolling.pipe(tap(() => console.log('Stopped polling after maximum duration'))))
    ).subscribe();
  }

  validateRecognitionData(data: any): boolean {
    if (!data || !data.results || data.results.length === 0) {
      return false;
    }
    const firstResult = data.results[0];
    if (!firstResult.image_url || !firstResult.inferences || firstResult.inferences.length === 0) {
      return false;
    }
    for (const inference of firstResult.inferences) {
      if (inference.model_id !== null && inference.name !== null) {
        return true;
      }
    }
    return false;
  }


  transformApiResponse(data: any): any[] {
    let products: any[] = [];
    console.log('data__', data);
    data.results.forEach((item: any) => {
      item.inferences.forEach((inference: any) => {
        products.push({
          recognition_id: item.recognition_id,
          task_id: item.task_id,
          image_id: item.image_id,
          image_url: item.image_url,
          name: inference.name,
          category: inference.category,
          brand: inference.brand,
          ean: inference.ean,
          model_id: inference.model_id,
          score: inference.score,
          bounding_box: {
            xmin: parseFloat(inference.bounding_box.x_min),
            ymin: parseFloat(inference.bounding_box.y_min),
            xmax: parseFloat(inference.bounding_box.x_max),
            ymax: parseFloat(inference.bounding_box.y_max),
            width: parseFloat(inference.bounding_box.width),
            height: parseFloat(inference.bounding_box.height)
          }
        });
      });
    });

    return products;
  }


  groupProducts() {
    const productMap = new Map();
    this.products.forEach(product => {
      const productName = product.name;
      const boundingBox = product.bounding_box;
      const score = product.score; // Asegúrate de obtener el score
      if (productMap.has(productName)) {
        productMap.get(productName).boundingBoxes.push(boundingBox);
        productMap.get(productName).count++;
      } else {
        productMap.set(productName, {
          name: productName,
          score: score,
          boundingBoxes: [boundingBox],
          count: 1
        });
      }
    });
    this.groupedProducts = Array.from(productMap.values());
    console.log(this.groupedProducts)
  }

}



