import { Injectable } from '@angular/core';
import { DynamicFormField } from './model';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormHandlerService {

  constructor(private fb: FormBuilder) { }

  createFormGroupFromFields(fields: DynamicFormField[], data: any = {}): FormGroup {
    const group: { [key: string]: AbstractControl } = {};
    fields.forEach((field: DynamicFormField) => {
      if (field.type === 'object' && field.fields) {
        group[field.name] = this.createFormGroupFromFields(field.fields, data[field.name] || field.default || {});
      } else if (field.type === 'array' && field.fields) {
        const formArray = this.fb.array([]);
        const initial = data[field.name];
        if (initial && Array.isArray(initial) && initial.length > 0) {
          initial.forEach((item: any) => {
            formArray.push(this.createFormGroupFromFields(field.fields as any, item) as any);
          });
        } else {
          // Agrega al menos un grupo vacío
          formArray.push(this.createFormGroupFromFields(field.fields) as any);
        }
        group[field.name] = formArray;
      } else if (field.type === 'list') {
        const formArray_1 = this.fb.array([]);

        if (!field.field) throw new Error("type list requred field not fields");

        if (field.field.type == 'array') {
          const initial = data[field.name];
          console.log('initial data', initial)

          if (initial) {
            initial.forEach((item: any[]) => {
              const formArray_2 = this.fb.array([]);
              item.forEach((item2: any) => {
                formArray_2.push(this.createFormGroupFromFields(field.field?.fields as any, item2) as any)
              })
              formArray_1.push(formArray_2 as any)
            })
          } else {
            const formArray_2 = this.fb.array([]);
            formArray_2.push(this.createFormGroupFromFields(field.field.fields as any) as any)
            formArray_1.push(formArray_2 as any)
          }


        }

        group[field.name] = formArray_1
      } else {

        if (field.type == 'model') {
          console.log('key', field.name, field.type)
          console.log('value', data[field.name], field.default)
        }

        group[field.name] = new FormControl(
          data[field.name] !== undefined ? data[field.name] : field.default
        );
      }
    });
    return this.fb.group(group);
  }

  removeItemByIndex(parentGroup: AbstractControl, index: number) {
    const parentArray = parentGroup as FormArray
    parentArray.removeAt(index)
  }

  getFormGroup(group: AbstractControl) {
    return group as FormGroup
  }

  getFormArrayFromGroup(group: AbstractControl, fieldName: string): FormArray {
    const control = group.get(fieldName);
    if (!(control instanceof FormArray)) {
      throw new Error(`El control "${fieldName}" no es un FormArray.`);
    }
    return control;
  }

  getFormArrayInArray(parentGroup: AbstractControl) {
    if (!(parentGroup instanceof FormArray)) {
      throw new Error('El control proporcionado no es un FormArray.');
    }

    const formGroup = parentGroup.controls;
    return parentGroup as FormArray;
  }

  addFormGroupInArray(parentGroup: AbstractControl, field: any) {
    const parentArray = parentGroup as FormArray
    parentArray.push(this.createFormGroupFromFields(field.fields as any) as any)
  }
}
