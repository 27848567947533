import { Component, EventEmitter, inject, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, AbstractControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DynamicFormField } from './model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { ChipFieldComponent } from './chip-field/chip-field.component';
import { ModelFieldComponent } from './model-field/model-field.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { CheckboxFieldComponent } from './checkbox-field/checkbox-field.component';
import { ObjectFieldComponent } from './object-field/object-field.component';
import { MatDividerModule } from '@angular/material/divider';
import { LibraryObjectsFieldComponent } from './library-objects-field/library-objects-field.component';
import { FormHandlerService } from './form-handler.service';
import { ListFieldComponent } from './list-field/list-field.component';

export interface InitialFormData {
  [key: string]: any;
}

@Component({
  selector: 'app-dynamic-forms',
  standalone: true,
  imports: [CommonModule, MatDividerModule, FormsModule, ReactiveFormsModule, ChipFieldComponent, MatButtonModule, MatFormFieldModule, MatInputModule, MatIconModule, MatCheckboxModule, MatSelectModule, MatExpansionModule, MatChipsModule, ModelFieldComponent, SelectFieldComponent, InputFieldComponent, CheckboxFieldComponent, ObjectFieldComponent, LibraryObjectsFieldComponent, ListFieldComponent],
  templateUrl: './dynamic-forms.component.html',
  styleUrls: ['./dynamic-forms.component.css']
})
export class DynamicFormsComponent implements OnInit {
  @Input() DYNAMIC_FORM_CONFIG: DynamicFormField[] = [];
  @Input() DATA: InitialFormData = {};
  @Output() onValueChange = new EventEmitter<any[]>();
  form!: FormGroup;
  formConfig!: DynamicFormField[];
  initialData!: { [key: string]: any };

  private form_handle = inject(FormHandlerService);
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.formConfig = this.DYNAMIC_FORM_CONFIG;
    this.initialData = this.DATA;
    this.buildForm();
    this.onValueChange.emit(this.form.value)
  }

  buildForm(): void {
    const group: { [key: string]: any } = {};

    this.formConfig.forEach((section: DynamicFormField) => {
      const fields = section.fields
      switch (section.type) {
        case 'array':
          if (!fields) throw new Error("type array requred fields");
          const formArray = this.fb.array([]);
          if (
            this.initialData &&
            this.initialData[section.name] &&
            Array.isArray(this.initialData[section.name]) &&
            this.initialData[section.name].length
          ) {
            this.initialData[section.name].forEach((item: any) => {
              formArray.push(this.form_handle.createFormGroupFromFields(fields, item) as any);
            });
          } else {
            formArray.push(this.form_handle.createFormGroupFromFields(fields) as any);
          }
          group[section.name] = formArray;
          break;
        case 'object':
          if (!fields) throw new Error("type object requred fields");
          if (this.initialData && this.initialData[section.name]) {
            group[section.name] = this.form_handle.createFormGroupFromFields(
              fields,
              this.initialData[section.name] || {}
            );
          } else {
            group[section.name] = this.form_handle.createFormGroupFromFields(fields);
          }
          break;
        case 'string':
          if (this.initialData && this.initialData[section.name]) {
            const value = this.initialData[section.name];
            group[section.name] = new FormControl(typeof value === 'object' ? JSON.stringify(value) : value);
          } else {
            const value = this.initialData[section.name];
            group[section.name] = new FormControl(section.default ? section.default : value);
          }
          break;

        default:
          const hasInitialData = this.initialData && this.initialData[section.name];
          if (hasInitialData) {
            group[section.name] = new FormControl(this.initialData[section.name] !== undefined ? JSON.stringify(this.initialData[section.name]) : section.default);
          } else {
            if (section.type === 'boolean') {
              group[section.name] = new FormControl(section.default ?? false, []);
            } else {
              group[section.name] = new FormControl(section.default);
            }
          }
          break;
      }
    });

    this.form = this.fb.group(group);
    this.form.valueChanges.subscribe(value => {
      console.log('value', value)
      this.onValueChange.emit(value)
    });
  }

  // createFormGroupFromFields(fields: DynamicFormField[], data: any = {}): FormGroup {
  //   const group: { [key: string]: AbstractControl } = {};
  //   fields.forEach((field: DynamicFormField) => {
  //     if (field.type === 'object' && field.fields) {
  //       group[field.name] = this.createFormGroupFromFields(field.fields, data[field.name] || field.default || {});
  //     } else if (field.type === 'array' && field.fields) {
  //       const formArray = this.fb.array([]);
  //       const initial = data[field.name];
  //       if (initial && Array.isArray(initial) && initial.length > 0) {
  //         initial.forEach((item: any) => {
  //           formArray.push(this.createFormGroupFromFields(field.fields as any, item) as any);
  //         });
  //       } else {
  //         // Agrega al menos un grupo vacío
  //         formArray.push(this.createFormGroupFromFields(field.fields) as any);
  //       }
  //       group[field.name] = formArray;
  //     } else if (field.type === 'list') {
  //       const formArray_1 = this.fb.array([]);

  //       if (!field.field) throw new Error("type list requred field not fields");

  //       if (field.field.type == 'array') {
  //         const initial = data[field.name];
  //         console.log('initial data', initial)

  //         if (initial) {
  //           initial.forEach((item: any[]) => {
  //             const formArray_2 = this.fb.array([]);
  //             item.forEach((item2: any) => {
  //               formArray_2.push(this.createFormGroupFromFields(field.field?.fields as any, item2) as any)
  //             })
  //             formArray_1.push(formArray_2 as any)
  //           })
  //         } else {
  //           const formArray_2 = this.fb.array([]);
  //           formArray_2.push(this.createFormGroupFromFields(field.field.fields as any) as any)
  //           formArray_1.push(formArray_2 as any)
  //         }


  //       }

  //       group[field.name] = formArray_1
  //     } else {

  //       if (field.type == 'model') {
  //         console.log('key', field.name, field.type)
  //         console.log('value', data[field.name], field.default)
  //       }

  //       group[field.name] = new FormControl(
  //         data[field.name] !== undefined ? data[field.name] : field.default
  //       );
  //     }
  //   });
  //   return this.fb.group(group);
  // }

  getFormArray(sectionName: string): FormArray {
    return this.form.get(sectionName) as FormArray;
  }

  getFormArrayFromGroup(group: AbstractControl, fieldName: string): FormArray {
    const control = group.get(fieldName);
    if (!(control instanceof FormArray)) {
      throw new Error(`El control "${fieldName}" no es un FormArray.`);
    }
    return control;
  }

  getSectionFormGroup(sectionName: string): FormGroup {
    const group = this.form.get(sectionName);
    if (!(group instanceof FormGroup)) {
      throw new Error(`El grupo de la sección "${sectionName}" no es un FormGroup.`);
    }
    return group;
  }

  getFormGroupInArray(parentGroup: AbstractControl, fieldName: string, index: number) {
    if (!(parentGroup instanceof FormArray)) {
      throw new Error('El control proporcionado no es un FormArray.');
    }
    const group = parentGroup as FormArray;
    const formGroup = group.controls[index].get(fieldName);
    if (!formGroup) {
      throw new Error(`El formGroup "${fieldName}" no existe en el array.`);
    }
    return formGroup as FormGroup;
  }


  getFormArrayInArray(parentGroup: AbstractControl) {
    if (!(parentGroup instanceof FormArray)) {
      throw new Error('El control proporcionado no es un FormArray.');
    }

    const formGroup = parentGroup.controls;
    return parentGroup as FormArray;
  }

  getFormGroup(group: AbstractControl) {
    return group as FormGroup
  }

  addItem(section: DynamicFormField): void {
    const newGroup = this.form_handle.createFormGroupFromFields(section.fields as DynamicFormField[]);
    this.getFormArray(section.name).push(newGroup);
  }

  addArrayFormWithFormGroup(parentGroup: AbstractControl, field: any) {
    const parentArray = parentGroup as FormArray
    const formArray_2 = this.fb.array([]);
    formArray_2.push(this.form_handle.createFormGroupFromFields(field.fields as any) as any)
    parentArray.push(formArray_2 as any)
  }

  addFormGroupInArray(parentGroup: AbstractControl, field: any) {
    const parentArray = parentGroup as FormArray
    parentArray.push(this.form_handle.createFormGroupFromFields(field.fields as any) as any)
  }

  removeItemByIndex(parentGroup: AbstractControl, index: number) {
    const parentArray = parentGroup as FormArray
    parentArray.removeAt(index)
  }

  removeItem(sectionName: string, index: number): void {
    this.getFormArray(sectionName).removeAt(index);
  }

  addSubItem(parentGroup: AbstractControl, field: DynamicFormField): void {
    const subArray = parentGroup.get(field.name) as FormArray;
    subArray.push(this.form_handle.createFormGroupFromFields(field.fields!));
  }

  removeSubItem(parentGroup: AbstractControl, fieldName: string, index: number): void {
    const subArray = parentGroup.get(fieldName) as FormArray;
    subArray.removeAt(index);
  }

  getFormControl(controlGroup: AbstractControl, fieldName: string): FormControl {
    if (!(controlGroup instanceof FormGroup)) {
      throw new Error('El control proporcionado no es un FormGroup.');
    }
    const group = controlGroup as FormGroup;
    const control = group.get(fieldName);
    if (!control) {
      throw new Error(`El control "${fieldName}" no existe en el grupo.`);
    }
    return control as FormControl;
  }

  onSubmit(): void {
    console.log('Valor del formulario:', this.form.value);
  }

  getFieldInputType(fieldType: string): string {
    switch (fieldType) {
      case 'number':
        return 'number';
      case 'boolean':
        return 'checkbox';
      case 'string':
        return 'text';
      case 'array':
        return 'textarea';
      default:
        return 'text';
    }
  }
}