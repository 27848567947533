<app-main-header [name_main]="titleheader">
  @if(creating){
      <button [disabled]="for_save" mat-flat-button color="primary" [disabled]="!usersForm.valid"
          (click)="saveUsers()">Guardar</button>
      }@else {
      <button class="check-btn" mat-flat-button (click)="openBottomSheet()"></button>
      <button mat-flat-button color="primary" [disabled]="!usersForm.valid || !for_save"
          (click)="saveUsers()">Guardar</button>
      }

</app-main-header>

<hr>
<div [formGroup]="usersForm">

<div class="users-data" >
 @for (d of formConfig; track d.label; let idx = $index, e = $even) {
  
  <mat-form-field class="outline" *ngIf="d.type !=='checkbox'" [ngStyle]="{ 'width': d.width, 'margin':d.margin}">    
      <mat-label>{{ d.label }}</mat-label>

  <input *ngIf="d.type !== 'select'" matInput [formControlName]="d.controlName" [type]="d.type" >
 
  <mat-select *ngIf="d.type === 'select'" [formControlName]="d.controlName">
    <mat-option *ngFor="let client of clients" [value]="client.client_id">
      {{ client.name }} <!-- Muestra el nombre del cliente -->
    </mat-option>
  </mat-select>
</mat-form-field>
  
  <div *ngIf="usersForm.get(d.controlName)?.errors?.['minlength'] && usersForm.get(d.controlName)?.touched">
    <mat-error>
    <small > El valor ingresado debe ser superior a 2 caracteres. </small>

</mat-error>
</div>
<div *ngIf="d.controlName === 'roles'" class="checkbox-group">
    <mat-label>{{d.label}}</mat-label>
    <div class="checkbox-options">
      <mat-checkbox 
        [checked]="usersForm.get('roles.viewer')?.value" 
        (change)="usersForm.get('roles.viewer')?.setValue($event.checked)">
        Viewer
      </mat-checkbox>

      <mat-checkbox 
        [checked]="usersForm.get('roles.admin')?.value" 
        (change)="usersForm.get('roles.admin')?.setValue($event.checked)">
        Admin
      </mat-checkbox>

      <mat-checkbox 
        [checked]="usersForm.get('roles.superadmin')?.value" 
        (change)="usersForm.get('roles.superadmin')?.setValue($event.checked)">
        Super Admin
      </mat-checkbox>
  </div>
</div>


<div *ngIf="d.controlName === 'is_active'" class="checkbox-group">
<mat-label>{{d.label}}</mat-label>
<div class="checkbox-options">
  <mat-checkbox 
      [checked]="usersForm.get('is_active')?.value === true" 
      (change)="usersForm.get('is_active')?.setValue(true)">
      Sí
    </mat-checkbox>
    <mat-checkbox 
      [checked]="usersForm.get('is_active')?.value === false" 
      (change)="usersForm.get('is_active')?.setValue(false)">
      No
    </mat-checkbox>
</div>
</div>
}
