import { Component, ViewChild } from '@angular/core';
import { LibraryService } from '../../../services/library.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterLink } from '@angular/router';
import { Library } from '../../../models/library';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-list-library-objects',
  standalone: true,
  imports: [MainHeaderComponent, DataTableComponent, RouterLink, MatButtonModule, MatButton, CommonModule, MatFormFieldModule, MainHeaderComponent],

  templateUrl: './list-library-objects.component.html',
  styleUrl: './list-library-objects.component.css'
})
export class ListLibraryObjectsComponent {
  library$!: Observable<Library[]>
  libraryData: Library[] = [];
  selected!: Library[];
  products!: Library[];
  selectedProducts: Library[] = [];

  selectedItems: any[] = [];

  columns: any[] = [
    { field: 'library_object_id', header: 'Id', filter: true, orderby: true },
    { field: 'libraryBrand.name', header: 'Marca', filter: true, orderby: false },
    { field: 'libraryCategory.name', header: 'Categoria', filter: true, orderby: false },
    { field: 'libraryEan.ean', header: 'Ean', filter: true, orderby: false },
    { field: 'name', header: 'Nombre', filter: true, orderby: false },
    { field: 'label', header: 'Etiqueta', filter: true, orderby: false },
    { field: 'description', header: 'Descripción', filter: true, orderby: false},
    { field: 'thumbnail', header: 'Enlace', filter: true, orderby: false },
  ]
  @ViewChild('dt')table!: Table;
  constructor(public libraryService: LibraryService, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.libraryService.loadLibraryStore();
  }

  onSelectionChange(event: any) {
    console.log('event', event)
    this.selectedProducts = event
  }
  onEditItem(item: any) {
    console.log('onEditItem items:', item);
    this.router.navigate(['/library', item.library_object_id]);
  }

  onDeleteItems(items: any) {
    console.log('Delete items:', items);
    if (window.confirm("Esto eliminará este producto y todas sus configuraciones")) { 
    this.libraryService.deleteLibrary(items.library_object_id).subscribe(() => {
     this.selectedProducts = []
     console.log('object delete')
     this._snackBar.open(`Producto ${items.library_object_id} eliminado.`, 'ok');
     this.libraryService.loadLibraryStore()
   })
  } 
  }

  deleteLibrary( library: Library[]) {
     this._snackBar.open('Eliminando...', 'ok');
    if (window.confirm("Esto eliminará este producto y todas sus configuraciones")) {
      library.map(library => {
        this.libraryService.deleteLibrary(library.library_object_id).subscribe(() => {
          this.selectedProducts = []
          console.log('producto delete')
          this._snackBar.open(`Producto ${library.library_object_id} eliminado.`, 'ok');
          this.libraryService.loadLibraryStore()
        })
      })
      } 
    }
      onRowSelect(event: any) {
        console.log('select', this.selectedProducts)
       
    
    }
}
