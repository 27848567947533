<app-main-header [name_main]="titleheader">
  @if(creating){
      <button [disabled]="for_save" mat-flat-button color="primary" [disabled]="!modelsForm.valid"
          (click)="saveModels()">Guardar</button>
      }@else {
      <button class="check-btn" mat-flat-button (click)="openBottomSheet()">
      </button>
      <button mat-flat-button color="primary" [disabled]="!modelsForm.valid || !for_save"
          (click)="saveModels()">Guardar</button>
      }

</app-main-header>

<hr>
<form [formGroup]="modelsForm">

<div class="models-data" >
 @for (d of formConfig; track d.label; let idx = $index, e = $even) {
  <mat-form-field class="outline"  [ngStyle]="{ 'width': d.width, 'margin':d.margin}">    
      <mat-label>{{ d.label }}</mat-label>
  <input *ngIf="d.type != 'select'" matInput [formControlName]="d.controlName" [type]="d.type" >
       
  </mat-form-field>
  <div *ngIf="modelsForm.get(d.controlName)?.errors?.['minlength'] && modelsForm.get(d.controlName)?.touched">
      <mat-error>
      <small > El valor ingresado debe ser superior a 2 caracteres. </small>

  </mat-error>
  </div>
  }
  <div class="checkbox-group">
  <mat-label>¿Está activo?</mat-label>
<div class="checkbox-options">
<mat-checkbox 
[checked]="modelsForm.get('is_active')?.value === true" 
(change)="setFilterProductsValue(true)">
Si
</mat-checkbox>
<mat-checkbox 
[checked]="modelsForm.get('is_active')?.value === false" 
(change)="setFilterProductsValue(false)">
No
</mat-checkbox>
</div>
  </div>
</div>
<!-- </div> -->
</form>
<div class="mission-parameters">
  <div class="title-btn">
      <div> 