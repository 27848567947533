import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { InitialFormData } from '../dynamic-forms.component';
import { DynamicFormField } from '../model';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
// import { MatChipsModule } from '@angular/material/chips';
// import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';


import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, computed, inject, model, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-chip-field',
  standalone: true,
  templateUrl: './chip-field.component.html',
  styleUrl: './chip-field.component.css',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChipFieldComponent),
    multi: true
  }],
  imports: [MatOptionModule, MatSelectModule, CommonModule, ReactiveFormsModule, MatChipsModule, MatIconModule, FormsModule, MatInputModule, MatChipsModule, MatAutocompleteModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChipFieldComponent implements ControlValueAccessor {
  @Input() config!: DynamicFormField;
  @Input() formControl!: FormControl;

  value: any;

  writeValue(value: string): void {
    console.log('writeValueOnCHip', value)
    const initial_value = this.formControl.value
    console.log('initial_value', initial_value)
  }
  registerOnChange(fn: any): void {
    console.log('fn', fn)
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }





  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly currentElement = model('');
  readonly elements = signal([] as any[]);
  readonly allElements: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  readonly filteredElements = computed(() => {
    const currentElement = this.currentElement().toLowerCase();
    return currentElement
      ? this.allElements.filter(element => element.toLowerCase().includes(currentElement))
      : this.allElements.slice();
  });

  readonly announcer = inject(LiveAnnouncer);

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our element
    if (value) {

      this.elements.update(elements => [...elements, value]);
    }
    console.log('this.elements', this.elements())
    // Clear the input value
    this.currentElement.set('');
  }

  remove(element: string): void {
    this.elements.update(elements => {
      const index = elements.indexOf(element);
      if (index < 0) {
        return elements;
      }

      elements.splice(index, 1);
      this.announcer.announce(`Removed ${element}`);
      return [...elements];
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.elements.update(elements => [...elements, event.option.viewValue]);
    this.currentElement.set('');
    this.formControl.setValue(this.elements())
    event.option.deselect();
  }

  // refsTarget: string[] = [];

  // maxChips = 5;
  // showAll = true;
  // isWritingValue = false;

  // constructor(private cdr: ChangeDetectorRef) {
  //   console.log('into compon', this.config)
  // }

  // ngOnInit() {
  //   console.log('into compon', this.config)
  // }

  // onTouched: () => void = () => { };
  // onChange: (value: any) => void = () => { };


  // get visibleChips() {
  //   return this.showAll ? this.refsTarget : this.refsTarget.slice(0, this.maxChips);
  // }

  // toggleShowAll() {
  //   this.showAll = !this.showAll;
  // }

  // writeValue(value: any): void {
  //   console.log('writeValue triggered with value:', value);
  //   let newValue: string[] = [];

  //   if (Array.isArray(value)) {
  //     newValue = value
  //       .map(item => (typeof item === 'string' ? item.split(',') : item))
  //       .flat()
  //       .map(v => v.trim())
  //       .filter(v => v.length > 0);
  //   } else if (typeof value === 'string') {
  //     newValue = value.trim() ? value.split(',').map(v => v.trim()) : [];
  //   }

  //   // Evita la recursión innecesaria solo si el valor realmente cambió
  //   if (JSON.stringify(this.refsTarget) !== JSON.stringify(newValue)) {
  //     this.refsTarget = newValue;
  //     this.formControl.patchValue(this.refsTarget, { emitEvent: false });
  //   }
  // }

  // registerOnChange(fn: any): void {
  //   this.onChange = fn;
  //   this.formControl.valueChanges
  //     .pipe(

  //       debounceTime(200),
  //       distinctUntilChanged()
  //     )
  //     .subscribe(fn);
  // }

  // registerOnTouched(fn: any): void {
  //   this.onTouched = fn;
  // }
  // onValueChange(value: any) {
  //   this.value = value;
  //   this.onChange(value)
  // }

  // //agrega los chips 
  // add(event: any): void {
  //   console.log('add triggered with event:', event);
  //   const input = event.target as HTMLInputElement;
  //   const value = input.value.trim();
  //   input.value = '';

  //   if (value) {
  //     const values = value.split(',').map(v => v.trim()).filter(v => v);

  //     if (!Array.isArray(this.refsTarget)) {
  //       this.refsTarget = [];
  //     }
  //     this.refsTarget.unshift(...values);
  //     this.formControl.setValue(this.refsTarget);

  //     this.inputControl.updateValueAndValidity();
  //     this.cdr.detectChanges();
  //   }
  // }



  // addFromInput(): void {
  //   this.processInputValue(this.inputControl.value ?? '');
  // }

  // processInputValue(value: string): void {
  //   if (value.trim()) {
  //     const values = value.split(',').map(v => v.trim()).filter(v => v);
  //     this.refsTarget.push(...values);
  //     this.formControl.setValue(this.refsTarget);
  //     this.inputControl.setValue('');
  //   }
  // }
  // remove(ref: string): void {
  //   const index = this.refsTarget.indexOf(ref);
  //   if (index >= 0) {
  //     this.refsTarget.splice(index, 1);
  //     this.formControl.setValue(this.refsTarget);
  //   }
  // }
}
