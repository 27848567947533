import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import { defer, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-user-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.css'
})
export class UserCardComponent {
  auth = inject(Auth)
  user$!: Observable<any>;
  ngOnInit(): void {
    this.user$ = this.getUser()
  }


  getUser(): Observable<any> {
    return defer(() => {
      const currentUser = this.auth.currentUser;
      if (currentUser) {
        return of(currentUser);
      } else {
        return of(null);
      }
    }).pipe(
      switchMap((user: any) => {
        if (user) {
          return of(user)
        } else {
          return of(null);
        }
      })
    );
  }

}
