<mat-dialog-content class="mat-typography">
  
        @if(rules$ | async; as rules){
        @if(!disableInput){
        <app-search-autocomplete [disabledBool]="disableInput" [title]="'Busca una regla para continuar'"
            [items]="rules" (itemSelected)="onItemSelected($event)">
        </app-search-autocomplete>
        <br>
        }

        <form [formGroup]="taskRuleForm" (ngSubmit)="saveRuleTaskParms()">





            @if($FORM_CONFIG | async; as form_config){
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Label de regla</mat-label>
                <input matInput formControlName="taskrulelabel">
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Peso de regla</mat-label>
                <input matInput formControlName="taskruleweights">
            </mat-form-field>
            @if(form_config){

            <h4>Parámetros</h4>
            <app-dynamic-forms [DYNAMIC_FORM_CONFIG]="form_config" (onValueChange)="onValueChange($event)"
                [DATA]="this.task_rule_parameters_data"></app-dynamic-forms>
            }

            }
        </form>
        }

    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button  mat-raised-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-raised-button (click)="saveRuleTaskParms()" cdkFocusInitial [disabled]="!taskRuleForm.valid">Guardar</button>
</mat-dialog-actions>