import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { Table, TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PrimeNGConfig } from 'primeng/api';
import { ReactiveFormsModule } from '@angular/forms';


@Component({
  standalone: true,
  selector: 'app-data-table',
  imports: [
    CommonModule,
    TableModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    RouterLink,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent {
  @Input() data: any[] = [];
  @Input() columns: any[] = [];
  @Input() rows: number = 10;
  @Input() rowsPerPageOptions: number[] = [10, 25, 50];
  @Input() selection: any[] = [];
  @Input() sortField: string = '';
  @Input() sortOrder: number = -1;
  @Input() showFilters: boolean = true;
  
  @Output() selectionChange = new EventEmitter<any[]>();
  @Output() rowSelect = new EventEmitter<any>();
  @Output() deleteItems = new EventEmitter<any[]>();
  @Output() editItem = new EventEmitter<any>();
  @Output() exportItem = new EventEmitter<any>();
  constructor(private primengConfig: PrimeNGConfig) {
    this.primengConfig.setTranslation({
      contains: 'Contiene',
      startsWith: 'Empieza con',
      notContains: 'No contiene',
      endsWith: 'Termina con',
      equals: 'Igual a',
      notEquals: 'No igual a',
      noFilter: 'Sin filtro',
      lt: 'Menor que',
      lte: 'Menor o igual que',
      gt: 'Mayor que',
      gte: 'Mayor o igual que',
      is: 'Es',
      isNot: 'No es',
      before: 'Antes',
      after: 'Después',
      dateIs: 'La fecha es',
      dateIsNot: 'La fecha no es',
      dateBefore: 'La fecha es antes',
      dateAfter: 'La fecha es después',
      clear: 'Limpiar',
      apply: 'Aplicar',
      matchAll: 'Coincidir con todo',
      matchAny: 'Coincidir con cualquiera',
      addRule: 'Agregar regla',
      removeRule: 'Eliminar regla',
      accept: 'Sí',
      reject: 'No',
      choose: 'Elegir',
      upload: 'Subir',
      cancel: 'Cancelar',
      dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      weekHeader: 'Sem',
      firstDayOfWeek: 1,
      dateFormat: 'dd/mm/yy',
      weak: 'Débil',
      medium: 'Medio',
      strong: 'Fuerte',
      passwordPrompt: 'Ingresa una contraseña',
      emptyMessage: 'No se encontraron resultados',
      emptyFilterMessage: 'No se encontraron resultados'
    });
  }
  getNestedValue(obj: any, path: string): any {
    //if (path !== 'roles')
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  } 
  
  onRowSelect(event: any) {
    this.rowSelect.emit(event);
  }

  onSelectionChange(event: any) {
    this.selectionChange.emit(event);
  }

  onDeleteItems(items: any[]) {
    this.deleteItems.emit(items);
  }

  onEditItem(item: any) {
    this.editItem.emit(item);
  }
  onExport(item: any) {
    this.exportItem.emit(item)
  }
}
