<app-main-header [name_main]="titleheader">
  @if(creating){
  <button
    [disabled]="for_save"
    mat-flat-button
    color="primary"
    [disabled]="!boardForm.valid"
    (click)="saveBoard()"
  >
    Guardar
  </button>
  }@else {
  <button
    class="check-btn"
    mat-flat-button
    (click)="openBottomSheet()"
  ></button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!boardForm.valid || !for_save"
    (click)="saveBoard()"
  >
    Guardar
  </button>
  }
</app-main-header>

<hr />
<div [formGroup]="boardForm">
  <div class="board-data">
    @for (d of formConfig; track d.label; let idx = $index, e = $even) {

    <mat-form-field
      class="outline"
      *ngIf="d.controlName !== 'roles'"
      [ngStyle]="{ width: d.width, margin: d.margin }"
    >
      <mat-label>{{ d.label }}</mat-label>

      <input
        *ngIf="d.type !== 'select'"
        matInput
        [formControlName]="d.controlName"
        [type]="d.type"
      />

      <mat-select *ngIf="d.type === 'select'" [formControlName]="d.controlName">
        <mat-option *ngFor="let client of clients" [value]="client.client_id">
          {{ client.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Roles Section -->
    <mat-form-field
      class="outline"
      *ngIf="d.controlName === 'roles'"
      [ngStyle]="{ width: d.width, margin: d.margin }"
    >
      <div class="search-container">
        <input
          matInput
          placeholder="Buscar correos"
          [matAutocomplete]="auto"
          [formControl]="roleSearchCtrl"
        />
        <mat-icon>search</mat-icon>
      </div>

      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="addRole($event.option.value)"
      >
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user.email">
          {{ user.email }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!-- Chips Section -->
    <mat-chip-list #chipList class="chip-list" *ngIf="d.controlName === 'roles'">
      <mat-chip
        *ngFor="let role of rolesArray.controls"
        [selectable]="true"
        [removable]="true"
        class="chip-item"
      >
        <span>{{ role.value.email }}</span> 
        
        <mat-icon matChipRemove  class="chip-remove" (click)="removeRole(role.value.email)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    
    <div
      *ngIf="boardForm.get(d.controlName)?.errors?.['minlength'] && boardForm.get(d.controlName)?.touched"
    >
      <mat-error>
        <small>El valor ingresado debe ser superior a 2 caracteres.</small>
      </mat-error>
    </div>
    
     } <!-- Cierra el bucle @for -->
  </div>

<!-- Sección de consultas -->
<div formArrayName="queries" class="queries-container">
  <button mat-raised-button color="primary" (click)="addQuery()">Agregar descargable</button>

  <div
    *ngFor="let query of queriesArray.controls; let i = index"
    [formGroupName]="i"
    class="query-group"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
  >
    <mat-form-field appearance="outline">
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Host</mat-label>
      <input matInput formControlName="host" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Rango de fechas</mat-label>
      <mat-date-range-input [rangePicker]="picker" formGroupName="params">
        <input matStartDate formControlName="start_date" placeholder="Fecha de inicio" />
        <input matEndDate formControlName="end_date" placeholder="Fecha de fin" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <button mat-icon-button color="warn" (click)="removeQuery(i)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
