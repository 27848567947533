// src/app/services/image-annotator.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageAnnotatorService {

  // Genera un color RGB a partir del nombre del objeto
  generateColorFromName(name: string, is_other:boolean): {r: number, g: number, b: number} {

    if (is_other) {
      return { r: 255, g: 0, b: 0 };  
    }
    
    const hash = this.hashString(name);
    const hue = (hash % 360) / 360.0;
    const saturation = 0.7 + ((hash / 360) % 30) / 100.0;
    const lightness = 0.6 + ((hash / 36000) % 20) / 100.0;
  
    // Convertimos HSL a RGB
    const [r, g, b] = this.hslToRgb(hue, saturation, lightness);
  
    // Devolvemos los valores RGB como números
    return { r: Math.round(r), g: Math.round(g), b: Math.round(b) };
  }

  private hashString(str: string): number {
    let hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;  // Convertir a entero de 32 bits
    }
    return hash;
  }

  // Conversión de HSL a RGB
  private hslToRgb(h: number, s: number, l: number): [number, number, number] {
    let r: number, g: number, b: number;

    if (s === 0) {
      r = g = b = l;  // Si no hay saturación, es un color gris
    } else {
      const hue2rgb = (p: number, q: number, t: number): number => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255];
  }
}