import { inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = async (route, state) => {
  const angularFireAuth = inject(Auth);
  const router = inject(Router);

  try {
    const user = await angularFireAuth.currentUser;
    const isLoggedIn = !!user;
    if (!isLoggedIn) {
      
      router.navigate(['/auth'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    // console.log('isLoggedIn', isLoggedIn)
    return isLoggedIn;

  } catch (error) {
    console.log('error guard,', error)
    router.navigate(['/auth'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
};
