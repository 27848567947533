<!-- <mat-form-field appearance="outline" class="full-width">
    <mat-label>{{config.name}}</mat-label>
    <input matInput [formControl]="inputControl" (keyup.enter)="add($event)" />
</mat-form-field>

<p>{{config | json}}</p>

<div class="chip-container">
    <mat-chip-list #chipList>
        <mat-chip *ngFor="let ref of visibleChips; let i = index" [selectable]="true" [removable]="true"
            (removed)="remove(ref)">
            {{ ref }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>
    <button *ngIf="refsTarget.length > maxChips" type="button" class="btn-ver-mas" (click)="toggleShowAll()">
        {{ showAll ? 'Ver menos' : 'Ver más' }}
    </button>
</div>
 -->
<mat-form-field class="full-width">
    <mat-label>{{config.label ? config.label : config.name}}</mat-label>
    <mat-chip-grid #chipGrid aria-label="item selection">
        @for (element of elements(); track $index) {
        <mat-chip-row (removed)="remove(element)">
            {{element}}
            <button matChipRemove [attr.aria-label]="'remove ' + element">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
        }
    </mat-chip-grid>
    <input name="currentElement" [placeholder]="config.placeholder ? config.placeholder : 'Agregar nuevo'" #elementInput
        [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"  />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event); elementInput.value = ''">
        @for (element of filteredElements(); track element) {
        <mat-option [value]="element">{{element}}</mat-option>
        }
    </mat-autocomplete>
</mat-form-field>