import { Component, inject, ViewChild } from '@angular/core';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { Table, TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MissionsService } from '../../../services/missions.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { Task } from '../../../models/task';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { MatDialog } from '@angular/material/dialog';
import { EditorJsonComponent } from '../../shared/editor-json/editor-json.component';


@Component({
  selector: 'app-list',
  standalone: true,
  imports: [CommonModule, MainHeaderComponent, TableModule, MatIconModule, MatButtonModule, MatMenuModule, RouterLink, MatFormFieldModule, DataTableComponent],
  templateUrl: './list.component.html',
  styleUrl: './list.component.css'
})
export class ListComponent {
  tasks$!: Observable<Task[]>
  selected!: Task[];
  products!: Task[];
  selectedProducts: Task[] = [];

  selectedItems: any[] = [];


  readonly dialog = inject(MatDialog);

  columns: any[] = [
    { field: 'task_id', header: 'Id', filter: true, orderby: true },
    { field: 'name', header: 'Nombre', filter: true, orderby: false },
    { field: 'task_key', header: 'Key', filter: true, orderby: false },
    { field: 'required_score', header: 'Requerido', filter: true, orderby: false }
  ];

  @ViewChild('dt') table!: Table;
  constructor(public missionsService: MissionsService, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.missionsService.loadTaskStore()
    console.log("tabla____", this.table)
  }
  onSelectionChange(event: any) {
    console.log('event', event)
    // this.selectedItems = event;
  }
  onEditItem(item: any) {
    console.log('onEditItem items:', item);
    this.router.navigate(['/missions', item.task_id]);
  }
  onExport(item: any) {
    console.log('onEditItem items:', item);
    this.openDialog(item.task_id)

    // this.router.navigate(['/missions', item.task_id]);
  }
  onDeleteItems(items: Task) {
    console.log('Delete items:', items);
    this.selectedProducts.push(items)
  }

  deleteTask(task: Task[]) {
    this._snackBar.open('Eliminando...', 'ok');
    if (window.confirm("Esto eliminará esta tarea y todas sus configuraciones")) {
      task.map((task: any) => {
        this.missionsService.deleteTask(task.data).subscribe(() => {
          this.selectedProducts = []
          console.log('task delete')
          this._snackBar.open(`Tarea ${task.data.task_id} eliminada.`, 'ok');
          this.missionsService.loadTaskStore()
        })
      })

    }

  }

  onRowSelect(event: any) {
    this.selectedProducts.push(event)
    console.log('onRowSelect _ _', this.selectedProducts)
  }

  handleFilter(event: Event) {
    console.log("evento", event)
    const inputElement = event.target as HTMLInputElement;
    this.table.filterGlobal(inputElement.value, 'contains');
  }
  openDialog(id:any): void {
    const dialogRef = this.dialog.open(EditorJsonComponent, {
      data: { task_id: id },
    });
    console.log('The dialog was open');
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result !== undefined) {
        // this.animal.set(result);
      }
    });
  }
}
