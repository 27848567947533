import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Router, RouterLink } from '@angular/router';
import { DashboardService } from '../board.service';
import { map, Observable, tap } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { UserCardComponent } from '../../shared/user-card/user-card.component';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-board-list',
  standalone: true,
  imports: [CommonModule, RouterLink, MatButtonModule, MainHeaderComponent, UserCardComponent],
  templateUrl: './board-list.component.html',
  styleUrl: './board-list.component.css'
})
export class BoardListComponent {
  firestore: Firestore = inject(Firestore);
  dashboardService = inject(DashboardService)
  router = inject(Router)
  dashBoards$!: Observable<any[]>;
  auth = inject(AuthService)
  // 
  transparency: number = 0.8;
  baseColors: string[] = [
    '#4B0082', // Indigo
    '#483D8B', // Dark Slate Blue
    '#6A5ACD', // Slate Blue
    '#7B68EE', // Medium Slate Blue
    '#9370DB', // Medium Purple
    '#8A2BE2', // Blue Violet
    '#9400D3', // Dark Violet
    '#9932CC', // Dark Orchid
    '#BA55D3', // Medium Orchid
    '#8B008B', // Dark Magenta
    '#800080', // Purple
    '#4B0082', // Indigo
    '#6A5ACD', // Slate Blue
    '#7B68EE', // Medium Slate Blue
    '#8A2BE2', // Blue Violet
    '#9400D3', // Dark Violet
    '#9932CC', // Dark Orchid
    '#BA55D3', // Medium Orchid
    '#8B008B', // Dark Magenta
    '#800080', // Purple
    '#483D8B', // Dark Slate Blue
    '#6A5ACD', // Slate Blue
    '#7B68EE', // Medium Slate Blue
    '#9370DB'  // Medium Purple
  ];
  colors: string[] = [];
  constructor() { }
  user$!: Observable<any>;
  ngOnInit(): void {
    this.user$ = this.auth.userData$.pipe(
      // tap(d => {
      //   console.log('Datos del usuario:', d);
      // })
    );

    this.dashBoards$ = this.dashboardService.getDashboards().pipe(
      tap(d => console.log('d', d)),
      map((dashboards: any[]) => dashboards.map(dashboard => ({ name: dashboard.name, urlBi: dashboard.urlBi, id: dashboard.id, client_id: dashboard.client_id, queries: dashboard.queries })))
    );
    this.updateColors();
  }
  godash(dashboard: any) {
    // console.log('dashboard', dashboard)
    this.router.navigated
    this.dashboardService.setSelectedDashboardUrl(dashboard);
    this.router.navigate(['/boards', dashboard.id]);
  }

  updateColors(): void {
    this.colors = this.baseColors.map(color => this.addTransparency(color, this.transparency));
  }

  addTransparency(color: string, transparency: number): string {
    const [r, g, b] = this.hexToRgb(color);
    return `rgba(${r}, ${g}, ${b}, ${transparency})`;
  }

  hexToRgb(hex: string): [number, number, number] {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  }

  getBackgroundColor(index: number): string {
    return this.colors[index % this.colors.length];
  }
}
