<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="dialog-json">
    <div style="height: 500px; min-width: 500px">
        <ngx-monaco-editor style="height: 100%;" [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="copyToClipboard()" mat-flat-button *ngIf="!import">COPIAR</button>
    <button (click)="importTask()" mat-flat-button *ngIf="import" [disabled]="importbtn">IMPORTAR</button>
    <!-- <button (click)="formatJson()" color="warn" mat-flat-button>Cerrar</button> -->
</mat-dialog-actions>