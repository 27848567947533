import { Component } from '@angular/core';

@Component({
  selector: 'app-task-play-ground',
  standalone: true,
  imports: [],
  templateUrl: './task-play-ground.component.html',
  styleUrl: './task-play-ground.component.css'
})
export class TaskPlayGroundComponent {

}
