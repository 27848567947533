import { computed, Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { Task } from '../models/task';
import { Models } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {
  private baseUrl: string = `${environment['api_backend']}/api`
    private apiUrlRecon = `${environment['api_recognition']}/v1/recognition`
    private apiUrlDetails = `${environment['api_recognition']}/v2/recognition/details`
    // TOKEN PARA API RECOGNITION
    private headers = new HttpHeaders({
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfdWlkIjoiY2IxNDk2Y2QtOTJhZi00YjE0LWFiZWItMjMwZWUzZGU5ZjA4IiwiY2xpZW50X2lkIjo0LCJpYXQiOjE3MjI0ODIzOTIsImV4cCI6MTcyMjQ5MzE5Mn0.6VEgFrRSx9uHEGWpAMhJViRzxwnOc7EyamkgrhnEojw`,
        'Accept': 'application/json'
    });
    private ModelsSignal = signal<Models[]>([]);
    models = computed(() => this.ModelsSignal()); 

    private handleError(error: any): Observable<never> {
      console.error('An error occurred:', error);
      return throwError(error);
    }

    constructor(private http: HttpClient) {};

    updateModels( models: Partial<Models>, modelsId:number):Observable<any> {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.patch<Models>(`${this.baseUrl}/models/${modelsId}`, models, { headers })
          .pipe(
              catchError(this.handleError)
          );
    }
     getModelsById(model_id: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/models/${model_id}`); 
    }
    createModels(models: any) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post<any>(`${this.baseUrl}/models`, models, { headers })
          .pipe(
              catchError(this.handleError)
          )
    };

      loadModelsStore(): void {
        let params = new HttpParams().set('limit', '200');
        this.http.get<Models[]>(`${this.baseUrl}/models`, { params }).subscribe((models: Models[]) => {
            this.ModelsSignal.set(models);
        })
    }
  
      deleteModels(model_id:number): Observable<Models> {
      return this.http.delete<Models>(`${this.baseUrl}/models/${model_id}`)
    } 
}