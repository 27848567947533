import { ChangeDetectorRef, Component, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, MinLengthValidator, MinValidator, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ModelService } from '../models/model.service';
import { MissionsService } from '../../services/missions.service';
import { Task } from '../../models/task';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatLabel } from '@angular/material/form-field';
import { version } from 'uuid';
import { ImageRecognitionComponent } from "../shared/image-recognition/image-recognition.component";
import { BehaviorSubject, Observable, skip, Subject } from 'rxjs';
import { MainHeaderComponent } from "../shared/main-header/main-header.component";
import { MatIcon } from '@angular/material/icon';
import { MissionsComponent } from '../missions/missions.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SendRecognitionBottomsheetComponent } from '../shared/send-recognition-bottomsheet/send-recognition-bottomsheet.component';
import { Models } from '../../models/models';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { ModelsService } from '../../services/models.service';


@Component({
  selector: 'app-models',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, CommonModule, MatOption, MatLabel, ImageRecognitionComponent, MainHeaderComponent, MatIcon, MissionsComponent, MatInputModule, MatButtonModule, MatRadioButton, MatCheckbox, MatRadioModule],
  templateUrl: './models.component.html',
  styleUrl: './models.component.css'
})
export class ModelsComponent {

  readonly panelOpenState = signal(false);
  oculto = false;
  modelsId!: number;
  modelssData: any;


  private default_models: Partial<Models> = {
    "description": " ",
    "name": " ",
    "version": " ",
    "server": " ",
    "server_path": " ",
    "is_active": true,
  }
  private models_subject = new BehaviorSubject<Models | Partial<Models> | undefined>(this.default_models);
  models$: Observable<Models | Partial<Models> | undefined> = this.models_subject.asObservable();

  titleheader: string = 'Ingresa el modelo'

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private modelsService: ModelsService, private router: Router, private _snackBar: MatSnackBar, private _bottomSheet: MatBottomSheet,) {

  }
  formConfig = [
    { controlName: 'name', label: 'Nombre del modelo', type: 'text', width: '100%', margin: '' },
    { controlName: 'description', label: 'Descripción', type: 'textarea', width: '100%', margin: '' },
    { controlName: 'server', label: 'Servidor', type: 'text', width: '100%', margin: '' },
    { controlName: 'server_path', label: 'Ruta del servidor', type: 'text', width: '100%', margin: '' },
    { controlName: 'version', label: 'Versión', type: 'text', width: '100%', margin: '' },
    { controlName: 'model_key', label: 'Llave', type: 'text', width: '100%', margin: '' },

  ];

  modelsForm: FormGroup = this.fb.group({
    description: ['', [Validators.required, Validators.minLength(3)]],
    name: ['', [Validators.required, Validators.minLength(3)]],
    version: ['', [Validators.required]],
    server: ['', [Validators.required, Validators.minLength(3)]],
    server_path: ['', [Validators.required, Validators.minLength(3)]],
    model_key: ['', [Validators.required]],
    is_active: [null, [Validators.required]]

  });


  ngOnInit(): void {
    this.modelsForm.valueChanges.subscribe(v => {
      this.for_save = true
      this.default_models = { ...v, }
      this.models_subject.next(this.default_models)
    })
    this.modelsId = +this.route.snapshot.paramMap.get('id')!;
    if (this.modelsId) {
      /* this.getTask() */
      this.refreshModels()
    } else {
      this.titleheader = 'Creando Modelo'
      console.log('Creando nueva')
    }

  }
  createModelss(models: Partial<Models>) {
    const modelsData = { ...models };
    this.modelsService.createModels(modelsData).subscribe(
      (response: any) => {
        this.router.navigate(['/models', response]);
      }
    );
  }

  saveModels() {
    if (this.modelsForm.valid) {
      const modelsData = {
        ...this.modelsForm.value,
      };

      this.default_models = modelsData;

      this.modelsId ? this.updateModelss(this.default_models) : this.createModelss(this.default_models);

      this.for_save = false;
    }
  }

  updateModelss(models: Partial<Models>) {
    this.modelsService.updateModels(models, this.modelsId).subscribe((response: any) => {

      this.router.navigate(['/models', response]);
      this._snackBar.open(`Modelo Actualizado.`, 'ok');
    },
      (error: any) => {

        this._snackBar.open('Error al actualizar el modelo', 'Cerrar', { duration: 3000 });
        console.error('Error al actualizar el modelo', error);
      }
    )
  }


  creating?: boolean = true
  for_save: boolean = false



  recognitionDetails$ = new Subject<any[]>();
  openBottomSheet(): void {
    const bottomSheetRef = this._bottomSheet.open(SendRecognitionBottomsheetComponent, {
      data: this.modelssData
    });
  }

  setFilterProductsValue(value: boolean) {
    const control = this.modelsForm.get('is_active');

    if (control?.value === value) {

      control.setValue(null);
    } else {

      control?.setValue(value);
    }
    control?.markAsTouched();
  }


  refreshModels() {
    this.modelsService.getModelsById(this.modelsId).subscribe((models: any) => {
      console.log('models1', models)
      this.default_models = models
      this.models_subject.next(this.default_models)

      if (models) {
        this.creating = false
        this.modelsForm.patchValue({
          name: models.name,
          description: models.description,
          version: models.version,
          server: models.server,
          server_path: models.server_path,
          rulesModels: models.rulesModels,
          model_key: models.model_key,
          is_active: models.is_active
        });
        this.modelssData = models
      }
    })
  }


}