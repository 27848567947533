import { Component, CUSTOM_ELEMENTS_SCHEMA, signal } from '@angular/core';
import { Config } from '@angular/fire/auth';
import { BehaviorSubject, config, Observable, Subject } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatLabel } from '@angular/material/form-field';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckIcon } from 'primeng/icons/check';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import { SendRecognitionBottomsheetComponent } from '../../shared/send-recognition-bottomsheet/send-recognition-bottomsheet.component';
import { ClientsService } from '../../../services/clients.service';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { Clients } from '../../../models/clients';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';

;

@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, CommonModule, MatOption, MatLabel, MainHeaderComponent, CheckIcon, MatCheckboxModule, MatInputModule, MatButtonModule, MatIconModule, MatIcon,MatRadioButton, MatRadioModule],
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.css',
  providers: [ClientsService],
})
export class ClientsComponent {

  readonly panelOpenState = signal(false);
  oculto = false;
  clientsId!: number;
  clientsData: any;
  showConfig = false;

  
  private default_clients: Partial<Clients> = {
    "name": " ",
     "config": {
      "default_bucket": "", 
      "filter_products": true, 
      "validate_unique": true,
      "validate_unique_key": "",
      "webhook_endpoint": '',
      "webhook_active": true,
    } 
  };
  private clients_subject = new BehaviorSubject<Clients | Partial<Clients> | undefined>(this.default_clients);
  clients$: Observable<Clients | Partial<Clients> | undefined> = this.clients_subject.asObservable();


  titleheader: string = 'Cliente'

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private clientsService: ClientsService, private router: Router, private _snackBar: MatSnackBar,private _bottomSheet: MatBottomSheet,){

  }
  formConfig = [
    { controlName: 'name', label: 'Nombre del cliente', type: 'text', width: '100%', margin: '' },
    { controlName: 'default_bucket', label: 'Bucket', type: 'textarea', width: '100%', margin: '' },
    { controlName: 'filter_products', label: '¿Filtar producto?', type: 'checkbox', width: '100%', margin: '' },
    { controlName: 'validate_unique_key', label: 'Clave unica', type: 'textarea', width: '100%', margin: '' },
    { controlName: 'validate_unique', label: '¿Validación única?', type: 'checkbox', width: '100%', margin: '' },
    { controlName: 'webhook_endpoint', label: 'Link endpoint', type: 'textarea', width: '100%', margin: '' },
    { controlName: 'webhook_active', label: '¿Webhook activo?', type: 'checkbox', width: '100%', margin: '' }
  
  ]

  clientForm : FormGroup= this.fb.group({
    name: ['',[ Validators.required,  Validators.minLength(3)]], 
    default_bucket: ['', [Validators.required, Validators.minLength(3)]],  
    filter_products: [null, [Validators.required]],
    validate_unique: [null, [Validators.required]],  
    validate_unique_key: ['', [Validators.required]],
    webhook_endpoint: ['', [Validators.required]],
    webhook_active: [null, [Validators.required]],
  });
  
  ngOnInit(): void {
    console.log('forsave1', this.for_save)
    this.clientForm.valueChanges.subscribe(v => {
      this.for_save = true
      console.log('forsave', this.for_save)
      this.default_clients = {  ...v, }     
      this.clients_subject.next(this.default_clients)
    })
    this.clientsId = +this.route.snapshot.paramMap.get('id')!;
    if (this.clientsId) {
      /* this.getTask() */
      this.refreshClients()
    } else {
      this.titleheader = 'Creando cliente'
      console.log('Creando nuevo')
  
  }
  }

  createClients(clients: Partial<Clients>) {
    const clientsData = { ...clients};
    console.log('data', clientsData)
    this.clientsService.createClients(clientsData).subscribe(
      response => {
        this.router.navigate(['/clients', response]);
      }
    );
  }
  


  saveClients() {
      if (this.clientForm.valid) {
        const formValue = this.clientForm.value;
        const clientsData = {
          name: formValue.name,
          config: {
            default_bucket: formValue.default_bucket,
            filter_products: formValue.filter_products,
            validate_unique: formValue.validate_unique,
            validate_unique_key: formValue.validate_unique_key,
            webhook_endpoint: formValue.webhook_endpoint,
            webhook_active: formValue.webhook_active,
          }
        };
        
        this.default_clients = {...clientsData}

        console.log('default', this.default_clients)
        if (this.clientsId) {
          this.updateClients(this.default_clients);
        } else {
          this.createClients(this.default_clients);
        }
        this.for_save = false;
  
      }
  }

  updateClients(clients: Partial<Clients>) {
    this.clientsService.updateClients(clients, this.clientsId ).subscribe((response) => {
      console.log('actualizacion', clients)
      this.router.navigate(['/clients', response]);
      this._snackBar.open(`Cliente Actualizado.`, 'ok');
    }
  )
  }

  creating?: boolean = true
  for_save: boolean = false

  
  recognitionDetails$ = new Subject<any[]>();
  openBottomSheet(): void {
    const bottomSheetRef = this._bottomSheet.open(SendRecognitionBottomsheetComponent, {
      data: this.clientsData
    });
  }

  setFilterProductsValue(value: boolean)  {
    const control = this.clientForm.get('filter_products');
  if (control?.value === value) {
  control.setValue(null);
  } else { 
    control?.setValue(value);
  }
  control?.markAsTouched();
}

setValidateUniqueValue(value: boolean) {
  const control = this.clientForm.get('validate_unique');
  if (control?.value === value) {
    control.setValue(null);
  } else {
    control?.setValue(value);
  }
  control?.markAsTouched();
}

setWebhookActiveValue(value: boolean) {
  const control = this.clientForm.get('webhook_active');
  if (control?.value === value) {
    control.setValue(null);
  } else {
    control?.setValue(value);
  }
  control?.markAsTouched();
}

  refreshClients() {
    this.clientsService.getClientsById(this.clientsId).subscribe(clients => {
      console.log('clients1', clients)
      this.default_clients = clients
      this.clients_subject.next(this.default_clients)
      console.log('clientes', this.default_clients)

      if (clients) {
        this.creating = false
        this.clientForm.patchValue({
          name: clients.name,
          default_bucket: clients.config.default_bucket,
          filter_products: clients.config.filter_products,
          validate_unique: clients.config.validate_unique,
          validate_unique_key: clients.config.validate_unique_key,
          webhook_endpoint: clients.config.webhook_endpoint,
          webhook_active: clients.config.webhook_active,

         
        });
        this.clientsData = clients
      }
    })
    
  }
}
