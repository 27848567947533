import { inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';

export const adminGuard: CanActivateFn = async (route, state) => {
  const auth = inject(Auth);
  const router = inject(Router);

  try {
    const tokendata = await auth.currentUser?.getIdTokenResult();
    // Verifica los claims del token
    if (!tokendata?.claims['superadmin'] && !tokendata?.claims['admin']) {
      router.navigate(['/auth'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    } else {
      return true;
    }

  } catch (error) {
    router.navigate(['/auth'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
};
