// app.config.ts
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './core/auth.interceptor';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getVertexAI, provideVertexAI } from '@angular/fire/vertexai-preview';
import { environment } from '../environments/environment';

import { provideServiceWorker } from '@angular/service-worker';
import { SecureInterceptor } from './core/sec.interceptor';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    provideFirestore(() => getFirestore('recognition-system')),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    provideVertexAI(() => getVertexAI()),
    { provide: HTTP_INTERCEPTORS, useClass: SecureInterceptor, multi: true },
    importProvidersFrom(MonacoEditorModule.forRoot({
      baseUrl: '/assets/monaco/min/vs',
      defaultOptions: { scrollBeyondLastLine: false },
      onMonacoLoad: () => {
        console.log('Monaco Editor cargado');
      }
    }))
    
    
  ]
};