import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Dashboards } from '../models/dashboards';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { Clients } from '../models/clients';
import { Users } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class DashboardsService {
  private baseUrl: string = `${environment['api_backend']}/api`
  private apiUrlRecon = `${environment['api_recognition']}/v1/recognition`
  private apiUrlDetails = `${environment['api_recognition']}/v2/recognition/details`
  // TOKEN PARA API RECOGNITION
  private headers = new HttpHeaders({
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfdWlkIjoiY2IxNDk2Y2QtOTJhZi00YjE0LWFiZWItMjMwZWUzZGU5ZjA4IiwiY2xpZW50X2lkIjo0LCJpYXQiOjE3MjI0ODIzOTIsImV4cCI6MTcyMjQ5MzE5Mn0.6VEgFrRSx9uHEGWpAMhJViRzxwnOc7EyamkgrhnEojw`,
      'Accept': 'application/json'
  });
  
  private usersLoaded = false;
  private DashboardsSubject = new BehaviorSubject<Dashboards[]>([]);
  dashboards$ =  this.DashboardsSubject.asObservable(); 
  
  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(error);
  }

  private users: Users[] = []; 

  constructor(private http: HttpClient) { }

  
  createDashboards(dashboards: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(`${this.baseUrl}/dashboards`, dashboards, { headers })
        .pipe(
      tap(() => this.loadDashboardsStore()), // Actualizar la lista después de crear
      catchError(this.handleError)
    );
  };

  loadUsers(): Observable<Users[]> {
  let params = new HttpParams().set('limit', '200');
  return this.http.get<Users[]>(`${this.baseUrl}/users`, { params })
    .pipe(catchError(this.handleError));
  }

  loadDashboardsStore(): void {
    forkJoin({
      users: this.loadUsers(),
      dashboards: this.http.get<Dashboards[]>(`${this.baseUrl}/dashboards`, { params: new HttpParams().set('limit', '2000') }).pipe(catchError(this.handleError))
    }).subscribe(({ users, dashboards }) => {
      this.users = users;
      const updatedDashboards = this.replaceRoleUidsWithEmails(dashboards); // Confirmar que los datos están actualizados
      this.DashboardsSubject.next(updatedDashboards);
    });
  }

 replaceRoleUidsWithEmails(dashboards: Dashboards[]): Dashboards[] {
  const userMap = new Map(this.users.map(user => [user.uid, user.email]));

  return dashboards.map(dashboard => ({
    ...dashboard,
    roles: Object.fromEntries(
      Object.entries(dashboard.roles ?? {}) // Asegurarse de que roles no sea null/undefined
        .filter(([uid]) => userMap.has(uid)) // Filtrar UIDs que no estén en userMap
        .map(([uid, role]) => [userMap.get(uid), role]) // Reemplazar UID con email
    ),
  }));
}


  getDashboardsByUid(dashboardUid: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/dashboards/${dashboardUid}`); 
    }

  updateDashboard(dashboards: Partial<Dashboards>, uid: string) {
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.http.patch<any>(`${this.baseUrl}/dashboards/${uid}`, dashboards, { headers })
    .pipe(
      tap(() => this.loadDashboardsStore()), // Actualizar la lista después de actualizar
      catchError(this.handleError)
    );
 } 

  deleteDashboards(dashboardUid:string): Observable<Dashboards> {
    return this.http.delete<Dashboards>(`${this.baseUrl}/dashboards/${dashboardUid}`)
  }  

  loadClients(): Observable<Clients[]> {
    let params = new HttpParams().set('limit', '200');
    return this.http.get<Clients[]>(`${this.baseUrl}/clients`, { params })
      .pipe(catchError(this.handleError));
  }
}
