import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { Library } from '../models/library';
import { BehaviorSubject, catchError, filter, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  private baseUrl: string = `${environment['api_backend']}/api`
  private apiUrlRecon = `${environment['api_recognition']}/v1/recognition`
  private apiUrlDetails = `${environment['api_recognition']}/v2/recognition/details`
  // TOKEN PARA API RECOGNITION
  private headers = new HttpHeaders({
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfdWlkIjoiY2IxNDk2Y2QtOTJhZi00YjE0LWFiZWItMjMwZWUzZGU5ZjA4IiwiY2xpZW50X2lkIjo0LCJpYXQiOjE3MjI0ODIzOTIsImV4cCI6MTcyMjQ5MzE5Mn0.6VEgFrRSx9uHEGWpAMhJViRzxwnOc7EyamkgrhnEojw`,
      'Accept': 'application/json'
  });
  private LibrarySubject = new BehaviorSubject <Library[]>([]);
  library$ =  this.LibrarySubject.asObservable().pipe(
    filter(library => library.length > 0));

  private handleError(error: any): Observable <never> {
    console.error('An error occurred:', error);
    return throwError(error);
  }
  constructor(private http: HttpClient) {};

    updateLibrary( library: Partial<Library>, libraryId:number) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.patch<Library>(`${this.baseUrl}/library-objects/${libraryId}`, library, { headers })
          .pipe(
              catchError(this.handleError)
          );
    }
    getLibraryById(libraryId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/library-objects/${libraryId}`); 
    }

    createLibrary(library: any) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post<any>(`${this.baseUrl}/library-objects`, library, { headers })
          .pipe(
              catchError(this.handleError)
          )
    };

    loadLibraryStore(): void {
        let params = new HttpParams().set('limit', '2000');
        this.http.get<Library[]>(`${this.baseUrl}/library-objects`, { params }).subscribe((library: Library[],) => {
          console.log("Datos recibidos:", library);
            this.LibrarySubject.next(library);
            console.log("Datos asignados a LibrarySignal:", this.LibrarySubject);
        })
    }

  
      deleteLibrary(libraryId:number): Observable<Library> {
      return this.http.delete<Library>(`${this.baseUrl}/library-objects/${libraryId}`)
    }
}
