import { ɵgetAllInstancesOf as _getAllInstancesOf, ɵgetDefaultInstanceOf as _getDefaultInstanceOf, VERSION, ɵAngularFireSchedulers as _AngularFireSchedulers, ɵAppCheckInstances as _AppCheckInstances, ɵzoneWrap as _zoneWrap } from '@angular/fire';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { InjectionToken, Optional, NgModule, makeEnvironmentProviders, NgZone, Injector } from '@angular/core';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { registerVersion } from 'firebase/app';
import { getVertexAI as getVertexAI$1, getGenerativeModel as getGenerativeModel$1 } from 'firebase/vertexai-preview';
export * from 'firebase/vertexai-preview';
class VertexAI {
  constructor(vertexai) {
    return vertexai;
  }
}
const VERTEX_AI_PROVIDER_NAME = 'vertexai';
class VertexAIInstances {
  constructor() {
    return _getAllInstancesOf(VERTEX_AI_PROVIDER_NAME);
  }
}
const vertexAIInstance$ = timer(0, 300).pipe(concatMap(() => from(_getAllInstancesOf(VERTEX_AI_PROVIDER_NAME))), distinct());
const PROVIDED_VERTEX_AI_INSTANCES = new InjectionToken('angularfire2.vertexai-instances');
function defaultVertexAIInstanceFactory(provided, defaultApp) {
  const defaultVertexAI = _getDefaultInstanceOf(VERTEX_AI_PROVIDER_NAME, provided, defaultApp);
  return defaultVertexAI && new VertexAI(defaultVertexAI);
}
function vertexAIInstanceFactory(fn) {
  return (zone, injector) => {
    const vertexAI = zone.runOutsideAngular(() => fn(injector));
    return new VertexAI(vertexAI);
  };
}
const VERTEX_AI_INSTANCES_PROVIDER = {
  provide: VertexAIInstances,
  deps: [[new Optional(), PROVIDED_VERTEX_AI_INSTANCES]]
};
const DEFAULT_VERTEX_AI_INSTANCE_PROVIDER = {
  provide: VertexAI,
  useFactory: defaultVertexAIInstanceFactory,
  deps: [[new Optional(), PROVIDED_VERTEX_AI_INSTANCES], FirebaseApp]
};
class VertexAIModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'vertexai');
  }
  static ɵfac = function VertexAIModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || VertexAIModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: VertexAIModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [DEFAULT_VERTEX_AI_INSTANCE_PROVIDER, VERTEX_AI_INSTANCES_PROVIDER]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VertexAIModule, [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_VERTEX_AI_INSTANCE_PROVIDER, VERTEX_AI_INSTANCES_PROVIDER]
    }]
  }], () => [], null);
})();
function provideVertexAI(fn, ...deps) {
  registerVersion('angularfire', VERSION.full, 'vertexai');
  return makeEnvironmentProviders([DEFAULT_VERTEX_AI_INSTANCE_PROVIDER, VERTEX_AI_INSTANCES_PROVIDER, {
    provide: PROVIDED_VERTEX_AI_INSTANCES,
    useFactory: vertexAIInstanceFactory(fn),
    multi: true,
    deps: [NgZone, Injector, _AngularFireSchedulers, FirebaseApps, [new Optional(), _AppCheckInstances], ...deps]
  }]);
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const getVertexAI = _zoneWrap(getVertexAI$1, true);
const getGenerativeModel = _zoneWrap(getGenerativeModel$1, true);

/**
 * Generated bundle index. Do not edit.
 */

export { VertexAI, VertexAIInstances, VertexAIModule, getGenerativeModel, getVertexAI, provideVertexAI, vertexAIInstance$ };
