import { inject } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service'; // Importa tu AuthService

export const authRedirectGuard: CanActivateFn = (route, state) => {
  const auth = inject(Auth);
  const router = inject(Router);
  const authService = inject(AuthService);

  return new Promise<boolean>((resolve) => {

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Verificar si el usuario está en la colección `users`
        const isAuthorized = await authService.checkUserAuthorization(user.uid);
       
        if (isAuthorized) {

          router.navigateByUrl(route.queryParams['returnUrl']); 
          resolve(false); // Bloquea la navegación hacia la ruta actual
        } else {
          authService.showUnauthorizedMessage()
          
          await authService.signOut(); // Cierra sesión si no está autorizado
          resolve(true); // Permitir la navegación a la ruta de autenticación
        }
      } else {
        resolve(true); // Si no hay usuario, permitir el acceso a la ruta de auth
      }
    });
  });
};
