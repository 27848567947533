import { Component, signal } from '@angular/core';
import { Users } from '../models/user';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from '@angular/fire/auth';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UsersService } from './users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SendRecognitionBottomsheetComponent } from '../components/shared/send-recognition-bottomsheet/send-recognition-bottomsheet.component';
import { CommonModule } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatLabel } from '@angular/material/form-field';
import { MainHeaderComponent } from '../components/shared/main-header/main-header.component';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { CheckIcon } from 'primeng/icons/check';
import { ClientsService } from '../services/clients.service';
import { Clients } from '../models/clients';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, CommonModule, MatOption, MatLabel, MainHeaderComponent, MatInputModule, MatButtonModule, MatIconModule, MatIcon, CheckIcon, MatSelectModule, MatCheckboxModule],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css',
  providers: [UsersService]
})
export class UsersComponent {
  readonly panelOpenState = signal(false);
  oculto = false;
  usersUid!: string;
  usersData: any;
  showConfig = false;
  clients: Clients[]= []
  
  
  private default_users: Partial<Users> = {
    "client_id": 1,
    "uid": " ",
    "name": " ",
    "email" : " ",
    "is_active": true,
    "roles" :{
      "viewer": true,
      "admin": true,
      "superadmin": true,
    }
  };
  private users_subject = new BehaviorSubject<Users | Partial<Users> | undefined>(this.default_users);
  users$: Observable<Users| Partial <Users> | undefined> = this.users_subject.asObservable();



  titleheader: string = 'Usuario'

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private usersService: UsersService, private clientsService: ClientsService, private router: Router, private _snackBar: MatSnackBar, private _bottomSheet: MatBottomSheet){

  }
 formConfig = [
  { controlName: 'client_id', label: 'Cliente', type: 'select', width: '100%', margin: '' },
  { controlName: 'name', label: 'Nombre del usuario', type: 'text', width: '100%', margin: '' },
  { controlName: 'email', label: 'Correo electrónico', type: 'textarea', width: '100%', margin: '' },
  { controlName: 'roles', label: 'Roles', type: 'checkbox', width: '100%', margin: '' },
  { controlName: 'is_active', label: 'Activo', type: 'checkbox', width: '100%', margin: '' },

 ]
 
 usersForm : FormGroup= this.fb.group({
  client_id: ['', [Validators.required ]],
  name: [''],
  email: ['', [Validators.required, Validators.email]],
  is_active: [null],
  roles: this.fb.group({
    viewer: [false],
    admin: [false],
    superadmin: [false]
  })
 })

 ngOnInit(): void {
  this.loadClients();
  this.usersForm.valueChanges.subscribe(v => {
    this.for_save = true
    console.log('forsave', this.for_save)
    this.default_users = {  ...v, }     
    this.users_subject.next(this.default_users)
  })
  this.usersUid = this.route.snapshot.paramMap.get('uid')!;
  if (this.usersUid) {
    console.log('UID encontrado:', this.usersUid); 
    this.refreshUsers()
  } else {
    this.titleheader = 'Creando usuario'
    console.log('Creando nuevo')

}
}

createUsers(users: Partial<Users>) {
  const usersData = { ...users};
  console.log('data', usersData)
  this.usersService.createUsers(usersData).subscribe(
    () => {
      this.router.navigate(['/users']);
      this._snackBar.open(`Usuario creado.`, 'ok');
    }
  );
}



saveUsers() {
    if (this.usersForm.valid) {
      const formValue = this.usersForm.value;
      const usersData = {
        client_id: formValue.client_id,
        name: formValue.name,
        email: formValue.email,
        is_active: formValue.is_active,
        roles: {
          viewer: formValue.roles?.viewer,
          admin: formValue.roles?.admin,
          superadmin: formValue.roles?.superadmin
        }
      };
      
      this.default_users = {...usersData}

      console.log('default', this.default_users)
      if (this.usersUid) {
        this.updateUsers( this.default_users);
      } else {
        this.createUsers( this.default_users);
      }
      this.for_save = false;

    }
}

updateUsers(users: Partial<Users>) {
  this.usersService.updateUsers(users, this.usersUid ).subscribe({ 
    next: () => {
      console.log('Usuario actualizado:', users);
      this.router.navigate(['/users']);
      this._snackBar.open(`Usuario Actualizado.`, 'ok');
    }, error: () => this._snackBar.open('error al actualiza usuarios', 'cerrar', {duration:3000})

    })
}

creating?: boolean = true
for_save: boolean = false


recognitionDetails$ = new Subject<any[]>();
openBottomSheet(): void {
  const bottomSheetRef = this._bottomSheet.open(SendRecognitionBottomsheetComponent, {
    data: this.usersData
  });
}

setFilterProductsValue(value: boolean) {
  const control = this.usersForm.get('is_active');

  if (control?.value === value) {

    control.setValue(null);
  } else {

    control?.setValue(value);
  }
  control?.markAsTouched();
}

refreshUsers() {
  this.usersService.getUsersByUid(this.usersUid).subscribe(users => {
    console.log('clients1', users)
    this.default_users = users
    if (users) {
      this.creating = false;  // Indica que estamos en modo edición
      this.usersForm.patchValue({
        client_id: users.client_id,
        name: users.name,
        email: users.email,
        is_active: users.is_active,
        roles: {
          viewer: users.roles.viewer,
          admin: users.roles.admin,
          superadmin: users.roles.superadmin
        }
      });
      this.usersData = users
      this.users_subject.next(this.default_users)
   
      }
    })
  }

  loadClients(): void {
    this.usersService.loadClients().subscribe((clients: Clients[]) => {
      this.clients = clients; 
      console.log('Clientes cargados:', this.clients);
    });
  }
}