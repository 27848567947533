<!-- <mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="side">
      <p>Auto-resizing sidenav</p>
      @if (showFiller) {
        <p>Lorem, ipsum dolor sit amet consectetur.</p>
      }
      <button (click)="showFiller = !showFiller" mat-raised-button>
        Toggle extra text
      </button>
    </mat-drawer>
  
    <div class="example-sidenav-content">
      <button type="button" mat-button (click)="drawer.toggle()">
        Toggle sidenav
      </button>
    </div>
  
  </mat-drawer-container> -->
<h1>componente de menu</h1>
  