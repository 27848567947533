
import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
@Component({
  standalone: true,
  imports: [CommonModule, AutoCompleteModule, FormsModule],
  selector: 'app-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.css']
})
export class SearchAutocompleteComponent {
  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() disabledBool: boolean = false;
  @Input() items: any[] = []; // Datos de entrada
  @Output() itemSelected = new EventEmitter<any>(); // Evento de salida

  selectedItem: any;
  filteredItems!: any[];

  ngOnInit(){ }

  filterItems(event: any): void {
    const query = event.query.toLowerCase();
    this.filteredItems = this.items.filter(item =>
      item.name.toLowerCase().includes(query) ||
      item.description.toLowerCase().includes(query)
    );
  }

  onSelect(event: any): void {
    this.itemSelected.emit(this.selectedItem);
  }
}
