import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SearchAutocompleteComponent } from '../search-autocomplete/search-autocomplete.component';
import { map, Observable } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RulesService } from '../../rules/rules.service';
import { MissionsService } from '../../../services/missions.service';
import { MatIconModule } from '@angular/material/icon';
import { DynamicFormsComponent } from '../dynamic-forms/dynamic-forms.component';
import { DynamicFormField, FieldType } from '../dynamic-forms/model';
import { Rule, RuleParameter } from '../../../models/rules';
import { TaskRuleParameter } from '../../../models/task_rule';
import { MatExpansionModule } from '@angular/material/expansion';
import { TaskModel } from '../../../models/task_model';

function typeConvertion(type: string) {
  let t: FieldType
  switch (type) {
    case 'str':
    case 'list':
    case 'string':
      t = 'string'
      break;
    case 'json':
      t = 'textarea'
      break;
    case 'bool':
    case 'boolean':
      t = 'boolean'
      break;

    default:
      t = 'string'
      break;
  }
  return t
}

@Component({
  selector: 'app-dialog-task-rule-params',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatDialogTitle, MatDialogContent, FormsModule, ReactiveFormsModule, AutoCompleteModule, SearchAutocompleteComponent, MatButtonModule, MatFormFieldModule, MatInputModule, MatIconModule, MatExpansionModule,
    DynamicFormsComponent
  ],
  templateUrl: './dialog-task-rule-params.component.html',
  styleUrl: './dialog-task-rule-params.component.css'
})
export class DialogTaskRuleParamsComponent {


  $FORM_CONFIG!: Observable<DynamicFormField[]>
  rules$: Observable<Rule[]>
  state: string = 'creando';
  disableInput: boolean = true;
  task_rule_parameters_data!: any
  task_rule_parameters!: any
  isRuleAssigned: string[] = [];
  taskRuleForm: FormGroup;
  isEditing = false;

  rule_id!: number
  task_id!: number
  task_rule_id!: number
  rule_parameters: any = {}
  data_for_save = {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private rulesService: RulesService,
    private missionsService: MissionsService,
    private dialogRef: MatDialogRef<DialogTaskRuleParamsComponent>
  ) {
    this.taskRuleForm = this.fb.group({
      taskruleweights: [this.data.data !== 'nuevo' ? this.data.data.taskruleweights?.weight || 0.5 : 0.5],
      taskrulelabel: [this.data.data !== 'nuevo' ? this.data.data.taskrulelabel?.value || 'Ingresa un nuevo nombre' : 'Ingresa un nuevo nombre'],
    });

    this.task_id = this.data.task_id;
    this.rule_id = this.data.data.rule_id;
    this.task_rule_id = this.data.data.task_rule_id;
    this.rules$ = this.rulesService.getRules();

    if (this.rule_id) this.setParameterTemplate();

    if (this.data.data != 'nuevo') {
      this.state = 'exist'
      this.task_rule_parameters_data = this.data.data.taskruleparameters.reduce((acc: any, item: TaskRuleParameter) => {
        acc[item.ruleparameter.key] = this.parseValue(item.value ? item.value : '')
        return acc;
      }, {})
    } else {
      this.disableInput = false;
    }
  }

  setParameterTemplate() {
    const task_models = this.data.task_models
    this.$FORM_CONFIG = this.rulesService.getRuleById(this.rule_id).pipe(
      map((rule: RuleParameter) => {
        const models_options = task_models.map((task_model: TaskModel) => {
          return {
            label: task_model.model.name,
            value: task_model.model.model_key
          }
        })

        const update_recursive_models = (value: DynamicFormField, key: FieldType) => {
          if (value.fields) {
            value.fields?.forEach(element => {
              if (element.fields) {
                update_recursive_models(element, key)
              } else if (element.type === key && element.name === 'model_key') {
                element.options = models_options
              }
            });
          } else if (value.type === key && value.name === 'model_key') {
            value.options = models_options
          }
          return value
        }

        const template: DynamicFormField[] = rule.rulesparameters.map((r) => {
          this.rule_parameters[r.key] = r.rule_parameter_id
          if (r.input_validator) {
            const _templates = JSON.parse(r.input_validator)
            return update_recursive_models(_templates, 'model')
          } else {
            return {
              type: r.format ? typeConvertion(r.format) : typeConvertion('string'),
              name: r.key ? r.key : 'Sin definir'
            }
          }
        })

        console.log('template', template)
        return template
      })
    )
  }

  stringifyValues(value: any) {
    switch (typeof value) {
      case 'string':
      case 'boolean':
      case 'number':
        return `${value}`
      case 'object':
        return JSON.stringify(value)
      case undefined:
        return ''
      default:
        return `${value}`
    }

  }

  parseValue(value: any) {
    // Eliminar espacios en blanco
    const str = value.trim();

    // Verificar si es un booleano (sin importar mayúsculas/minúsculas)
    if (str === 'true') return true;
    if (str === 'false') return false;

    // Verificar si es numérico (Number('123') devuelve 123 y Number('abc') devuelve NaN)
    if (!isNaN(str)) return Number(str);

    // Intentar parsear el string como JSON
    try {
      return JSON.parse(str.replace(/'/g, '"'))
    } catch (e) {
      // Si falla, se devuelve el string original
      return value;
    }
  }


  ngOnInit(): void {
    this.LoadTaskDetail()
    this.taskRuleForm.valueChanges.subscribe(v => {

      this.data_for_save = {
        rule_id: this.rule_id,
        task_id: this.task_id,
        taskruleweights: { weight: this.taskRuleForm.value.taskruleweights },
        taskrulelabel: {
          value: this.taskRuleForm.value.taskrulelabel
        },
        taskruleparameters: this.task_rule_parameters
      };
      console.log('data_for_save', this.data_for_save)
    })
  }

  enableEdit() {
    this.isEditing = true;
  }

  LoadTaskDetail() {
    this.missionsService.getTaskById(this.task_id).subscribe((task) => {
      if (Array.isArray(task.tasksrules)) {
        this.isRuleAssigned = task.tasksrules.map((rule: any) => rule.rule_id);
      } else {
        console.error('taskRule no contiene datos válidos.');
      }
    });
  }

  onValueChange(event: any) {
    this.data_for_save = this.transformDataForUpdate(event)
  }

  saveRuleTaskParms() {
    if (!Object.keys(this.data_for_save).length) {
      alert('El formulario no es valido.')
      throw new Error("not_data");
    }

    let resultType;
    if (this.state === 'creando') {
      resultType = 'nuevo';
    } else {
      resultType = 'existente';
    }
    this.dialogRef.close({ data: { ...this.data_for_save }, type: resultType });
  }


  isRulesAssigned(ruleId: string): boolean {
    return this.isRuleAssigned.includes(ruleId)
  }

  transformDataForUpdate(inputData: any) {
    this.task_rule_parameters = Object.keys(inputData).map(key => {
      const current = this.data?.data?.taskruleparameters?.find((trp: TaskRuleParameter) => trp.ruleparameter.key === key)
      const rule_parameter_id = this.rule_parameters[key]
      if (current) {
        return {
          task_rule_parameter_id: current.task_rule_parameter_id,
          task_rule_id: current.task_rule_id,
          rule_parameter_id: current.rule_parameter_id,
          value: this.stringifyValues(inputData[key]),
        }
      } else {
        return {
          rule_parameter_id: rule_parameter_id,
          value: this.stringifyValues(inputData[key]),
        }
      }
    })


    const task_rule = {
      rule_id: this.rule_id,
      task_id: this.task_id,
      taskruleweights: { weight: this.taskRuleForm.value.taskruleweights },
      taskrulelabel: {
        value: this.taskRuleForm.value.taskrulelabel
      },
      taskruleparameters: this.task_rule_parameters
    };
    return task_rule;
  }


  onItemSelected(item: any): void {
    this.rule_id = item.rule_id
    this.setParameterTemplate()
  }

}
