<mat-form-field class="full-width">
    <mat-label>{{config.label ? config.label : config.name}}</mat-label>
    <input matInput [formControl]="productInput" (keydown.enter)="addProducts();$event.stopPropagation()"
        [placeholder]="config.placeholder ? config.placeholder : 'Ingrese el valor del parámetro'">
    <button mat-button matSuffix (click)="addProducts();$event.stopPropagation()">
        <mat-icon>Agregar</mat-icon>
    </button>
</mat-form-field>
@if(products.length){
<table mat-table [dataSource]="products">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Elemento </th>
        <td mat-cell *matCellDef="let product"> {{product}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Acciones </th>
        <td mat-cell *matCellDef="let product">
            <button mat-button color="warn" (click)="removeProduct(product)">
                <mat-icon>Eliminar</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
}