@if(user$ | async; as user){

<div class="ctn-profile">
    <div class="ctn-info">
        @if(user.email === 'c.moreno@lucro-app.com'){
        <span style="font-size:10px;">v1</span>
        }
        <span>{{user.displayName}}</span>
        <span class="mail">{{user.email}}</span>
    </div>
    @if(user.photoURL){
    <div>
        <img class="image-user" [src]="user.photoURL" alt="">
    </div>
    }@else {
    <div class="letter">
        {{user.email.charAt(0)}}
    </div>
    }
</div>
}