<app-main-header [name_main]="titleheader" [id_code]="missionId">
    @if(creating){
    <button mat-flat-button color="primary" (click)="saveTask()">Guardar</button>
    }@else {
    <!-- <button class="check-btn" mat-flat-button (click)="openBottomSheet()">
        <span class="material-symbols-outlined">add_photo_alternate</span>COMPROBAR MISIÓN
    </button> -->
  <button mat-flat-button color="warn" (click)="deleteTask(missionId)" class="btn">Borrar</button>
    <button mat-flat-button color="primary" (click)="onClone()" class="btn">Duplicar</button> 
    <button mat-flat-button color="primary" [disabled]="!missionForm.valid "
        (click)="saveTask()" class="btn">Actualizar</button>

    }
</app-main-header>

<form [formGroup]="missionForm">
    <!-- <app-image-recognition [recognitionResults$]="recognitionDetails$"></app-image-recognition> -->
    <!-- <mat-accordion>
        <mat-expansion-panel expanded="true" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
                <mat-panel-title> Resultado reconociemiento </mat-panel-title>
                <mat-panel-description>
                    442f9681-d265-47a3-9ded-4cea79148313
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="product-viewer">
                <div class="ima">
                    <div #container class="image-container">
                        <img #image [src]="currentImagePath" class="image" (load)="onImageLoad()" />
                        <svg #svgContainer class="svg-overlay"></svg>
                        <svg width="0" height="0">
                            <defs>
                                <pattern id="line-pattern" width="4" height="4" patternUnits="userSpaceOnUse"
                                    patternTransform="rotate(45)">
                                    <rect width="2" height="4" transform="translate(0,0)" fill="white"></rect>
                                    <animateTransform attributeName="patternTransform" type="translate" from="0,0"
                                        to="4,4" begin="0s" dur="1s" repeatCount="indefinite" />
                                </pattern>
                            </defs>
                        </svg>
                        <span style="opacity: 1;">{{currentImagePath}}</span>
                    </div>
                </div>
                <div class="list-objects">
                    <span><b>Listado de objetos detectados: {{groupedProducts.length}}</b></span>
                    @for (d of groupedProducts;track d;let idx = $index) {
                    <span [class.selected]="idx === selectedProductIndex" (mouseover)="highlightBox(d, idx)">
                        <span class="number">{{idx + 1}}</span>
                        <span> {{d.name}}</span>

                        <span [ngClass]="d.score > 0.5 ? 'valid':'verify'">
                            {{d.score}}
                        </span>
                    </span>
                    }
                </div>
            </div>
            <div *ngIf="resulRecon" style="margin: 40px 0;">
                <span style="margin: 10px 0;">
                    Veredicto de la imagen {{resulRecon.image_id}}
                </span>
                <span [ngClass]="resulRecon.veredict != false ? 'valid':'verify'">
                    {{resulRecon.veredict === false ? 'Falso':'Verdadero'}}
                </span>
                <br>
                <br>
                <p-table [value]="resulRecon.details">
                    <ng-template pTemplate="header">
                        <tr style="font-size: 12px;">
                            <th>Regla</th>
                            <th>Aprobado</th>
                            <th>Puntuación</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-dw>
                        <tr style="font-size: 12px;">
                            <td>{{ dw.rule }}</td>
                            <td>{{ dw.approved }}</td>
                            <td>{{ dw.score }}</td>
                    </ng-template>
                </p-table>
            </div>
        </mat-expansion-panel>
    </mat-accordion> -->
    <div class="mission-data">
        @for (d of formConfig; track d.label; let idx = $index, e = $even) {
        <mat-form-field appearance="outline" [ngStyle]="{ 'width': d.width, 'margin':d.margin}">
            <mat-label>{{ d.label }}</mat-label>
            <input *ngIf="d.type != 'select'" matInput [formControlName]="d.controlName" [type]="d.type">
            <mat-select *ngIf="d.type === 'select'" [formControlName]="d.controlName">
                <mat-option *ngFor="let option of d.options" [value]="option.value" disabled="true">{{ option.viewValue
                    }}</mat-option>
            </mat-select>
        </mat-form-field>
        }
    </div>
    <!-- @if(mission$ | async; as mission){ -->
    
    <div class="mission-parameters">
        <div class="title-btn">
            <h2>Modelos de reconocimiento</h2>
            <!-- <button mat-flat-button color="primary">Añadir Modelo</button> -->
        </div>
        @if(listModels$ | async; as models){
        <app-search-autocomplete [title]="'Busca y añade módelos'" [icon]="'models'" [items]="models"
            (itemSelected)="onItemSelected($event)">
        </app-search-autocomplete>
        }

        @if(mission$ | async; as mission){
        <p-table [value]="mission.taskmodels!" emptyMessage="No hay datos disponibles"
            [tableStyle]="{ 'min-width': '50rem' }">
            <ng-template pTemplate="header">
                <tr style="font-size: 12px;">
                    <th>Id</th>
                    <th>Nombre</th>
                    <th>Servidor</th>
                    <th>Ruta</th>
                    <th>Description</th>
                    <th pSortableColumn></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-models>
                <tr style="font-size: 12px;">
                    <td>{{ models.model.model_id }}</td>
                    <td>{{ models.model.name }}</td>
                    <td>{{ models.model.server }}</td>
                    <td>{{models.model.server_path}}</td>
                    <td>{{models.model.description}}</td>
                    <td>
                        <button mat-icon-button [matMenuTriggerFor]="menu1">
                            <span class="material-symbols-outlined">more_vert</span>
                        </button>
                        <mat-menu #menu1="matMenu">
                            <button (click)="deleteTaskModel(models)" mat-menu-item>
                                <span class="material-symbols-outlined">delete</span>
                                <span>Eliminar</span>
                            </button>
                        </mat-menu>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        }@else{
        <div class="contone">
            <img width="80" src="assets/icons/no_model.svg" alt="">
            No hay modelos asignados a la Misión
        </div>
        }
    </div>
    <br>
    <div class="mission-parameters">
        <div class="title-btn">
            <div>
                <h2>Reglas</h2>
                <span>Peso requerido: {{ requiredWeight }}</span>
            </div>

            <button mat-flat-button color="primary" (click)="openDialogTaskRuleprams('nuevo')">Añadir Regla</button>
        </div>
        @if(mission$ | async; as mission){
        <p-table [value]="mission.tasksrules!" [tableStyle]="{ 'min-width': '50rem' }"
            (onValueChange)="onTableChange($event)">
            <ng-template pTemplate="header">
                <tr style="font-size: 12px;">
                    <th>Id</th>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Peso</th>
                    <th pSortableColumn></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rules>
                <tr style="font-size: 12px;">
                    <td>{{ rules.rule.rule_id }}</td>
                    <td>{{ rules.rule.task || rules.taskrulelabel.value }}</td>
                    <td>{{ rules.rule.description }}</td>
                    <td> {{rules.taskruleweights?.weight || 0}}</td>
                    <td>
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <span class="material-symbols-outlined">more_vert</span>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="openDialogTaskRuleprams(rules)">
                                <span class="material-symbols-outlined">edit</span>
                                <span>Editar</span>
                            </button>
                            <button (click)="deleteTaskRule(rules)" mat-menu-item>
                                <span class="material-symbols-outlined">delete</span>
                                <span>Eliminar</span>
                            </button>
                        </mat-menu>
                    </td>
                </tr>
            </ng-template>
             
            <ng-template pTemplate="footer">
                 <tr style="font-size: 12px;">
               <td colspan="2">Total Peso</td>
                <td  class="fw-bold" [ngClass]="totalWeight >= requiredWeight ? 'text-success' : 'text-danger'">
                  {{ totalWeight >= requiredWeight ? 'Cumple' : 'No cumple' }} </td>
                <td class="footer-content" type="totalWeight">{{ totalWeight }}</td>
                <td type="requiredWeight"> Requerido: {{requiredWeight}}</td>
            
        </tr>
            </ng-template>
        </p-table>
        }@else {
        <div class="contone">
            <img width="80" src="assets/icons/no_task.svg" alt="">
            No hay reglas asignadas a la Misión
        </div>
        }
    </div>
</form>