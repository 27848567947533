import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MenuComponent } from '../shared/menu/menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '../../services/auth.service';
import { BehaviorSubject, tap, timer, Observable } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule, RouterLink, RouterLinkActive, MenuComponent, MatSidenavModule, MatButtonModule, MatIconModule, MatTooltipModule, MatProgressSpinnerModule, 
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css'
})
export class LayoutComponent {
  client: number | null = null;
  rol:boolean =  false
  // logoUrl: string = 'assets/small-icon.svg'; // Logo por defecto
  iconUrl!: string
  logoUrl!: string
  auth = inject(AuthService)

  constructor( private cdRef: ChangeDetectorRef) {

  }
  iconview: boolean = true
  user$! :Observable<any>;
  ngOnInit() {
    // Suscríbete al usuario autenticado
    this.user$ = this.auth.userData$.pipe(
      tap(d => {
        // console.log('Datos del usuario:', d);
        this.client = d.client_id;
        this.rol = d.rol;
        this.setLogo(this.client);
        this.cdRef.detectChanges();
        // Oculta el icono después de 2 segundos
        timer(500).subscribe(() => {
          this.iconview = false;
        });
      })
    );

  }

  private setLogo(client_id: number | null) {
    // console.log('client_id', client_id)
    switch (client_id) {
      case 7:
        this.iconUrl = 'assets/icon-pepsi.svg';
        this.logoUrl = 'assets/logo-pepsi.svg';
        break;
      case 2:
        this.iconUrl = 'assets/small-icon.svg';
        this.logoUrl = 'assets/small-icon.svg';
        break;
      default:
        this.logoUrl = 'default-logo.png';
        break;
    }
  }

  signOut() {
    this.auth.signOut()
  }
}
