import { Component, CUSTOM_ELEMENTS_SCHEMA, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormField } from '../model';

@Component({
  selector: 'app-input-field',
  standalone: true,
  templateUrl: './input-field.component.html',
  styleUrl: './input-field.component.css',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldComponent),
    multi: true
  }],
  imports: [ReactiveFormsModule, MatInputModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InputFieldComponent implements ControlValueAccessor {
  @Input() config!: DynamicFormField;
  @Input() formControl!: FormControl;
  @Input() type!: any;

  writeValue(value: string): void {
}
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}
