import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { signal, computed, effect } from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ModelService {

    private baseUrl: string = `${environment['api_backend']}/api`

    constructor(private http: HttpClient) {
    }

    getModels(): Observable<any> {
        let params = new HttpParams().set('limit', '200');
        return this.http.get(`${this.baseUrl}/models/`, { params });
    }
}