<app-main-header [name_main]="'Misiones de reconocimiento'">
    <button class="check-btn" mat-flat-button routerLink="/missions/create">
        CREAR
    </button>
    <button [disabled]="!selectedProducts.length" class="check-btn" mat-flat-button color="warn"
        (click)="deleteTask(selectedProducts)">
        BORRAR
    </button>
</app-main-header>
<!--  -->
<app-data-table 
    [data]="missionsService.tasks()" 
    [columns]="columns" 
    [rows]="50" 
    [rowsPerPageOptions]="[10, 25, 50]" 
    (rowSelect)="onRowSelect($event)" 
    [selection]="selectedItems" 
    (selectionChange)="onSelectionChange($event)"
    [sortField]="'task_id'" 
    [sortOrder]="-1"
    [showFilters]="true"
    (deleteItems)="onDeleteItems($event)"
    (editItem)="onEditItem($event)"
    (exportItem)="onExport($event)"
    (cloneItem)="onClone($event)"
    [showButton]="true"
    >
</app-data-table>
<!--  -->
<!-- <p-table #dt [value]="missionsService.tasks()" dataKey="task_id" [rows]="10" [paginator]="true"
    [rowsPerPageOptions]="[10, 25, 50]" (onRowSelect)="onRowSelect($event)" [(selection)]="selectedProducts"
    dataKey="task_id" [sortField]="'task_id'" [sortOrder]="-1">
    <ng-template pTemplate="header">
        <tr style="font-size: 12px">
            <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
            <th pSortableColumn="task_id">ID <p-sortIcon field="task_id"></p-sortIcon></th>
            <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="task_key">Clave <p-sortIcon field="task_key"></p-sortIcon></th>
            <th pSortableColumn="required_score">Requerido <p-sortIcon field="required_score"></p-sortIcon></th>
            <th pSortableColumn></th>
        </tr>
        <tr>
            <th>
            </th>
            <th>
                <p-columnFilter type="text" field="name" placeholder="Buscar por nombre..." ariaLabel="Filter Name" />
            </th>
            <th>
                <p-columnFilter type="text" field="task_key" placeholder="Buscar por clave..." />
            </th>
            <th>
            </th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-mission>
        <tr style="font-size: 12px">
            <td>
                <p-tableCheckbox [value]="mission" />
            </td>
            <td>{{ mission.task_id }}</td>
            <td>{{ mission.name }}</td>
            <td>{{ mission.task_key }}</td>
            <td>{{ mission.required_score }}</td>
            <td>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item [routerLink]="['/missions', mission.task_id]">
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                    </button>
                    <button mat-menu-item disabled>
                        <mat-icon>content_copy</mat-icon>
                        <span>Duplicar</span>
                    </button>
                    <button (click)="deleteTask([mission])" mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Eliminar</span>
                    </button>
                </mat-menu>
            </td>
        </tr>

    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="4">Cargando misiones</td>
        </tr>
    </ng-template>
</p-table> -->