<div class="layout-container">
    <div style="overflow-x: scroll">
        <div class="details-image">
            <div style="display: flex;">
                <button mat-icon-button class="btn-back" (click)="backbutton()">
                    <span class="material-symbols-outlined" style="margin-right: 4px;">close</span>
                </button>
                <span class="ima">
                    <span class="material-symbols-outlined" style="margin-right: 4px;">image</span>
                    {{this.nameImage}}
                </span>
            </div>
            <div>
                <span class="ndetections">
                    {{detections.length}} Detecciones
                </span>
            </div>
        </div>

        <div #imageContainer class="image-container">
            <img [src]="imageUrl" #imageElement (load)="setupBoundingBoxes()" class="imagep">
            <ng-container *ngFor="let box of detections; let i = index">
                <div *ngIf="box.visible" [ngStyle]="getBoxStyle(box)" class="bounding-box" (click)="showDetails(box)">
                    <span class="box-index">{{ i + 1 }}</span>
                </div>
            </ng-container>
        </div>
    </div>

    <div [ngClass]="{'panel-visible': panelVisible, 'panel-hidden': !panelVisible}" class="detections-panel">
        <div class="detection-header">
            <button mat-icon-button (click)="togglePanel()" *ngIf="windowWidth < 768">
                <span class="material-symbols-outlined">arrow_forward</span>
            </button>
            <!-- <p-autoComplete class="aut" [(ngModel)]="selectedItem" [suggestions]="filteredDetections"
                (completeMethod)="search($event)" field="name" [dropdown]="true" placeholder="Buscar detección"
                (onSelect)="onSelectDetection($event)">
            </p-autoComplete> -->
            <div style="display: flex;">
                <div *ngFor="let thumbnail of thumbnails; let i = index" class="thumbnail" (click)="selectImage(i)">
                    <img [src]="thumbnail" [class.selected]="currentIndex === i">
                </div>
            </div>






        </div>
        @if(detections.length){
        <div *ngFor="let box of detections; let i = index" class="detection-item">

            <div [ngStyle]="{'border-left': '4px solid rgba(' + box.color.r + ',' + box.color.g + ',' + box.color.b + ', 1)'}"
             style="border-bottom: 1px solid rgb(236, 236, 236);padding-bottom: 14px; padding-left: 14px;">

                <span class="detection-text"> {{ box.name }}</span>
                <br>
                <div>
                    <span class="ind">{{ i + 1 }}</span>
                    <span [class]="box.score > (0.5) ? 'scorein' : 'scoreout'">{{box.score | fixedTwoDecimals}}</span>
                    <span> (m: {{ box.model_id }})</span>

                </div>
            </div>
            <button mat-icon-button (click)="toggleVisibility(box.id)">
                <span class="material-symbols-outlined">{{ box.visible ? 'visibility' : 'visibility_off' }}</span>
            </button>
        </div>
        }@else{
            No se encontraron detecciones en la imagen
        }

    </div>
</div>
<!-- <span class="material-symbols-outlined">arrow_back</span> -->