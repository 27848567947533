import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Rule } from "../../models/rules";
import { Observable, tap } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})

export class RulesService {
    private baseUrl: string = `${environment['api_backend']}/api`
    constructor(private http: HttpClient) {

    }

    getRules(): Observable<any[]> {
        let params = new HttpParams().set('limit', '200');
        return this.http.get<any[]>(`${this.baseUrl}/rules`, { params });
    }

    getRuleById(id: number): Observable<any> {
        return this.http.get<any[]>(`${this.baseUrl}/rules/${id}`);
    }

    createRule(rule: any): Observable<any> {
        console.log('rule', rule)
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}/rules`, rule, { headers })
            .pipe(
                tap(d => console.log(d))
            );
    }

    updateRule(ruleId: number, ruleData: any): Observable<any> {
        const url = `${this.baseUrl}/rules/${ruleId}`;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.patch(url, ruleData, { headers })
    }

    deleteRule(ruleId:number): Observable<Rule> {
    return this.http.delete<Rule>(`${this.baseUrl}/rules/${ruleId}`)
    } 
}