import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MenuComponent } from './components/shared/menu/menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip';
import { UpdateServiceWorker } from './services/updatepwa.service';
import { AuthService } from './services/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { UsersComponent } from './users/users.component';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule, RouterLink, RouterLinkActive, MenuComponent, MatSidenavModule, MatButtonModule, MatIconModule, MatTooltipModule, UsersComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  envP!: boolean
  auth = inject(AuthService)
  constructor(private updateService: UpdateServiceWorker, private updates: SwUpdate) {
    this.checkForUpdates();
    console.log('.',environment.production)
    this.envP = environment.production === true ? false : true;
  }
  showFiller = false;
  title = 'client';
  signOut() {
    this.auth.signOut()
  }
  // Método para verificar y aplicar actualizaciones automáticamente
  checkForUpdates() {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_READY') {
          console.log('Nueva versión disponible. Actualizando...');
          window.location.reload(); // Fuerza la recarga para obtener la última versión
        }
      });
    }
  }
}
