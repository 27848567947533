import { Component, ViewChild } from '@angular/core';
import { MainHeaderComponent } from "../../shared/main-header/main-header.component";
import { DataTableComponent } from "../../shared/data-table/data-table.component";

import { Task } from '../../../models/task';
import { Models } from '../../../models/models';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterLink } from '@angular/router';
import { Table } from 'primeng/table';
import {MatButtonModule} from '@angular/material/button';
import { ModelsService } from '../../../services/models.service';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [MainHeaderComponent, DataTableComponent, RouterLink, MatButtonModule, ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.css'
})
export class ListModelssComponent {
  models$!: Observable<Models[]>
  selected!: Models[];
  products!: Models[];
  selectedProducts: Models[] = [];

  selectedItems: any[] = [];

  columns: any[] = [
    { field: 'model_id', header: 'Id', filter: true , orderby: true },
    { field: 'name', header: 'Nombre', filter: true, orderby: false },
    { field: 'description', header: 'Descripcion', filter: true, orderby: false },
    { field: 'server', header: 'Servidor', filter: true, orderby: false },
    { field: 'server_path', header: 'Ruta del Servidor', filter: true, orderby: false },
    { field: 'version', header: 'Version', filter: true, orderby: false },
    { field: 'model_key', header: 'Llave', filter: true, orderby: false },
    { field: 'is_active', header: 'Activo', filter: true, orderby: false },
  ];
  
  @ViewChild('dt') table!: Table;
  constructor(public modelsService: ModelsService, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.modelsService.loadModelsStore()
    console.log("tabla____", this.table)
  }
  onSelectionChange(event: any) {
    console.log('event', event)
    this.selectedProducts = event
  }
  onEditItem(item: any) {
    console.log('onEditItem items:', item);
    this.router.navigate(['/models', item.model_id]);  
  }
  onDeleteItems(items: any) {
     console.log('Delete items:', items);
     if (window.confirm("Esto eliminará este modelo y todas sus configuraciones")) { 
     this.modelsService.deleteModels(items.model_id).subscribe(() => {
      this.selectedProducts = []
      console.log('modelo delete')
      this._snackBar.open(`Modelo ${items.model_id} eliminado.`, 'ok');
      this.modelsService.loadModelsStore()
    })
  }
  }


  deleteModel( model: Models[]) {
    this._snackBar.open('Eliminando...', 'ok');
    if (window.confirm("Esto eliminará este modelo y todas sus configuraciones")) {
      model.map(model => {
        this.modelsService.deleteModels(model.model_id).subscribe(() => {
          this.selectedProducts = []
          console.log('model delete')
          this._snackBar.open(`Modelo ${model.model_id} eliminado.`, 'ok');
          this.modelsService.loadModelsStore()
        })
      })
      }
    }
      onRowSelect(event: any) {
        console.log('select', this.selectedProducts)
       
    
    }
  }







