<app-main-header [name_main]="titleheader">
  @if(creating){
      <button [disabled]="for_save" mat-flat-button color="primary" [disabled]="!clientForm.valid"
          (click)="saveClients()">Guardar</button>
      }@else {
      <button class="check-btn" mat-flat-button (click)="openBottomSheet()"></button>
      <button mat-flat-button color="primary" [disabled]="!clientForm.valid || !for_save"
          (click)="saveClients()">Guardar</button>
      }

</app-main-header>

<hr>
<form [formGroup]="clientForm">

<div class="clients-data" >
 @for (d of formConfig; track d.label; let idx = $index, e = $even) {
  <mat-form-field class="outline"  [ngStyle]="{ 'width': d.width, 'margin':d.margin}" *ngIf="d.type !== 'checkbox'">    
      <mat-label>{{ d.label }}</mat-label>
  <input *ngIf="d.type != 'select'" matInput [formControlName]="d.controlName" [type]="d.type" >
       
  </mat-form-field>
  <div *ngIf="d.type === 'checkbox'" class="checkbox-group">
    <mat-label>{{ d.label }}</mat-label>
    <div class="checkbox-options">
      <mat-checkbox 
      [checked]="clientForm.get(d.controlName)?.value === true" 
      (change)="clientForm.get(d.controlName)?.setValue(true)">
        Si
      </mat-checkbox>
      <mat-checkbox 
      [checked]="clientForm.get(d.controlName)?.value === false" 
      (change)="clientForm.get(d.controlName)?.setValue(false)">
        No
      </mat-checkbox>
    </div>
  </div>
  <div *ngIf="clientForm.get(d.controlName)?.errors?.['minlength'] && clientForm.get(d.controlName)?.touched">
      <mat-error>
      <small > El valor ingresado debe ser superior a 2 caracteres. </small>

  </mat-error>
  </div>
  }
<!--   <div class="checkbox-group">
  <mat-label>¿Filtar producto?</mat-label>
  <div class="checkbox-options">
  <mat-checkbox 
  [checked]="clientForm.get('filter_products')?.value === true" 
  (change)="setFilterProductsValue(true)">
  Si
</mat-checkbox>
<mat-checkbox 
  [checked]="clientForm.get('filter_products')?.value === false" 
  (change)="setFilterProductsValue(false)">
  No
</mat-checkbox>

 </div>
 </div>
  <div class="checkbox-group">
  <mat-label>¿Validación única?</mat-label>
  <div class="checkbox-options">
  <mat-checkbox 
  [checked]="clientForm.get('validate_unique')?.value === true" 
  (change)="setValidateUniqueValue(true)">
  Si
</mat-checkbox>
<mat-checkbox 
  [checked]="clientForm.get('validate_unique')?.value === false" 
  (change)="setValidateUniqueValue(false)">
  No
</mat-checkbox>

  </div>
  </div>
<div class="checkbox-group">
<mat-label>¿Webhook activo?</mat-label>
<div class="checkbox-options">
<mat-checkbox 
  [checked]="clientForm.get('webhook_active')?.value === true" 
  (change)="setWebhookActiveValue(true)">
  Si
</mat-checkbox>
<mat-checkbox 
  [checked]="clientForm.get('webhook_active')?.value === false" 
  (change)="setWebhookActiveValue(false)">
  No
</mat-checkbox>

</div>
</div>
</div>
</form>
<div class="mission-parameters">
  <div class="title-btn">
      <div>  -->