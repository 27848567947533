<app-main-header [name_main]="titleheader" [id_code]="ruleId">
  @if(creating){
  <button [disabled]="!ruleForm.valid || !for_save" mat-flat-button color="primary"
    (click)="saveRule()">Guardar</button>
  }@else {
  <button mat-flat-button color="warn" (click)="deleteRule(ruleId)" class="check-btn">Borrar</button>
  <button mat-flat-button color="primary" (click)="onClone()" class="btn">Duplicar</button>
  <button mat-flat-button color="primary" [disabled]="!ruleForm.valid || !for_save" (click)="saveRule()"
    class="btn">Actualizar</button>
  }

</app-main-header>
<form [formGroup]="ruleForm">
  <div class="rule-data">
    @for (con of formConfig; track $index; let idx = $index, e = $even) {

    <mat-form-field appearance="outline" [ngStyle]="{ 'width': con.width }">
      <mat-label>{{ con.label }}</mat-label>
      <input matInput [formControlName]="con.controlName" [type]="con.type">
    </mat-form-field>
    }
  </div>


  <div class="rule-parameters">
    <div class="section-head">
      <span>Entradas</span>
      <button mat-flat-button color="primary" (click)="addParameter()">Añadir
        nuevo</button>
    </div>

    @for (param of rulesparameters.value; track param.rule_parameter_id; let idx = $index, e = $even) {
    <!-- {{param | json}} -->
    <div formArrayName="rulesparameters" >
      <ng-container [formGroupName]="idx">
        <mat-form-field appearance="outline">
          <mat-label>Llave</mat-label>
          <input matInput formControlName="key">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="format">
            <mat-option *ngFor="let option of typeOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Template</mat-label>
          <textarea formControlName="input_validator" matInput placeholder="Ex. It makes me feel..."></textarea>
        </mat-form-field>
      </ng-container>
      <button mat-icon-button (click)="removeParameter(idx)">
        <span class="material-symbols-outlined">delete</span>
      </button>
    </div>
    }
  </div>
  <br>


  <div class="rule-parameters">
    <div class="section-head">
      <span>Salidas</span>
      <button mat-flat-button color="primary" (click)="addOuput()">Añadir nuevo</button>
    </div>


    @for (param of ruleoutputs.value; track param.rule_output_id; let idx = $index, e = $even) {
    <!-- {{param | json}} -->
    <div formArrayName="ruleoutputs" class="form-row">
      <ng-container [formGroupName]="idx">
        <mat-form-field appearance="outline">
          <mat-label>Llave</mat-label>
          <input matInput formControlName="key">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="label">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let option of typeOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <button mat-icon-button (click)="removeOuput(idx)">
        <span class="material-symbols-outlined">delete</span>
      </button>
    </div>
    }
  </div>
</form>