import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { signal, computed, effect } from '@angular/core';
import { TaskModel } from '../models/task_model';
import { Task } from '../models/task';
import { environment } from '../../environments/environment';

export interface TaskRule {
    task_rule_id: number;
    rule_id: number;
    task_id: number;
    name: string;
    description: string;
    function_name: string;
    weight: number;
    created_at: string;
    updated_at: string;
}

@Injectable({
    providedIn: 'root'
})
export class MissionsService {
    private baseUrl: string = `${environment['api_backend']}/api`
    private apiUrlRecon = `${environment['api_recognition']}/v1/recognition`
    private apiUrlDetails = `${environment['api_recognition']}/v2/recognition/details`
    private tokenUrl = `${environment.api_recognition}/auth/token?client_id=${environment.client}`;
    private localStorageKey = 'At_';

    private checkToken(): Observable<string | null> {
        // console.log('checkToken')
        const token = localStorage.getItem(this.localStorageKey);
        if (token) { return of(token) } else {
            return this.fetchToken().pipe(
                tap(newToken => {
                    if (newToken) {
                        localStorage.setItem(this.localStorageKey, newToken);
                    }
                })
            );
        }
    }

    private fetchToken(): Observable<string | null> {
        // console.log('fetchToken')
        const headers = new HttpHeaders({
            'accept': 'application/json',
            'Authorization': environment.key
        });
        return this.http.get<{ token: string }>(this.tokenUrl, { headers }).pipe(
            tap(response => console.log('Token fetched:', response)),
            map(response => response.token),
            catchError(error => {
                console.error('Error fetching token:', error);
                return of(null);
            })
        );
    }

    private getHeaders(): Observable<HttpHeaders> {
        return this.checkToken().pipe(
            map(token => new HttpHeaders({
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            }))
        );
    }

    private tasksSignal = signal<Task[]>([]);
    tasks = computed(() => this.tasksSignal());
    taskCount = computed(() => this.tasksSignal().length);

    private tasks_store = new BehaviorSubject<Task[]>([]);
    tasks_store$ = this.tasks_store.asObservable();

    private productsSubject = new BehaviorSubject<any[]>([]);
    private imagePathSubject = new BehaviorSubject<string>('');

    private handleError(error: any): Observable<never> {
        console.error('An error occurred:', error);
        return throwError(error);
    }

    constructor(private http: HttpClient) {
        this.tasks_store$.subscribe(missions => {
            this.tasksSignal.set(missions);
        });

        effect(() => {
            console.log(`Total tasks: ${this.taskCount()}`);
        });
    }

    loadTaskStore(): void {
        let params = new HttpParams().set('limit', '2000');
        this.http.get<Task[]>(`${this.baseUrl}/tasks`, { params }).subscribe((tasks: Task[]) => {
            this.tasksSignal.set(tasks);
        })
    }



    deleteTask(task: Task): Observable<Task> {
        return this.http.delete<Task>(`${this.baseUrl}/tasks/${task.task_id}`)
    }


    getTaskById(taskId: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/tasks/${taskId}`);
    }

    createMission(mission: any): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}/tasks`, mission, { headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    updateTask(task_id: number, task: Partial<Task>) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.patch<Task>(`${this.baseUrl}/tasks/${task_id}`, task, { headers })
            .pipe(
                catchError(this.handleError)
            );
    }



    // private deepCopy<T>(obj: T): T {
    //     return JSON.parse(JSON.stringify(obj));
    // }

    // addRuleToMission(taskId: number, rule: TaskRule): void {
    //     this.tasksSignal.update(missions => {
    //         const mission = missions.find(m => m.task_id === taskId);
    //         if (mission) {
    //             mission.tasksrules.push(rule);
    //         }
    //         const updatedMissions = this.deepCopy(missions);
    //         console.log('After adding rule:', updatedMissions);
    //         return updatedMissions;
    //     });
    //     this.tasksSubject.next(this.tasksSignal());
    // }

    // deleteRuleFromMission(taskId: number, ruleId: number): void {
    //     this.tasksSignal.update(missions => {
    //         const mission = missions.find(m => m.task_id === taskId);
    //         if (mission) {
    //             console.log('Before deleting rule:', mission.tasksrules);
    //             mission.tasksrules = mission.tasksrules.filter(rule => rule.task_rule_id !== ruleId);
    //             console.log('After deleting rule:', mission.tasksrules);
    //         }
    //         const updatedMissions = this.deepCopy(missions);
    //         console.log('After deleting rule - missions:', updatedMissions);
    //         return updatedMissions;
    //     });
    //     this.tasksSubject.next(this.tasksSignal());
    // }

    deleteTaskRule(task_rule_id: number): Observable<TaskRule> {
        console.log('delete task_rule_id', task_rule_id)
        return this.http.delete<TaskRule>(`${this.baseUrl}/tasks-rules/${task_rule_id}`)
    }

    createTaskRule(task_rule: TaskRule): Observable<TaskRule> {
        return this.http.post<TaskRule>(`${this.baseUrl}/tasks-rules/`, task_rule)
    }

    updateTaskRule(task_rule_id: number, task_rule: TaskRule): Observable<TaskRule> {
         console.log(' datos a enviar task_rule', task_rule)
        return this.http.patch<TaskRule>(`${this.baseUrl}/tasks-rules/${task_rule_id}`, task_rule);
    }

    createTaskModel(task_model: TaskModel) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const { task_id, model_id } = task_model
        console.log({ task_id, model_id })
        return this.http.post(`${this.baseUrl}/task-models`, { task_id, model_id }, { headers });
    }

    deleteTaskModel(task_id: number, model_id: number): Observable<TaskRule> {
        return this.http.delete<TaskRule>(`${this.baseUrl}/task-models/${task_id}/${model_id}`)
    }

    sendRecognition(formData: FormData): Observable<any> {
        return this.getHeaders().pipe(
            switchMap(headers => this.http.post(this.apiUrlRecon, formData, { headers }).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401 || error.status === 403) {
                        // Token might be expired or invalid
                        localStorage.removeItem(this.localStorageKey);
                        return this.getHeaders().pipe(
                            switchMap(newHeaders => this.http.post(this.apiUrlRecon, formData, { headers: newHeaders }))
                        );
                    } else if (error.status === 500) {
                        console.error('Token expired or server error');
                        return throwError('Token expired or server error');
                    } else if (error.status === 0) {
                        console.error('Network error or request stalled. Please check your connection.');
                        return throwError('Network error or request stalled');
                    } else {
                        return throwError(error);
                    }
                })
            ))
        );
    }


    getRecognitionStatus(id: string): Observable<any> {
        return this.getHeaders().pipe(
            switchMap(headers => this.http.get(`${this.apiUrlRecon}/status/${id}`, { headers }).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401 || error.status === 403) {
                        localStorage.removeItem(this.localStorageKey);
                        return this.getHeaders().pipe(
                            switchMap(newHeaders => this.http.get(`${this.apiUrlRecon}/status/${id}`, { headers: newHeaders }))
                        );
                    } else {
                        return throwError(error);
                    }
                })
            ))
        );
    }

    getRecognitionDetails(id: string): Observable<any> {
        return this.getHeaders().pipe(
            switchMap(headers => this.http.get(`${this.apiUrlDetails}/${id}`, { headers }).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401 || error.status === 403) {
                        localStorage.removeItem(this.localStorageKey);
                        return this.getHeaders().pipe(
                            switchMap(newHeaders => this.http.get(`${this.apiUrlDetails}/${id}`, { headers: newHeaders }))
                        );
                    } else {
                        return throwError(error);
                    }
                })
            ))
        );
    }



    setProducts(products: any[]) {
        this.productsSubject.next(products);
    }

    getProducts(): Observable<any[]> {
        return this.productsSubject.asObservable();
    }

    transformApiResponse(data: any): any[] {
        let products: any[] = [];
        for (const imageId in data.inferences) {
            if (data.inferences.hasOwnProperty(imageId)) {
                data.inferences[imageId].forEach((item: any) => {
                    products.push({
                        imageId: imageId,
                        name: item.name,
                        score: item.score,
                        bounding_box: {
                            xmin: parseFloat(item.bounding_box.xmin),
                            ymin: parseFloat(item.bounding_box.ymin),
                            xmax: parseFloat(item.bounding_box.xmax),
                            ymax: parseFloat(item.bounding_box.ymax),
                            width: parseFloat(item.bounding_box.xmax) - parseFloat(item.bounding_box.xmin),
                            height: parseFloat(item.bounding_box.ymax) - parseFloat(item.bounding_box.ymin)
                        },
                        path: item.path
                    });
                });
            }
        }
        return products;
    }
    // EXPORTAR IMPORTAR TAREAS
    exportTaskById(taskId: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/tasks/export/${taskId}`);
    }
    importTaskById(task: Task): Observable<Task> {
        // return this.http.post<TaskRule>(`${this.baseUrl}/tasks/import`, task_rule)
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(`${this.baseUrl}/tasks/import`, task, { headers })
        .pipe(
            catchError(this.handleError)
        );
    }

}
