import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormField } from '../model';
import { ModelFieldComponent } from '../model-field/model-field.component';
import { InputFieldComponent } from '../input-field/input-field.component';
import { CheckboxFieldComponent } from '../checkbox-field/checkbox-field.component';
import { ChipFieldComponent } from '../chip-field/chip-field.component';
import { SelectFieldComponent } from '../select-field/select-field.component';
import { LibraryObjectsFieldComponent } from '../library-objects-field/library-objects-field.component';

@Component({
  selector: 'app-object-field',
  standalone: true,
  templateUrl: './object-field.component.html',
  styleUrl: './object-field.component.css',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ObjectFieldComponent),
    multi: true
  }],
  imports: [ReactiveFormsModule, ModelFieldComponent, InputFieldComponent, CheckboxFieldComponent, ChipFieldComponent, ModelFieldComponent, SelectFieldComponent, LibraryObjectsFieldComponent],
})
export class ObjectFieldComponent implements ControlValueAccessor {
  @Input() config!: DynamicFormField;
  @Input() formGroup!: FormGroup;

  writeValue(value: string): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
  getFormControl(controlGroup: AbstractControl, fieldName: string): FormControl {
    if (!(controlGroup instanceof FormGroup)) {
      throw new Error('El control proporcionado no es un FormGroup.');
    }
    const group = controlGroup as FormGroup;
    const control = group.get(fieldName);
    if (!control) {
      throw new Error(`El control "${fieldName}" no existe en el grupo.`);
    }
    return control as FormControl;
  }
}
