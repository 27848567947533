import { Component, ViewChild } from '@angular/core';
import { Dashboards } from '../../models/dashboards';
import { DashboardsService } from '../../services/dashboards.service';
import { Table, TableModule } from 'primeng/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { MainHeaderComponent } from '../../components/shared/main-header/main-header.component';
import { DataTableComponent } from '../../components/shared/data-table/data-table.component';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
  selector: 'app-list-board',
  standalone: true,
  imports: [MainHeaderComponent, DataTableComponent, RouterLink,MatButtonModule, MatButton, CommonModule, MatFormFieldModule, DataTableComponent, TableModule],
  templateUrl: './list-board.component.html',
  styleUrl: './list-board.component.css'
})
export class ListBoardComponent {
  dashboards$! : Observable<Dashboards[]>;
  selected!: Dashboards[];
  products!: Dashboards[];
  selectedProducts: Dashboards[] = [];
  selectedItems: any[] = [];
  dashboards: Dashboards[] = [];
  boardsData: any[]= []

  dataToDisplayInTable: any[] = [];
  columns: any[] = [
    { field: 'client_id', header: 'Cliente', filter: true, orderby: true},
    { field: 'name', header: 'Tablero', filter: true, orderby: false },
    { field: 'roles', header: 'Usuarios', filter: true, orderby: false},
    { field: 'urlBi', header: 'Url', filter: true, orderby: false },
  ];

  @ViewChild('dt')table!: Table;
  constructor(public dashboardService: DashboardsService, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.dashboardService.loadDashboardsStore()
    this.dashboardService.dashboards$.subscribe(data => {
    this.boardsData = data.map(boards => ({
      ...boards,
    roles: this.formatRoles(boards.roles?? {})
    }));
  });
    console.log("tabla____", this.table) 
    }; 

  
  onSelectionChange(event: any) {
    console.log('event', event)
    this.selectedProducts = event
  }
  onEditItem(item: any) {
    console.log('onEditItem items:', item);
    this.router.navigate(['/dashboards', item.dashboard_id]);
  }

   onDeleteItems(items: any) {
    console.log('Delete items:', items);
    if (window.confirm("Esto eliminará este tablero y todas sus configuraciones")) { 
    this.dashboardService.deleteDashboards(items.uid).subscribe(() => {
     this.selectedProducts = []
     console.log('Tablero delete')
     this._snackBar.open(`Tablero ${items.uid} eliminado.`, 'ok');
     this.dashboardService.loadDashboardsStore()
   })
  } 
  } 

  deleteDashboard( dashboard: Dashboards[]) {
     this._snackBar.open('Eliminando...', 'ok');
    if (window.confirm("Esto eliminará este tablero y todas sus configuraciones")) {
        dashboard.map(dashboard => {
          if (dashboard.dashboard_id) { // Verifica si el usuario tiene uid
            this.dashboardService.deleteDashboards(dashboard.dashboard_id).subscribe(() => {
              this.selectedProducts = []; 
              this._snackBar.open(`Tablero ${dashboard.name} eliminado.`, 'ok');
              this.dashboardService.loadDashboardsStore(); // Recargar la lista de usuarios
            });
          } else {
            console.error(`El dashboard ${dashboard.name} no tiene un uid válido`);
          }
        });
      } 
    } 

  onRowSelect(event: any) {
      console.log('select', this.selectedProducts)
  } 

  formatRoles(roles: any): string {
    if (!roles) return 'Sin roles';
    const enabledRoles = Object.keys(roles).filter(emails => roles[emails]);
    return enabledRoles.length ? enabledRoles.join(', ') : 'Sin roles';
  }


} 

