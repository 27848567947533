import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class UpdateServiceWorker {

    constructor(private updates: SwUpdate, private snackBar: MatSnackBar) {
        this.updates.versionUpdates.subscribe((event: VersionEvent) => {
            if (event.type === 'VERSION_READY') {
                this.showUpdateSnackBar();
            }
        });
    }
    showUpdateSnackBar() {
        const snackBarRef = this.snackBar.open('Nueva versión disponible', 'Actualizar', {duration: 6000});
        snackBarRef.onAction().subscribe(() => {
            this.updates.activateUpdate().then(() => document.location.reload());
        });
    }
}
